import { useContext, useEffect, useState } from "react";
import FiveOfferSection from "../../components/sectionlayouts/FiveOfferSection";
import { OffersContext } from "../../context/offers/OffersContext";
import SixOfferSection from "../../components/sectionlayouts/SixOfferSection";
import BrandSection from "../../components/sectionlayouts/BrandSection";
import Banner from "../../components/banners/Banner";

import "./home.css";
import { Link, useNavigate } from "react-router-dom";
import GiveawaySection from "../../components/sectionlayouts/GiveawaySection";
import { ClientContext } from "../../context/client/ClientContext";
export default function Home() {
  const { offers } = useContext(OffersContext);
  const { client } = useContext(ClientContext);
  let isLoggedIn = false;

  if (client) {
    isLoggedIn = true;
  }

  const navigate = useNavigate();
  const bannerItemsRight = [
    {
      dImg: "AntiBeauty Banner.png",
      mImg: "AntiBeauty Banner.png",
      pill: "Exclusive",
      cashback: "50% Off",
      merchant: "AntiBeauty",
      link: "/offers/search/detail/7774",
      title: "This is Title One",
    },
    {
      dImg: "Ally Banner.jpg",
      mImg: "Ally Banner.jpg",
      pill: "Exclusive",
      cashback: "5.6% Cashback",
      merchant: "Ally Fashion",
      link: "/offers/search/detail/1918",
      title: "This is Title One",
    },
    // {
    //     dImg: 'clarks.jpg',
    //     mImg: 'clarks.jpg',
    //     pill: "Exclusive",
    //     cashback: "5.6% Cashback",
    //     merchant: "Clarks",
    //     link: "/offers/search/detail/1693",
    //     title: "This is Title One"
    // }
  ];
  const bannerItemsHowitWorks = [{
    dImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/How-it-works-banner-[Homepage].png",
    mImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/How-it-works-banner-[Homepage]-MOBILE.png",
    link: "/signup",
  }]

  const [bannerItemsFull, setBannerItemsFull] = useState([]);

  useEffect(() => {
    const items = [];

    // if (client) {
    //   if (client?.subscription_status !== "active") {
    //     //ugrade to isavemore
    //     items.push({
    //       dImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/Desktop_Full_Banner_Sub_1.png",
    //       mImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/Mobile_Full_Banner_Sub_1.png",
    //       isFunction: true,
    //     });
    //   } else {
    //     //more user sees.
    //     items.push({
    //       dImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/Desktop_Full_Banner_2.png",
    //       mImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/Mobile_Full_Banner_2.png",
    //       link: "/giveaways",
    //     });
    //   }
    // } else {
    //   //isavenow
    //   items.push({
    //     dImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/Desktop_Full_Banner_1.png",
    //     mImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/Mobile_Full_Banner_1.png",
    //     link: "/giveaways",
    //   });
    //   //ugrade to isavemore
    //   items.push({
    //     dImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/Desktop_Full_Banner_Sub_1.png",
    //     mImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/Mobile_Full_Banner_Sub_1.png",
    //     link: "/giveaways",
    //   });
    // }

    // if (client) {
    //   if (client?.subscription_status !== "active") {

    //   } else {

    //   }
    // } else {

    // }
    // if (isLoggedIn) {
    //   // items.push({
    //   //   dImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/Desktop_Full_Banner_Fuel.png",
    //   //   mImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/Mobile_Full_Banner_Fuel.png",
    //   //   link: "https://www.unitedpetroleum.com.au/fuel-discount-cards/our-partners/isavenow/",
    //   //   isNewTab: true,
    //   // });
    // } else {
    //   // items.push({
    //   //   dImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/Desktop_Full_Banner_Fuel.png",
    //   //   mImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/Mobile_Full_Banner_Fuel.png",
    //   //   link: "/login",
    //   // });
    // }

    // items.push({
    //   dImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/DESKTOP_Full_Banner_-_Hero_Image_Wotif.png",
    //   mImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/MOBILE_Full_Banner_-_Hero_Image_Wotif.png",
    //   link: "/offers/search/detail/2237",
    // });

    items.push({
      dImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/headerbanners/home-july-web-1.png",
      mImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/headerbanners/home-july-mob-1.png",
      link: "/giveaways",
    })
    setBannerItemsFull(items);
  }, [client]);

  // function autocomplete(inp, arr) {
  //     /*the autocomplete function takes two arguments,
  //     the text field element and an array of possible autocompleted values:*/
  //     var currentFocus;
  //     /*execute a function when someone writes in the text field:*/
  //     inp.addEventListener("input", function (e) {
  //         var a, b, i, val = this.value;
  //         /*close any already open lists of autocompleted values*/
  //         closeAllLists();
  //         if (!val) { return false; }
  //         currentFocus = -1;
  //         /*create a DIV element that will contain the items (values):*/
  //         a = document.createElement("DIV");
  //         a.setAttribute("id", this.id + "autocomplete-list");
  //         a.setAttribute("className", "autocomplete-items w-50 m-auto");
  //         /*append the DIV element as a child of the autocomplete container:*/
  //         // this.parentNode.appendChild(a);
  //         document.getElementById("divAutocompleteContainer").appendChild(a);
  //         /*for each item in the array...*/

  //         for (i = 0; i < arr.length; i++) {
  //             /*check if the item starts with the same letters as the text field value:*/

  //             if (arr[i].title.substr(0, val.length).toUpperCase() == val.toUpperCase()) {
  //                 /*create a DIV element for each matching element:*/
  //                 b = document.createElement("DIV");
  //                 /*make the matching letters bold:*/
  //                 b.innerHTML = "<strong>" + arr[i].title.substr(0, val.length) + "</strong>";
  //                 b.innerHTML += arr[i].title.substr(val.length);
  //                 /*insert a input field that will hold the current array item's value:*/
  //                 b.innerHTML += "<input type='hidden' value='" + arr[i].id + "'>";
  //                 /*execute a function when someone clicks on the item value (DIV element):*/
  //                 b.addEventListener("click", function (e) {
  //                     /*insert the value for the autocomplete text field:*/
  //                     navigate("/offers/detail/" + this.getElementsByTagName("input")[0].value)
  //                     //inp.value = this.getElementsByTagName("input")[0].value;
  //                     /*close the list of autocompleted values,
  //                     (or any other open lists of autocompleted values:*/
  //                     closeAllLists();
  //                 });
  //                 a.appendChild(b);
  //             }
  //         }
  //     });
  //     /*execute a function presses a key on the keyboard:*/
  //     inp.addEventListener("keydown", function (e) {
  //         var x = document.getElementById(this.id + "autocomplete-list");
  //         if (x) x = x.getElementsByTagName("div");
  //         if (e.keyCode == 40) {
  //             /*If the arrow DOWN key is pressed,
  //             increase the currentFocus variable:*/
  //             currentFocus++;
  //             /*and and make the current item more visible:*/
  //             addActive(x);
  //         } else if (e.keyCode == 38) { //up
  //             /*If the arrow UP key is pressed,
  //             decrease the currentFocus variable:*/
  //             currentFocus--;
  //             /*and and make the current item more visible:*/
  //             addActive(x);
  //         } else if (e.keyCode == 13) {
  //             /*If the ENTER key is pressed, prevent the form from being submitted,*/
  //             e.preventDefault();
  //             if (currentFocus > -1) {
  //                 /*and simulate a click on the "active" item:*/
  //                 if (x) x[currentFocus].click();
  //             }
  //         }
  //     });
  //     function addActive(x) {
  //         /*a function to classify an item as "active":*/
  //         if (!x) return false;
  //         /*start by removing the "active" className on all items:*/
  //         removeActive(x);
  //         if (currentFocus >= x.length) currentFocus = 0;
  //         if (currentFocus < 0) currentFocus = (x.length - 1);
  //         /*add className "autocomplete-active":*/
  //         x[currentFocus].classList.add("autocomplete-active");
  //     }
  //     function removeActive(x) {
  //         /*a function to remove the "active" className from all autocomplete items:*/
  //         for (var i = 0; i < x.length; i++) {
  //             x[i].classList.remove("autocomplete-active");
  //         }
  //     }
  //     function closeAllLists(elmnt) {
  //         /*close all autocomplete lists in the document,
  //         except the one passed as an argument:*/
  //         var x = document.getElementsByClassName("autocomplete-items");
  //         for (var i = 0; i < x.length; i++) {
  //             if (elmnt != x[i] && elmnt != inp) {
  //                 x[i].parentNode.removeChild(x[i]);
  //             }
  //         }
  //     }
  //     /*execute a function when someone clicks in the document:*/
  //     document.addEventListener("click", function (e) {
  //         closeAllLists(e.target);
  //     });
  // }

  useEffect(() => {
    if (offers?.all) {
      const inp = document.getElementById("txtGlobalSearch");
      inp.addEventListener("keydown", function (e) {
        if (e.keyCode == 13) {
          /*If the ENTER key is pressed, prevent the form from being submitted,*/
          e.preventDefault();
          globalSearch();
        }
      });
    }
    // autocomplete(document.getElementById("txtGlobalSearch"), offers?.all);
  }, [offers]);

  const globalSearch = () => {
    // alert("A")
    const searchparam = document.getElementById("txtGlobalSearch").value;
    if (searchparam !== "") navigate("/search/" + searchparam);
  };

  return (
    <>
      <div className="pt-3">
        <div id="divAutocompleteContainer" className="mb-4">
          <div
            className="w-50 m-auto rounded-pill overflow-hidden 
                     d-md-block hm-search-bar"
            style={{ border: "1px solid #FB6E2C" }}
          >
            {/* <form autocomplete="off"> */}

            <div className="autocompleteaa input-group">
              <input
                id="txtGlobalSearch"
                type="text"
                className="form-control border-0 isn-fs-12 search-input-placeholder"
                placeholder="Search product, brand or category"
                aria-label="search"
                aria-describedby="button-addon2"
                autoComplete="off"
              />
              <button
                onClick={globalSearch}
                className="btn btn-primary bg-primary text-white ps-3 pe-3 border-0"
                type="button"
                id="button-addon2"
              >
                <i className="bi bi-search"></i>
              </button>
            </div>
            {/* </form> */}
          </div>
          {/* <div className="w-75 m-auto rounded-pill overflow-hidden d-md-none"
                    style={{ border: "1px solid #FB6E2C" }}>
                   

                    <div className="autocompleteaa input-group">
                        <input id="txtGlobalSearch" type="text" className="form-control border-0 isn-fs-12"
                            placeholder="Search product, brand or category" aria-label="search"
                            aria-describedby="button-addon2" autoComplete="off" />
                        <button onClick={globalSearch} className="btn btn-primary bg-primary text-white ps-3 pe-3 border-0"
                            type="button" id="button-addon2"><i className="bi bi-search"></i></button>
                    </div>
                   
                </div> */}
        </div>
      </div>
      <div className="container">
        <div>
          <Banner type="FULL" items={bannerItemsFull} bannerid="homefirst" />
        </div>
        <div className="text-center mt-5">
          <h2>Your new one-stop shop for all things saving,
            <br />shopping, earning and <label className="isn-text-normal">winning.</label></h2>
          <p className="mt-4">
            Shop smarter with discounts and cashback on your favourite brands.
          </p>
          <p>
            Create an account for free to access thousands of online deals across<br />
            fashion, travel, entertainment and more!
          </p>
        </div>
        <div className="mt-5">
          <h1 className="isn-sub-title" style={{ color: "#002848" }}>How it works</h1>
          <div className="mt-3">
            <Banner type="FULL" items={bannerItemsHowitWorks} bannerid="homethird" />
          </div>
        </div>
        <div className="mt-5">
          <FiveOfferSection
            sectiontype="Featured Brands"
            offers={offers?.["Featured"]}
            color="#002848"
          />
        </div>
        <div className="text-center mt-5">
          <h1>iSaveNow Premium</h1>
          <p className="mt-4">
            Enjoy all iSaveNow discounts while unlocking Premium perks!
          </p>

          <p><i class="bi bi-dot"></i> Double cashback on ALL eligible brands*</p>
          {/* <p><i class="bi bi-dot"></i> Automatic FREE ENTRY to major cash giveaways</p> */}
          <p><i class="bi bi-dot"></i> First access to new brands and special offers</p>
          <p><i class="bi bi-dot"></i> Premium customer support</p>
          <p><i class="bi bi-dot"></i> <b>Join iSaveNow Premium, starting from a one-time payment of just $10</b></p>
          <a href="/giveaways" className="btn rounded-5 isn-btn-premium-primary">
            Join now
          </a>
          {/* <p className="fw-bold">
            Simply upgrade your account for $19.99 per month
          </p> */}
        </div>

        {
          (offers?.["Giveaway"] && offers?.["Giveaway"]?.length > 0) ?
            <div className="mt-5">
              <GiveawaySection
                sectionlink=""
                sectiontype="Giveaways"
                offers={offers?.["Giveaway"]?.toReversed()}
                color="#002848"
              />
            </div>
            : ""
        }


        <div className="mt-5">
          <SixOfferSection
            sectiontype="Hot Deals"
            offers={offers?.["Hot"]}
            color="#002848"
          />
        </div>
        <div className="mt-5">
          <SixOfferSection
            sectiontype="For You"
            offers={offers?.["Personalised"]}
            color="#002848"
          />
        </div>
        <div className="mt-5">
          <BrandSection
            sectiontype="Popular"
            offers={offers?.["Hot"]}
            color="#002848"
          />
        </div>
        <div className="mt-5">
          <h1 className="isn-sub-title" style={{ color: "#002848" }}>
            Trending Today
          </h1>
          <div>
            <Banner
              type="RIGHT"
              contentType="OFFER"
              items={bannerItemsRight}
              bannerid="homesecond"
            />
          </div>
        </div>
        <div className="mt-5 row">
          <a className="col-sm-6 mb-4" href="https://www.starlight.org.au/?utm_source=isn&utm_medium=display" target="_blank">
            <img src="/Starlight.png" alt="" className="w-100 rounded-4" />
          </a>
          {/* {isLoggedIn ? ( */}
          <a
            href="http://mypaynow.com.au?utm_source=isn&utm_medium=display"
            target="_blank"
            rel="noopener noreferrer"
            className="col-sm-6 gtm-external-click"
          >
            <img
              src="/MPN-Image.png"
              alt=""
              className="w-100 rounded-4"
            />
          </a>
          {/* ) : (
            <Link className="col-sm-6 mb-4 gtm-external-click" to="/login">
              <img
                src="/MPN-Image.png"
                alt=""
                className="w-100 rounded-4"
              />
            </Link>
          )} */}
          {/* <Link className="col-sm-6" to="/fuel/fuelcard">
                </Link> */}
        </div>
      </div>
    </>
  );
}
