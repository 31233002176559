
import { Link } from "react-router-dom";
import SixOfferThumb from "../thumbnails/SixOfferThumb";
import { useEffect, useState } from "react";
import Skeleton from "../skeleton/Skeleton";


export default function SixOfferSection(obj) {
    const [sixwidth, setSixWidth] = useState();

    const resizeWindow = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            const width = window.innerWidth;
            const itemwidth = width / 100 * 38;
            setSixWidth(`${itemwidth * 7.5}px`);
        } else {
            setSixWidth(`auto`);
        }
    }
    useEffect(() => {
        window.addEventListener('resize', resizeWindow)
        resizeWindow()
    }, [obj.offers]);

    return <div className="mb-3 mt-3">
        <div className="row mb-3">
            <h1 className="col isn-sub-title" style={{ color: obj.color }}>{obj?.sectiontype}</h1>
            <div className="col text-end align-middle">
                <Link style={{ textDecoration: "none", color: "black" }} to={`/offers/${obj?.sectiontype}`}>View all</Link>
                <i className="bi bi-chevron-right"></i>
            </div>
        </div>
        <div className="overflow-auto isn-hide-scroll">
            <div className="row" style={{ width: `${sixwidth}` }}>
                {
                    (obj.offers) ?
                        obj.offers?.map((offer, index) => {
                            if (index < 6)
                                return <SixOfferThumb sectiontype={obj?.sectiontype} offer={offer} key={index} />
                        })
                        : Array.from({length: 6 }, (_, i) => <Skeleton key={i} type="sixoffer" />)

                }
            </div>
        </div>
    </div>
}