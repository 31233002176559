import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function BrandThumb(obj) {
    const [brandwidth, setBrandWidth] = useState("12.5%");

    const resizeWindow = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            //alert(obj.parenttype === "page")
            const width = (obj.parenttype === "page") ? "33.33%" : "12.5%"
            setBrandWidth(width);
        } else {
            setBrandWidth("12.5%");
        }
    }
    useEffect(() => {
        // if (obj.offers) window.addEventListener('resize', resizeWindow)
        resizeWindow();
    }, []);

    return <div className={(obj.parenttype === "page") ? "mb-5" : ""} style={{ width: brandwidth }}>
        <Link to={`/brands/${obj.offer.mName}`}>
            <img src={obj.offer.mImg} className="img-thumbnail rounded-3 border-1" alt="..."></img>
        </Link>
    </div>
}