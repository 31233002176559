


export default function (obj) {



    return (obj.loader) ? <div className="isn-full-overlay">
        <div className="position-absolute isn-content-center text-center">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            {/* <h3 className="text-light">{message}</h3>  */}
        </div>
    </div>
        : ""
}