import PageHeader from "../../components/masterlayout/PageHeader";
import { useEffect, useState, useContext } from "react";
import { Stripe } from 'stripe'
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm'
import { loadStripe } from '@stripe/stripe-js';
import "./payment.css";

const Payment = (props) => {
  const { premiumText, entryText, packageVal, clientSec, stripeKey, selectedPackage, priceId } = props

  const [clientSecret, setClientSecret] = useState('');
  const [stripePromise, setStripePromise] = useState('');
  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#0570DE',
      colorBackground: '#FFFFFF',
      colorText: '#30313D',
      colorDanger: '#DF1B41',
      fontFamily: 'Poppins, sans-serif',
      spacingUnit: '2px',
      borderRadius: '25px',
    },
    rules: {
      '.Label': {
        fontSize: '0',
        lineHeight: '10px'
      },
      '.CheckboxLabel': {
        color: '#D0E518'
      },
      '.TabLabel': {
        transition: 'absolute',
      },
      '.Block': {
        borderRadius: '10px',
        padding: '10px',
      },
      '.Tab': {
        color: '#d0e518',
        borderColor: '#d0e518',
        borderLeft: '1px solid #d0e518',
        borderTop: '1px solid #d0e518',
        borderRight: '1px solid #d0e518',
        borderBottom: '0',
        borderRadius: '0',
        backgroundColor: '#002848',
        marginBottom: '10px'
      },
      '.Tab--selected': {
        color: '#d0e518',
        borderLeft: '2px solid #d0e518',
        borderTop: '2px solid #d0e518',
        borderRight: '2px solid #d0e518',
        boxShadow: 'none'

      },
      '.Tab--selected:hover ': {
        color: '#d0e518',
        boxShadow: 'none'
      },
      '.Tab--selected:focus': {
        boxShadow: 'none'
      },
      '.Tab:focus': {
        boxShadow: 'none'
      },
      '.Tab:hover': {
        color: '#d0e518',

      },
      '.TabIcon--selected': {
        fill: '#d0e518'
      },
      '.TabIcon:hover': {
        fill: '#d0e518'
      },
      '.TabIcon': {
        fill: '#d0e518'
      }
    },
  };

  const fields = {
    billingDetails: 'never'
  };

  const cardNumber = {
    placeholder: 'Test',
  };




  useEffect(() => {
    setStripePromise(loadStripe(stripeKey));
    setClientSecret(clientSec);

  }, []);

  return (

    <div>
      <div>

      </div>
      <div className="container">
        <div className="payment-element">
          <div className="payment-header text-center mb-2">Billing Information</div>
          {premiumText ?

            <div className="payment-description text-center"> Complete your purchase to secure {entryText} and {premiumText} </div>

            :
            <div className="payment-description text-center"> Complete your purchase to secure {entryText}</div>

          }

          {packageVal && (
            <div className="payment-value text-center mt-2 mb-3">{selectedPackage} package : ${packageVal}</div>
          )}

          {clientSecret && stripePromise && (
            <Elements stripe={stripePromise} options={{ clientSecret, appearance }}  >
              <CheckoutForm priceId={priceId} />
            </Elements>
          )}
        </div>
      </div>
    </div>
  )

}

export default Payment;