export const CONTENT = {
  included: [
    { id: '447f67bf-d086-5a8b-9960-7a6a016c9631', content: 'Automatic entry into ALL iSaveNow Premium giveaways and promotions' },
    { id: '68256073-be58-58f5-a693-15f8e7800ac3', content: 'Early access to new brands and deals' },
    { id: 'c8699b1a-1770-5dd4-b0d9-b80ccc65c7ea', content: 'Premium iSaveNow Premium offerings, including exclusive deals and promotional offers' },
    { id: '1520d207-58c5-553d-a095-c0e19e702254', content: 'Premium customer support' }
  ],
  claimGiveaway: [
    { id: '447f67bf-d086-5a8b-9960-7a6a016c9631', content: 'Please refer to Terms and Conditions listed in individual giveaways.' },
    {id: '68256073-be58-58f5-a693-15f8e7800ac3', content: 'In ordinance with the terms and conditions of the giveaways, you must consent to your first name and state of residence being used for marketing purposes.'},
    { id: 'c8699b1a-1770-5dd4-b0d9-b80ccc65c7ea', content: 'Should you consent to providing a quote, we will only use this for marketing purposes.' },
    { id: '1520d207-58c5-553d-a095-c0e19e702254', content: ' iSaveNow will never post any other personal information, including last name, address, or billing information.' },
  ]
}