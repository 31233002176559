import React, { useState, useEffect } from "react";
import "./signup.css";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import Alert from "../../components/alert/Alert";
import useAlert from "../../components/alert/useAlert";
import Loader from "../../components/skeleton/Loader";

import "bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
import "bootstrap-datepicker";

const SignupForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [referrer, setReferrer] = useState("");
  const [loader, setLoader] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [isOver18, setIsOver18] = useState(false);
  const [isOver18Error, setIsOver18Error] = useState("");
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");

  const australianStates = [
    { code: "NSW", name: "New South Wales" },
    { code: "QLD", name: "Queensland" },
    { code: "SA", name: "South Australia" },
    { code: "TAS", name: "Tasmania" },
    { code: "VIC", name: "Victoria" },
    { code: "WA", name: "Western Australia" },
    { code: "ACT", name: "Australian Capital Territory" },
    { code: "NT", name: "Northern Territory" },
  ];

  const {
    alert,
    showSuccessAlert,
    showErrorAlert,
    showWarningAlert,
    clearAlert,
  } = useAlert();

  const navigate = useNavigate();

  useEffect(() => {
    // Parse the URL query string
    const queryParams = new URLSearchParams(window.location.search);
    const referrerParam = queryParams.get("referrer");

    // Set the referrer state to the parameter value or default to an empty string
    setReferrer(referrerParam || "");
  }, []);

  useEffect(() => {
    // When the component mounts
    document.body.classList.add("login-background");

    // When the component unmounts
    return () => {
      document.body.classList.remove("login-background");
    };
  }, []);

  const validateName = (name, type) => {
    if (!name) return `${type} name is required.`;
    if (name.length < 2 || name.length > 30)
      return `${type} name should be between 2 and 30 characters.`;
    if (!/^[A-Za-z]+(?: [A-Za-z]+)*$/.test(name))
      return `${type}  name should only contain letters.`;
    return "";
  };

  const validateEmail = (email) => {
    if (!email) return "Email address is required.";
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) return "Please enter a valid email address.";
    return "";
  };

  const validatePassword = (password) => {
    if (!password) return "Password is required.";
    if (password.length < 8)
      return "Password should be at least 8 characters long.";
    if (
      !/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/.test(password)
    ) {
      return "Password should contain at least one uppercase letter, one lowercase letter, one number, and one special character.";
    }
    return "";
  };

  const validateConfirmPasswords = (confirmPassword) => {
    if (!confirmPassword) return "Please confirm your password..";
    if (password !== confirmPassword) {
      return "Passwords do not match.";
    }
    return "";
  };

  const validatePhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
      setPhoneNumberError("Phone number is required.");
      return false;
    }

    // Regex for Australian phone numbers (landline and mobile)
    const phoneRegex =
      /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$|^(?:\+?61|0)4\d(?:[ -]?[0-9]){7}$/;
    if (!phoneRegex.test(phoneNumber)) {
      setPhoneNumberError("Please enter a valid Australian phone number.");
      return false;
    }

    return true;
  };

  const validateOver18 = (isOver18) => {
    if (!isOver18) {
      setIsOver18Error("You must be 18 years or older to register.");

      return false;
    }
    setIsOver18Error("");
    return true;
  };

  const validateState = (state) => {
    if (!state) {
      setStateError("Selecting a state is required.");
      return false;
    }
    setStateError("");
    return true;
  };

  const hasValidationErrors = () => {
    if (
      firstNameError === "" &&
      lastNameError === "" &&
      emailError === "" &&
      // passwordError === "" &&
      // confirmPasswordError === "" &&
      validatePhoneNumber(phoneNumber) &&
      validateOver18(isOver18) &&
      validateState(state)
    ) {
      return true;
    }

    return false;
  };

  const getPasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[a-z]/.test(password)) strength += 1;
    if (/\d/.test(password)) strength += 1;
    if (/[!@#$%^&*]/.test(password)) strength += 1;
    return strength; // Will be between 0 and 5
  };

  const resetForm = () => {
    setEmail("");
    setPassword("");
    setLastName("");
    setFirstName("");
    setIsOver18(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log("Form submission started");

    setFirstNameError(validateName(firstName, "First"));
    setLastNameError(validateName(lastName, "Last"));
    // setConfirmPasswordError(validateConfirmPasswords(confirmPassword));
    setEmailError(validateEmail(email));
    // setPasswordError(validatePassword(password));
    validateOver18(isOver18);
    validateState(state);

    if (hasValidationErrors()) {
      console.log("Validation passed");

      const params = {
        username: email,
        password,
        firstname: firstName,
        lastname: lastName,
        referrer,
        phonenumber: phoneNumber,
        state,
      };

      try {
        setLoader(true);
        const result = await isnofferwallapi.post("/api/signup", params);

        if (result.data.code === "200") {
          setLoader(false);
          showSuccessAlert(`
          Congratulations!
        
          We have sent you an email with your unique username and password.
        
          Don’t see it? Be sure to check your junk folder!
        `);

          resetForm();

          setTimeout(() => {
            navigate(`/login`);
          }, 5000);
        } else {
          setLoader(false);
          showErrorAlert(result.data.message.message);
        }
      } catch (error) {
        setLoader(false);
        console.error("Sign up error", error);
        if (error.response) {
          showErrorAlert(error.response.data.payload.message);
        } else {
          showErrorAlert("Something went wrong , Please try again");
        }
      }
    }

    console.log("Validation failed");
  };

  const PasswordStrengthIndicator = () => {
    const strengthBarVariant = [
      "danger",
      "warning",
      "info",
      "primary",
      "success",
    ];

    return (
      <div className="password-strength-indicator my-2">
        <div className={`progress`}>
          <div
            className={`progress-bar bg-${
              strengthBarVariant[passwordStrength - 1] || "secondary"
            }`}
            role="progressbar"
            style={{ width: `${(passwordStrength / 5) * 100}%` }}
            aria-valuenow={passwordStrength}
            aria-valuemin="0"
            aria-valuemax="5"
          ></div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {/* Logo Image */}
      <div className="row">
        <div className="col-md-4 offset-md-4">
          <div className="col-md-7 offset-md-2 pt-md-4 isn-log-heading">
            <a href="/" className="ps-2">
              <img
                src="/login/log.png"
                alt="iSaveNow Logo"
                className="img-fluid"
              />
            </a>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-4">
            <div className="card mx-auto rounded-3 shadow">
              <div className="card-body">
                <h2 className="card-title text-left mb-2 isn-fs-26 px-3 pt-3">
                  Sign Up - It’s FREE!
                </h2>
                <p className="isn-fs-13 ps-3 pe-2">
                  Join thousands of happy Members accessing the best discounts,
                  offers, and cashback - all for FREE!
                </p>
                <form onSubmit={handleSubmit}>
                  {/* First Name Field */}
                  <div class="input-group mb-3 px-3">
                    <input
                      type="text"
                      className={`form-control isn-fs-13 signup-text-field ${
                        firstNameError ? "is-invalid" : ""
                      }`}
                      id="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="First name"
                      onBlur={() =>
                        setFirstNameError(validateName(firstName, "First"))
                      }
                      required
                    />
                    <div className="invalid-feedback isn-fs-10">
                      {firstNameError}
                    </div>
                  </div>

                  {/* Last Name Field */}
                  <div class="input-group mb-3 px-3">
                    <input
                      type="text"
                      className={`form-control isn-fs-13 signup-text-field ${
                        lastNameError ? "is-invalid" : ""
                      }`}
                      id="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Last name"
                      onBlur={() =>
                        setLastNameError(validateName(lastName, "Last"))
                      }
                      required
                    />
                    <div className="invalid-feedback isn-fs-10">
                      {lastNameError}
                    </div>
                  </div>

                  {/* Email Field */}
                  <div class="input-group mb-3 px-3">
                    <input
                      type="email"
                      className={`form-control isn-fs-13 ${
                        emailError ? "is-invalid" : ""
                      }`}
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email address"
                      onBlur={() => setEmailError(validateEmail(email))}
                      required
                    />
                    <div className="invalid-feedback isn-fs-10">
                      {emailError}
                    </div>
                  </div>

                  {/* Phone Number Field */}
                  <div className="input-group mb-3 px-3">
                    <input
                      type="text"
                      className={`form-control isn-fs-13 ${
                        phoneNumberError ? "is-invalid" : ""
                      }`}
                      id="phoneNumber"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      placeholder="Phone number"
                      onBlur={() => validatePhoneNumber(phoneNumber)}
                      required
                    />
                    <div className="invalid-feedback isn-fs-10">
                      {phoneNumberError}
                    </div>
                  </div>

                  {/* Password Field */}
                  {/* <div class="input-group mb-3 px-3">
                    <input
                      type={"password"}
                      className={`form-control pwd-input isn-fs-13 ${
                        passwordError ? "is-invalid" : ""
                      }`}
                      id="password"
                      value={password}
                      placeholder="Password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordStrength(
                          getPasswordStrength(e.target.value)
                        );
                      }}
                      onBlur={() =>
                        setPasswordError(validatePassword(password))
                      }
                      required
                    />
                    <div className="invalid-feedback isn-fs-10">
                      {passwordError}
                    </div>
                    <PasswordStrengthIndicator />
                  </div>

                  <div class="input-group mb-3 px-3">
                    <input
                      type="password"
                      className={`form-control isn-fs-13 ${
                        confirmPasswordError ? "is-invalid" : ""
                      }`}
                      placeholder="Confirm password"
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                      onBlur={() =>
                        setConfirmPasswordError(
                          validateConfirmPasswords(confirmPassword)
                        )
                      }
                      required
                    />
                    {confirmPasswordError && (
                      <div className="invalid-feedback isn-fs-10">
                        {confirmPasswordError}
                      </div>
                    )}
                  </div> */}

                  {/* State Dropdown */}
                  <div className="input-group mb-3 px-3">
                    <select
                      className={`form-select isn-fs-13 signup-text-field ${
                        stateError ? "is-invalid" : ""
                      }`}
                      id="state"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      onBlur={() => validateState(state)}
                      required
                    >
                      <option value="">Select your state</option>
                      {australianStates.map((state) => (
                        <option key={state.code} value={state.name}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                    {stateError && (
                      <div className="invalid-feedback isn-fs-10">
                        {stateError}
                      </div>
                    )}
                  </div>

                  {/* Checkbox for 18+ Confirmation */}
                  <div className="mb-3 px-3">
                    <input
                      type="checkbox"
                      id="isOver18"
                      className={`form-check-input ${
                        isOver18Error ? "is-invalid" : ""
                      }`}
                      checked={isOver18}
                      onChange={(e) => setIsOver18(e.target.checked)}
                      onBlur={() => validateOver18(isOver18)}
                    />
                    <label
                      htmlFor="isOver18"
                      className="form-check-label isn-fs-13 ml-2 ms-2"
                    >
                      I am over 18 years old
                    </label>
                    {isOver18Error && (
                      <div className="invalid-feedback isn-fs-10">
                        {isOver18Error}
                      </div>
                    )}
                  </div>

                  {/* Submit Button */}
                  <div className="row px-4 pt-2">
                    <button
                      type="submit"
                      className="btn btn-primary border-0 rounded-pill btnSignUp btn-signup-pg"
                    >
                      SIGN UP
                    </button>
                  </div>
                  <div className="text-center">
                    <p className="mt-3 isn-fs-11">
                      By clicking on Sign Up, you agree to iSaveNow's
                      <br />{" "}
                      <Link
                        className="signup-pg-terms-link"
                        to={"/about/terms-and-conditions"}
                      >
                        Terms & Conditions{" "}
                      </Link>
                      and{" "}
                      <Link
                        className="signup-pg-terms-link"
                        to={"/about/privacy-policy"}
                      >
                        Privacy Policy
                      </Link>
                    </p>
                    <p className="mt-3 isn-fs-13">
                      Already have an account? &nbsp;
                      <Link to="/login" className="pgDirectLink">
                        Login
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Alert
        show={alert.show}
        type={alert.type}
        message={alert.message}
        onClose={clearAlert}
      />
      <Loader loader={loader} />
    </div>
  );
};

export default SignupForm;
