import { useEffect, useState, useMemo, useContext } from "react";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import Common from "./common";
import PageHeader from "../../components/masterlayout/PageHeader";
import "./profile.css";
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from "react-table";
import { ClientContext } from "../../context/client/ClientContext";

// const statuses = [
// 	{
// 		id: 1,
// 		name: "Pending",
// 	},
// 	{
// 		id: 2,
// 		name: "Confirm",
// 	},
// 	{
// 		id: 3,
// 		name: "Reject",
// 	},
// ];

export default function Cashback() {
	const breadcrumbs = [
		{ label: "Home", path: "/" },
		{ label: "Withdrawal History", path: "/user/withdraw" }, // Do not include the current page here
	];
	const { client } = useContext(ClientContext);

	const [WithdrawInfo, setWithdrawInfo] = useState();
	//const [startDate, setStartDate] = useState();
	const [euid, setEUID] = useState(client?.euid || "");

	useEffect(() => {
		if (client) {
			setEUID(client.euid);
			geWithdrawInfo();
		}
	}, [client]);

	const geWithdrawInfo = async () => {
		try {
			const res = await isnofferwallapi.get("/api/cashback/withdrawalinfo", {
				params: {
					customerId: client.euid
				},
			});

			const withdrawals = res.data.list
			setWithdrawInfo(withdrawals);
		} catch (error) { }
	};

	

	const data = useMemo(
		() => WithdrawInfo ? WithdrawInfo : [
			{
				withdrawDateUtc: "N/A",
				withdrawAmount: "N/A",
				rewardStatus: "N/A",
			},
		],
		[WithdrawInfo]
	);

	const Dot = ({ color }) => (
		<span
			style={{
				height: "10px",
				width: "10px",
				backgroundColor: color,
				borderRadius: "50%",
				display: "inline-block",
				marginRight: "5px",
			}}
		/>
	);

	const columns = useMemo(
		() => [
			{
				Header: "Date",
				accessor: "withdrawDateUtc",
				Filter: false,
			},
			{
				Header: "Withdraw Amount",
				accessor: "withdrawAmount",
				Filter: false,
			},
			{
				Header: "Status",
				accessor: "rewardStatus",
				//Filter: FilterForm,
				Filter: false,
				Cell: ({ value }) => {
					// Determine the dot color based on the status value
					let color;
					switch (value) {
						case "Confirmed":
							color = "#28D294";
							break;
						case "Rejected":
							color = "#FB2C2C";
							break;
						default:
							color = "gray";
					}
					// Return a JSX element that contains the dot and the status text
					return (
						<div>
							<Dot color={color} />
							{value}
						</div>
					);
				},
			},
		],
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		setGlobalFilter,
		prepareRow,
	} = useTable({ columns, data }, useFilters, useGlobalFilter, useSortBy, usePagination);

	const { globalFilter } = state;

	return (
		<div className="isn-page-bg pb-5">
			<div>
				<PageHeader title="Withdrawal History" breadcrumbs={breadcrumbs} />
			</div>
			<div className="container">
				<div className="row">
					<div className="col-md-3">
						<Common module={"withdraw"} />
					</div>
					<div
						className="col isn-bradius bg-white p-4 mt-4"
						style={{ padding: "20px 10% 20px 10%" }}
					>
						<h5 className="mb-4">Withdraw Overview</h5>
						<div className="">
							{/* <div style={{ display: "flex" }} className="col-md-6 mb-4">
							<DatePicker
								className="form-control border-end-0 border rounded-pill"
									selected={startDate}
									onChange={(date) => setStartDate(date)}
									placeholderText="Date"
								/>
								<span style={{fontSize: "25px", marginLeft: "-30px", position: "relative"}} className="input-group-append">
										<i className="bi bi-calendar-date"></i>
								</span>
							</div> */}

							<div style={{ display: "flex" }} className="col-md-6 mb-4">
								<input
									className="form-control border-end-0 border rounded-pill"
									type="search"
									value={globalFilter || ""}
									onChange={(e) => setGlobalFilter(e.target.value)}
									placeholder="Search"
									aria-label="Search"
								/>
								<span className="input-group-append">
									<button
										className="btn btn-outline-secondary bg-white border-bottom-0 border rounded-pill ms-n5"
										type="button"
									>
										<i className="bi bi-search"></i>
									</button>
								</span>
							</div>
						</div>
						<div className="table-responsive">
							<table
								style={{ width: "100%" }}
								className="table"
								{...getTableProps()}
							>
								<thead className="table-light">
									{headerGroups.map((headerGroup) => (
										<tr {...headerGroup.getHeaderGroupProps()}>
											{headerGroup.headers.map((column) => (
												<th
													className="table-header-style"
													{...column.getHeaderProps(
														column.getSortByToggleProps()
													)}
												>
													<div>
														{column.canFilter ? column.render("Filter") : null}
													</div>
													{column.render("Header")}
													<span>
														{column.isSorted
															? column.isSortedDesc
																? " 🔽"
																: " 🔼"
															: ""}
													</span>
												</th>
											))}
										</tr>
									))}
								</thead>
								<tbody className="table-body-style" {...getTableBodyProps()}>
									{page.map((row) => {
										prepareRow(row);
										return (
											<tr {...row.getRowProps()}>
												{row.cells.map((cell) => {
													return (
														<td {...cell.getCellProps()}>
															{cell.render("Cell")}
														</td>
													);
												})}
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
						<div
							className="row"
							style={{ position: "relative", float: "right" }}
						>
							<div className="col-6">
								<button
									type="button"
									className="btn btn-light"
									onClick={() => previousPage()}
								>
									Previous
								</button>
							</div>
							<div className="col-6">
								<button
									type="button"
									className="btn btn-light"
									onClick={() => nextPage()}
								>
									Next
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
