import React, { useState, useContext } from "react";
import PageHeader from "../../components/masterlayout/PageHeader";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import Alert from "../../components/alert/Alert";
import useAlert from "../../components/alert/useAlert";
import { useNavigate } from "react-router-dom";
import { ClientContext } from "../../context/client/ClientContext";

const RefereAFriend = () => {
  const breadcrumbs = [
    { label: "Home", path: "/" },
    // Do not include the current page here
  ];
  const navigate = useNavigate();
  const { client } = useContext(ClientContext);

  let userId = "";
  if (!client) {
    navigate("/login");
  } else {
    if(client?.subscription_status !== "active"){
      navigate("/");
    }
    userId = client.euid;
  }

  const protocol = window.location.protocol;
  const host = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : "";
  const baseUrl = `${protocol}//${host}${port}`;
  // Replace with actual user ID
  const referralLink = `${baseUrl}/signup?referrer=${userId}`;

  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 3000); // Reset copied state after 3 seconds
    });
  };

  const {
    alert,
    showSuccessAlert,
    showErrorAlert,
    showWarningAlert,
    clearAlert,
  } = useAlert();

  const validateSubject = (subject) => {
    if (!subject) return "Subject is required.";
    // Add more conditions if needed
    return "";
  };

  return (
    <>
      <div>
        <PageHeader title={"Refer a Friend"} breadcrumbs={breadcrumbs} />
      </div>
      <div className="container">
        <div className="card mx-auto rounded-3 shadow">
          <div className="card-body">
            <h2 className="card-title text-left mb-4 isn-fs-24 px-3">
              Refer a friend to iSaveNow Premium!
            </h2>
            <p className="text-left text-muted isn-fs-14 px-3">
              There’s always room for more in the iSaveNow community...that’s
              why when you refer a friend to iSaveNow Premium, you’ll both get
              $15 straight in your iSaveNow wallet!
            </p>

            <h2 className="text-left mb-3 mt-4 px-3 isn-fs-16">
              Here's How It Works:
            </h2>
            <ul className="text-left text-muted isn-fs-14 list-unstyled ps-5 px-3">
              <li className="pb-2">
                <i className="bi bi-chevron-right pe-2"></i>
                Share your unique referral link with your friends and fam
              </li>
              <li className="pb-2">
                <i className="bi bi-chevron-right pe-2"></i>
                They sign up to the monthly iSaveNow Premium subscription
              </li>
              <li>
                <i className="bi bi-chevron-right pe-2"></i>
                You both receive $15 in your iSaveNow wallets!
              </li>
            </ul>

            <p className="text-left text-muted isn-fs-14 mt-3 px-3">
              Spread the word about iSaveNow Premium so your loved ones can
              experience 10 entries to all cash giveaways (weekly and major
              draws), plus a whole lot of savings!
            </p>

            <p className="text-left text-muted isn-fs-14 mt-3 px-3">
              So...ready to share the love?
            </p>
            <div className="row mb-3 ps-3">
              <div className="col-md-6">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control isn-fs-13"
                    aria-label="Referal-link"
                    aria-describedby="button-addon2"
                    value={referralLink}
                    readOnly
                  />
                  <button
                    class="btn btn-outline-secondary btnSignUp text-white border-0 isn-fs-13"
                    type="button"
                    id="button-addon2"
                    onClick={copyToClipboard}
                  >
                    {copied ? "Copied!" : "Copy"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Alert
            show={alert.show}
            type={alert.type}
            message={alert.message}
            onClose={clearAlert}
          />
        </div>
      </div>
    </>
  );
};

export default RefereAFriend;
