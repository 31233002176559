export const CONTENT = {
  general: [
    {
      id: '4025c8d3-ea0d-5115-b359-96d977952b80',
      content: "This fuel discount (“Discount”) offer is conducted by iSaveNow Pty Ltd (ACN 668 356 009) of Level 12, 50 Cavill Avenue, Surfers Paradise, Qld 4217 (iSaveNow) in partnership with United Petroleum Pty Ltd (ACN 085 779 255) of 600 Glenferrie Road, Hawthorn Victoria 3122 (“United”)."
    },
    {
      id: '0fac28f2-8b13-5c84-a072-ec431ad8849c',
      content: "Information on how to participate in the Discount, forms part of the terms and conditions. Opting into the Discount is deemed acceptance of these terms and conditions (“Terms and Conditions”)."
    },
    {
      id: '8940afdd-9901-5373-97de-f0913cecbd3e',
      content: "If there is an inconsistency between these Terms and Conditions and anything else that refers to this Discount, these Terms and Conditions will prevail."
    }
  ],
  participate: [
    {
      id: '4d0d77bb-13b6-534e-bde1-97c2197b2aca',
      content: "You must be an eligible customer of iSaveNow to apply for this Discount."
    },
    {
      id: 'ee0f10e5-f756-5041-9327-f35f57e10ed3',
      content: "The Discount offered will be no greater than 4 cents per litre off the advertised price of fuel at participating United service stations."
    },
    {
      id: '727635d7-5160-577d-a8ce-4a937aad00cc',
      content: "To receive the Discount off the advertised price of fuel you must produce at the time of purchase of fuel at a participating United service station, a valid iSaveNow/United fuel discount card issued by United."
    },
    {
      id: '9ee9a5f1-f9e8-53b1-b082-3dfaa53b81d9',
      content: "You can only obtain this Discount using the iSaveNow/United fuel discount card, once per day."
    },
    {
      id: 'd494eee0-20ae-5865-b8db-06c790daaaad',
      content: "This Discount is valid for a purchase to a maximum is 150 litres in any one single transaction."
    },
    {
      id: '3b2e5490-f1b6-5be9-b911-3d13eaa649e4',
      content: "You can only be a member of one fuel discount card program offered by United Petroleum."
    },
    {
      id: '2b2bfdab-234d-51a0-bca6-ce9b8a5a3d42',
      content: "The fuel discount is not valid in conjunction with Fleet Card, United Card, Motorcharge, Motorpass or in conjunction with any other fuel offers or discounts unless specified otherwise.  "
    },
    {
      id: '7f24423f-4c31-51a9-bda9-ed6c79d2beaa',
      content: "United or iSaveNow may change, suspend, or terminate the conditions of the fuel discount offer or these terms from time to time at their discretion and without notice."
    },
    {
      id: '241dbc50-8e89-5fbe-b82e-7c482b4eb3f0',
      content: "By joining this Discount program, you agree to these Terms and Conditions and any variation. You also agree to provide United with any changes to your contact details."
    }
  ]
}