export default function GiveawaysCompetition() {
  return (
    <div className="container">
      <p className="font-bold mb-2">
        These are the terms and conditions of a competition promoted by:
      </p>
      <p className="mb-2">ISaveNow Pty Ltd ACN 668 356 009 of Level 12, 50 Cavill Avenue, Surfers Paradise QLD 4217 (“the Promoter”).</p>
      <p className="font-bold mb-2 text-center">
        Promotional Competition One
      </p>
      <p className="font-bold mb-2">
        Promotional Period:
      </p>
      <p className="mb-2">Start date: 14 June 2024 12am AEST</p>
      <p className="mb-2">End date:  28 June 2024 11:59pm AEST</p>
      <p className="mb-2">ACT Permit - TP24/01268</p>
      <p className="mb-2">NSW Permit - NTP/10180</p>

      <p className="mb-2">
        <strong className="font-bold">Eligible entrants: </strong><br></br>
        All current subscribers (paying members) and all customers that have purchased a relevant iSaveNow Premium package of the Promoter, residing in QLD, NSW, ACT, VIC, TAS, NT and WA, who are over the age of 18 years is automatically entered into the draw.
      </p>
      <p className="mb-2">
        <strong className="font-bold">Ineligible: </strong>
        <ul>
          <li>Residents of South Australia are not eligible to enter</li>
          <li>Anyone under the age of 18 is not eligible to enter</li>
          <li>All employees of the company and its associated agencies/companies are not eligible to enter</li>
          <li>Executives directly associated with the conduct of this Promotion and their immediate family members of iSaveNow are not eligible to enter. "Immediate family member" means any of the following: spouse, ex-spouse, de-facto spouse, child or step-child (whether natural or by adoption), parent, stepparent, grandparent, step-grandparent, uncle, aunt, niece, nephew, brother, sister, step-brother, step-sister or 1st cousin</li>
        </ul>

      </p>
      <p className="mb-2">
        <strong className="font-bold">Prize: </strong>
        $100,000 Cash.
      </p>
      <p className="mb-2">
        <strong className="font-bold">Major Prize: </strong><br></br>
        The Major Prize and runners-up (only to be used should the prize be unclaimed) are drawn by computerised random selection at the office of the Promoter on 29 June 2024 9:00am AEST.
      </p>
      <p className="mb-2">
        <strong className="font-bold">Winner notification: </strong><br></br>
        <p>The winner will be contacted by email or phone on 29 June 2024 10:00am AEST.</p>
        <p>Upon being notified, winners must claim their prize by replying to the Promoter nominating a deposit account held in their own name at an Australian Authorised Deposit Taking Institution for the deposit of the relevant prize and provide a current and valid driver’s licence or other recognised photographic identification that includes the winners’ address. </p>
        <p>The winners’ details will be published on the iSaveNow public website, within 30 days of the Major Prize Draw or Unclaimed Prize Draws. </p>
      </p>
      <p className="mb-2">
        <strong className="font-bold">Unclaimed Prizes: </strong><br></br>
        The Major Prize must be claimed by 27 September 2024 12pm AEST. In the event of an unclaimed prize, the first runner-up from the original computerised random selection will be notified. This winner will be notified as per above “winner notification” on 30 September 2024.
      </p>
      <p className="font-bold mb-2 text-center">
        General Terms and Conditions of Entry
      </p>
      <div className="flex flex-col gap-3">
        <p>1. The entrant agrees and acknowledges that they have read these Terms and Conditions of Entry and that entry into the Promotion is deemed to be acceptance of these Terms and Conditions</p>
        <p>2. The Promotion commences on the Start Date and ends on the End Date ("Promotional Period"). Entries are deemed to be received at the time of receipt by the Promoter and not at the time of transmission or deposit by the entrant. Records of the Promoter and its agencies are final and conclusive as to the time of receipt.</p>
        <p>3. Valid and eligible entries will be accepted during the Promotional Period.</p>
        <p>4. The maximum number of entries per entrant will be capped at 4,510.</p>
        <p>5. Draw:</p>
        <div className="pl-2 flex flex-col gap-[10px]">
          <p>a) The draw will take place at the times nominated above and will be conducted by a draw conductor nominated by the Promoter.</p>
          <p>b) The draw will take place at the premises of the Promoter using computerised random selection.</p>
          <p>c) The draw conductor may draw additional reserve entries in case of an invalid entry or entrant.</p>
          <p>d) If a draw is scheduled on the weekend or a public holiday, the draw will be conducted at the same time and location on the following business day.</p>
          <p>e) The Promoter will ensure each draw is open for public scrutiny and anyone can request to view the certificate of compliance.</p>
          <p>f) The winner of a drawn prize is determined by chance.</p>
        </div>
        <p>6. All reasonable attempts will be made to contact each winner.</p>
        <p>7. If any winner chooses not to take their prize (or is unable to), or does not take or claim a prize by the time specified by the Promoter, or is unavailable, they forfeit the prize and the Promoter is not obliged to substitute the prize.</p>
        <p>8. No entry fee is charged by the Promoter to enter the Promotion. Where entry is allowed online, there is no additional cost to enter the Promotion other than any cost paid by the entrant to access the website or social media platform of entry via their Internet service provider.</p>
        <p>9. The prize will be awarded to the person named in the entry and any entry that is made on behalf of an entrant or by a third party will be invalid. If there is a dispute as to the identity of an entrant or winner, the Promoter reserves the right, in its sole discretion, to determine the identity of the entrant or winner.</p>
        <p>10. All Entrants' personal information will be collected, stored and used by the Promoter in accordance with its Privacy Policy available on the Promoter’s website, www.isavenow.com.au.The Promoter may use this information for identification verification, or, future marketing purposes regarding its products, including contacting the entrant electronically.</p>
        <p>11. If for any reason any aspect of this Promotion is not capable of running as planned, including by reason of computer virus, communications network failure, bugs, tampering, unauthorised intervention, fraud, technical failure or any cause beyond the control of the Promoter, the Promoter may in its sole discretion cancel, terminate, modify or suspend the Promotion and invalidate any affected entries, or suspend or modify a prize, subject to State or Territory regulation.</p>
        <p>12. The Promoter reserves the right, at any time, to validate and check the authenticity of entries and entrant's details (including an entrant's identity, age and place of residence). In the event that a winner cannot provide suitable proof as required by the Promoter to validate their entry, the winner will forfeit the prize in whole and no substitute will be offered. </p>
        <p>13. Incomplete, indecipherable, inaudible, incorrect and illegible entries, as applicable, will at the Promoter's discretion be deemed invalid and not eligible to win.</p>
        <p>14. The use of any automated entry software or any other mechanical or electronic means that allows an individual to automatically enter repeatedly is prohibited and may render all entries submitted by that individual invalid.</p>
        <p>15. The Promoter reserves the right to disqualify entries in the event of non-compliance with these Terms and Conditions. In the event that there is a dispute concerning the conduct of the Promotion, the decision of the Promoter is final and binding on each entrant and no correspondence will be entered into by the Promoter with any claimant. </p>
        <p>16. The Promoter and its associated agencies and companies will not be liable for any loss (including, without limitation, indirect, special or consequential loss or loss of profits), expense, damage, personal injury or death which is suffered or sustained (whether or not arising from any person's negligence or wilful misconduct) in connection with this Promotion or accepting or using any prize (or recommendation), except for any liability which cannot be excluded by law (in which case that liability is limited to the minimum allowable by law).</p>
      </div>
    </div>
  )
}
