import { Link } from "react-router-dom"


const BannerOffer = ({ offer }) => {
    return <div className="h-auto position-absolute top-50 start-50 translate-middle" style={{ width: "max-content" }}>
        <span className="badge rounded-pill text-bg-primary mb-2">{offer.pill}</span>
        <h1 className="fw-bold mb-2">{offer.cashback}</h1>
        <div>{offer.merchant}</div>
        <Link to={offer.link} type="button" className="btn btn-outline-light rounded-pill ps-4 pe-4 mt-4">Shop Now</Link>
    </div>
}

const BannerWithTitle = ({content}) => {
    return <div className="h-auto position-absolute top-50 start-50 translate-middle" style={{ width: "max-content" }}>
        <h1 className="fw-bold mb-2" style={{color:content.txtColor, fontSize: content.txtSize}}>{content.title}</h1>
    </div>
}

const BannerWithTitleAndButton = ({content}) => {
    return <div className="h-auto position-absolute top-50 start-50 translate-middle" style={{ width: "max-content" }}>
        <h1 className="fw-bold mb-2">{content.title}</h1>
        <Link to={content.link} type="button" className="btn btn-outline-light rounded-pill ps-4 pe-4 mt-4 ms-auto me-auto">Shop Now</Link>
    </div>
}

export { BannerOffer, BannerWithTitle, BannerWithTitleAndButton }