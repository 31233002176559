import { useState, useEffect } from 'react';

const useAlert = () => {
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });

  const showAlert = (type, message) => {
    setAlert({ show: true, message, type });
  };

  const clearAlert = () => {
    setAlert({ show: false, message: '', type: '' });
  };

  useEffect(() => {
    let timerId;
    if (alert.show) {
      // Set a timer to clear the alert after 10 seconds
      timerId = setTimeout(clearAlert, 5000);
    }

    // Cleanup function to clear the timer if the alert is dismissed manually or component unmounts
    return () => clearTimeout(timerId);
  }, [alert.show]);

  // Reuse the existing methods, but have them call `showAlert` with the appropriate type
  const showSuccessAlert = (message) => showAlert('success', message);
  const showErrorAlert = (message) => showAlert('danger', message);
  const showWarningAlert = (message) => showAlert('warning', message);

  return { alert, showSuccessAlert, showErrorAlert, showWarningAlert, clearAlert };
};

export default useAlert;
