import { useContext, useEffect, useState } from "react";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import { useParams } from "react-router-dom";
import { OffersContext } from "../../context/offers/OffersContext";
import FiveOfferThumb from "../../components/thumbnails/FiveOfferThumb";
import PageHeader from "../../components/masterlayout/PageHeader";

export default function OffersSearch() {
  const { offers } = useContext(OffersContext);
  const params = useParams();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [type, setType] = useState();
  const searchparam = params.searchparam;
  useEffect(() => {
    // const type = params.type
    setBreadcrumbs([
      { label: "Home", path: "/" }
      // Do not include the current page here
    ])
    // setType(type)
  }, []);


  return (
    <>
      <div>
        <PageHeader title={"Search"} breadcrumbs={breadcrumbs} />
      </div>
      <div className="container">
        <div className="row">
          {
            (offers) ?
              offers["all"]?.map((offer, index) => {
               

                if ((offer.title.toUpperCase().includes(searchparam.toUpperCase())
                  || offer.mName.toUpperCase().includes(searchparam.toUpperCase())
                  || offer.desc.toUpperCase().includes(searchparam.toUpperCase()))
                  && offer?.tgs[0] !== "Giveaway" 
                    && offer?.tgs[0] !== "How" 
                    && offer?.tgs[0] !== "About"
                    && offer?.tgs[0] !== "ISN_Winners")

                  return <FiveOfferThumb sectiontype={"search"} offer={offer} key={index} parenttype="page" />;
              })
              : ""
          }
        </div>
      </div>
    </>
  );
}
