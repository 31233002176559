import "./userProfile.css";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import { useNavigate } from "react-router-dom";

const UserProfile = ({ name }) => {
  const navigate = useNavigate();
  const userSessionCookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith("userSession"))
    ?.split("=")[1];

  const userSession = userSessionCookie
    ? JSON.parse(decodeURIComponent(userSessionCookie))
    : null;

  const handleLogoutClick = async () => {
    try {
      const params = { accesstokem: userSession.idToken };

      const result = await isnofferwallapi.post("/api/logout", params);

     
      document.cookie = "userSession=; Max-Age=-1; path=/; Secure";
      document.cookie = 'loginToken=; Max-Age=0';
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Error in logout - ", error.response);
      document.cookie = "userSession=; Max-Age=-1; path=/; Secure";
      navigate("/");
      window.location.reload();
    }
  };

  return (
    <>
    <a className="dropdown-item isn-font-color-white" href="/user/account" >Profile </a>
    <a className="dropdown-item isn-font-color-white"  onClick={handleLogoutClick} >Logout</a>
    </>
    // <div className="container profile-pg-container">
    //   <div className="row d-flex justify-content-center">
    //     <div className="col-md-12">
    //       <div className="card p-3 py-4 profile-pg-card">
    //         <div className="text-center">
    //           <img
    //             src="/default_profile.png"
    //             width="90"
    //             className="rounded-circle"
    //           />
    //         </div>

    //         <div className="text-center mt-3">
    //           <h5 className="mt-2 mb-0 profile-pg-name">{name}</h5>

    //           <div className="px-4 mt-1">
    //             <p className="fonts"> </p>
    //           </div>
    //           <div></div>

    //           <div className="">
    //             <button
    //               className="btn btn-outline-primary px-4 profile-pg-buttons"
    //               onClick={handleLogoutClick}
    //             >
    //               Logout
    //             </button>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default UserProfile;
