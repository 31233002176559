
import { Link } from "react-router-dom";
import BrandThumb from "../thumbnails/BrandThumb";
import SixOfferThumb from "../thumbnails/SixOfferThumb";
import { useEffect, useState } from "react";
import Skeleton from "../skeleton/Skeleton";


export default function BrandSection(obj) {
    const [brandswidth, setBrandsWidth] = useState();

    const resizeWindow = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            const width = window.innerWidth;
            const itemwidth = width / 100 * 40;
            setBrandsWidth(`${itemwidth * 8}px`);
        } else {
            setBrandsWidth(`auto`);
        }
    }
    useEffect(() => {
        if (obj.offers) window.addEventListener('resize', resizeWindow)
        resizeWindow()
    }, [obj.offers]);


    return <div className="mb-3 mt-3">
        <div className="row mb-3">
            <h1 className="col isn-sub-title" style={{ color: obj.color }}>{obj?.sectiontype}</h1>
            <div className="col text-end align-middle">
                <Link style={{ textDecoration: "none", color: "black" }} to={`/brands`}>View all</Link><i className="bi bi-chevron-right"></i></div>
        </div>
        <div className="overflow-auto isn-hide-scroll">
            <div className="row" style={{ width: `${brandswidth}` }}>
                {
                    (obj?.offers)?
                    obj?.offers?.map((offer, index) => {
                        if (index < 8)
                            return <BrandThumb offer={offer} key={index} parenttype="section" />
                    })
                    : Array.from({ length: 8 }, (_, i) => <Skeleton key={i} type="brands" />)
                
                }
            </div>
        </div>
    </div>
}