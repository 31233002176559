import { useContext, useEffect, useState } from "react";
import PageHeader from "../../components/masterlayout/PageHeader"
import { FavouritesContext } from "../../context/favourites/FavouritesContext";
import { OffersContext } from "../../context/offers/OffersContext";
import FiveOfferThumb from "../../components/thumbnails/FiveOfferThumb";




export default function MyFavourites() {

    const { offers } = useContext(OffersContext);
    const { favourites } = useContext(FavouritesContext);
    const [favOffers, setFavOffers] = useState();
    const breadcrumbs = [
        { label: "Home", path: "/" },
    ]

    useEffect(() => {
        
    }, []);
    return <div>
        <div>
            <PageHeader title={"My Favourites"} breadcrumbs={breadcrumbs} />
        </div>
        <div className="container">
            <div className="row">
                {
                    offers?.all?.map((offer, index) => {
                        if(offer.fav){
                            return <FiveOfferThumb sectiontype="My Favourites" offer={offer} 
                            key={index}  parenttype="page" />

                        }
                    })
                }
            </div>
        </div>
    </div>
}