import { useContext, useEffect, useState } from "react";
import Banner from "../../components/banners/Banner";
import GiveawaySection from "../../components/sectionlayouts/GiveawaySection";
import { OffersContext } from "../../context/offers/OffersContext";
import PageHeader from "../../components/masterlayout/PageHeader";
import { Link, useParams, useSearchParams } from "react-router-dom";
import GiveawayThumb from "../../components/thumbnails/GiveawayThumb";
import { ClientContext } from "../../context/client/ClientContext";
import { useNavigate } from "react-router-dom";
import { TenantContext } from "../../context/tenant/TenantContext";
import ThreeOfferSection from "../../components/sectionlayouts/ThreeOfferSection";
import BrandThumb from "../../components/thumbnails/BrandThumb";
import { isnofferwallapi } from "../../config/axios/axiosconfig";

import './giveaway2.css';
import './giveaway2mob.css';

import { Alert } from "react-bootstrap";
import useAlert from "../../components/alert/useAlert";
import Payment from "../payment/payment";
import Modal from 'bootstrap/js/dist/modal';
import Loader from "../../components/skeleton/Loader";
import LoginModal from "../auth/LoginModal";
import DOMPurify from "dompurify";

export default function GiveAway2() {
    const { tenant } = useContext(TenantContext);
    const { offers } = useContext(OffersContext);
    const [giveaways, setGiveaways] = useState();
    const [monthNames, setMonthNames] = useState([]);
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();


    const paramMonth = useState();
    const { client, clientdispatch } = useContext(ClientContext);
    const navigate = useNavigate();
    const [stepOne, setStepOne] = useState(false);
    const [stepTwo, setStepTwo] = useState(false);
    const [signupError, setSignupError] = useState("");
    const [displayDate, setDisplayDate] = useState("");
    // const bannerItemsLeft = [{
    //     dImg: 'https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/DESKTOP_FullBanner-Hero_Image_1000-day.png',
    //     mImg: 'https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/iSN-Giveaway-Page-Assets-[700x700]-$1k-a-day-1.png',
    //     // content: "<div style='color:#002848;'><h1 className='fw-bold'>Monthly giveaways with iSaveMore</h1></div>",
    //     // link: "/giveaways/December/detail/2554"
    // }]


    const [bannerItemsFull, setBannerItemsFull] = useState([]);

    const [giveaway, setGiveaway] = useState();
    const [discountGiveaway, setDiscountGiveaway] = useState();
    const [isPaymentforReguler, setPaymentforReguler] = useState(true);


    const [cdDays, setCdDays] = useState("00");
    const [cdHours, setCdHours] = useState("00");
    const [cdMinutes, setCdMinutes] = useState("00");
    const [cdSeconds, setCdSeconds] = useState("00");
    const [userPreviousStatus, setUserPreviousStatus] = useState(null);
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const [isOpen, setIsOpen] = useState(false);
    const [isLoginOpen, setLoginIsOpen] = useState(false);

    const handleClose = () => setIsOpen(false);
    const handleShow = () => setIsOpen(true);

    const [signupMDL, setSignupMDL] = useState();
    const [signupSuccessModal, setSignupSuccessModal] = useState();
    const [offerwarningModal, setOfferwarningModal] = useState();

    const [displayRegulerTitle, setDisplayRegulerTitle] = useState(true);
    useEffect(() => {

        const redirectstatus = searchParams.get("redirect_status");
        if (redirectstatus) {
            setLoader(true);


            // http://localhost:3000/giveaways?
            //payment_intent=pi_3PE9O5JgAGwR9Z7W1i1v7c3j
            //&payment_intent_client_secret=pi_3PE9O5JgAGwR9Z7W1i1v7c3j_secret_6uCm6ZB7NEViKROYS9y03eDZo
            //&redirect_status=succeeded
            //    get - /api/user?euid={}
            if (searchParams.get("redirect_status") === "succeeded") {
                setTimeout(() => {
                    updateClientStatus();
                }, 2000);
            }



        }


        const mysignupmodal = new Modal('#signupModal');
        setSignupMDL(mysignupmodal)

    }, [])

    const setDiscountEntries = (previousPrice) => {
        for (const ent of tenant.tenantcontentconfig.giveaway.entries_section.entries) {
            if (ent.price_id === previousPrice) {
                if (ent.discount_entry) {
                    ent.discount_entry.previouse_price = ent.price
                    setDiscountGiveaway(ent.discount_entry);
                    setDisplayRegulerTitle(false);
                    break;
                }
            }
            //Check current purchesed giveaway is discount package
            if (ent.discount_entry && ent.discount_entry.price_id === previousPrice) {
                setPaymentforReguler(false);
            }
        }

    }

    const updateClientStatus = async () => {
        if (client) {
            const res = await isnofferwallapi.get("/api/user", {
                params: {
                    euid: client.euid,
                }

            });
            setUserPreviousStatus(client.subscription_status);
            if (res.data[0].subscription_status === 'active') {


                if (client) {
                    const userSessionCookie = document.cookie
                        .split("; ")
                        .find((row) => row.startsWith("userSession"))
                        ?.split("=")[1];

                    const userSession = (userSessionCookie
                        ? JSON.parse(decodeURIComponent(userSessionCookie))
                        : null);

                    const clientobj = client
                    clientobj.subscription_status = res.data[0].subscription_status;
                    clientobj.subscription_expiry_date = res.data[0].subscription_expiry_date;
                    clientobj.subscription_id = res.data[0].subscription_id;
                    clientobj.subscription_type = res.data[0].subcription_type;
                    clientdispatch({ type: "CLIENT_SUCCESS", payload: clientobj });

                    userSession.subscription_status = res.data[0].subscription_status;
                    userSession.subscription_id = res.data[0].subscription_id;
                    userSession.subscription_expiry_date = res.data[0].subscription_expiry_date;
                    userSession.subscription_type = res.data[0].subcription_type;

                    document.cookie = `userSession=${encodeURIComponent(
                        JSON.stringify(userSession)
                    )}; path=/; Max-Age=10800; Secure`;

                    const reqPath = document.cookie
                        .split("; ")
                        .find((row) => row.startsWith("request-path"))
                        ?.split("=")[1];
                    if (reqPath) {
                        document.cookie = `request-path=; path=/; Max-Age=-1; Secure`;
                        setTimeout(function () {
                            navigate(reqPath);
                        }, 3000);
                    }
                }

            }
        }
        setLoader(false);
        const myModal = new Modal('#signupSuccessModal');
        setSignupSuccessModal(myModal);
        myModal.show();
    }

    // useEffect(() => {
    //     const myModal = new Modal('#signupSuccessModal');
    //     setSignupSuccessModal(myModal);
    //     myModal.show();
    // }, [])

    const showWarning = () => {
        const myWModal = new Modal('#offerwarningModal');
        setOfferwarningModal(myWModal);
        myWModal.show();
        signupSuccessModal.hide();
    }
    const hideWarnignandOfferModal = () => {
        signupSuccessModal.hide();
        offerwarningModal.hide();
    }
    const hideWarningModal = () => {
        signupSuccessModal.show();
        offerwarningModal.hide();
    }

    const getDateSuffix = (i) => {
        let j = i % 10,
            k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    }
    const getTimewithAMPM = (hours, minute) => {
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        return `${(hours < 10) ? "0" + hours : hours}.${(minute < 10) ? "0" + minute : minute}${ampm}`
    }
    const setCountDown = (date) => {
        const dDate = new Date(date);
        const d = dDate.getTime();
        const mon = months[dDate.getMonth()]
        const day = dDate.getDate();
        const hour = dDate.getHours();
        const year = dDate.getFullYear();
        const minute = dDate.getMinutes();
        // console.log(date, dDate, mon, day, hour, year, minute)
        const displayD = `${mon} ${getDateSuffix(day)} ${year} at ${getTimewithAMPM(hour, minute)} AEST`
        setDisplayDate(displayD);

        var x = setInterval(function () {

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = d - now;
            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            days = days < 0 ? "0" : days;
            hours = hours < 0 ? "0" : hours;
            minutes = minutes < 0 ? "0" : minutes;
            seconds = seconds < 0 ? "0" : seconds;

            setCdDays(days < 10 ? "0" + days : days);
            setCdHours(hours < 10 ? "0" + hours : hours);
            setCdMinutes(minutes < 10 ? "0" + minutes : minutes);
            setCdSeconds(seconds < 10 ? "0" + seconds : seconds);
            // If the count down is finished, write some text
            if (distance < 0) {
                clearInterval(x);
                // document.getElementById("demo").innerHTML = "EXPIRED";
            }
        }, 1000);
    }


    useEffect(() => {

        if (tenant) {
            setGiveaway(tenant.tenantcontentconfig.giveaway);
            setCountDown(tenant.tenantcontentconfig.giveaway.offer.validToYMD);
            const selectedPrice = searchParams.get("price");
            if (selectedPrice && searchParams.get("redirect_status") === "succeeded") {
                setDiscountEntries(selectedPrice);

            }
        }
    }, [tenant]);

    useEffect(() => {
        const items = [];

        if (client) {
            if (client?.subscription_status !== "active") {
                //ugrade to isavemore
                items.push({
                    dImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/DESKTOP-Full-Banner-GIVEAWAYS.png",
                    mImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/MOBILE-Banners-GIVEAWAY?.png",
                    isFunction: true,
                });
            } else {
                //more user sees.
                items.push({
                    dImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/DESKTOP-Banner-iSaveMORE-Giveaway-2.png",
                    mImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/MOBILE-Banner-Giveaways-2.png",
                    //link: "/giveaways",
                });
            }

            setStepOne(true);
            setSignupPage("d-none");
            setPaymentPage("d-block");
        } else {
            //guestuser
            items.push({
                dImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/DESKTOP-Full-Banner-GIVEAWAYS.png",
                mImg: "https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/MOBILE-Banners-GIVEAWAY?.png",
                link: "/login",
            });

        }
        setBannerItemsFull(items);

    }, [client]);




    useEffect(() => {
        if (!client) {
            // navigate("/login");
        }


        if (offers) {
            const monthsObj = { all: offers["Giveaway"] }
            // monthsObj[months[new Date(offer.pub).getMonth()]]

            offers["Giveaway"]?.map((offer, index) => {
                const month = months[new Date(offer.vTo).getMonth()];
                // const offer = res.data[i];
                const arr = [offer];
                monthsObj[month] = monthsObj[month] ?
                    [offer, ...monthsObj[month]] : arr;

                // monthsObj[months[new Date(offer.pub).getMonth()]] 



            })
            setGiveaways(monthsObj)
            let ms = []
            if (params.month) {
                ms.push(params.month)
            } else {
                for (const monthName in monthsObj) {
                    if (monthName !== "all") {
                        ms.push(monthName)
                        // setMonthNames([monthName, ...monthNames])

                    }
                }
            }
            setMonthNames(ms)
        }
    }, [offers, params]);












    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [stateError, setStateError] = useState("");

    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [referrer, setReferrer] = useState("");
    const [loader, setLoader] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [isOver18, setIsOver18] = useState(false);
    const [isOver18Error, setIsOver18Error] = useState("");
    const [signupPage, setSignupPage] = useState("d-block");
    const [paymentPage, setPaymentPage] = useState("d-none");
    const [state, setState] = useState("0");



    const [entryText, setEntryText] = useState("");
    const [premiumText, setPremiumText] = useState("");
    const [packageVal, setPackageVal] = useState("");
    const [stripeKey, setStripeKey] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);
    const [priceId, setPriceId] = useState("");
    const [term, setTerm] = useState("");
    const [detail, setDetail] = useState("");
    const [euid, setEuid] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState("");



    const clickBuy = async (selectedGiveaway) => {
        if (signupSuccessModal) {
            signupSuccessModal.hide();
        }
        setClientSecret(null);
        setStripeKey(null);
        setPackageVal(selectedGiveaway.price);
        setEntryText(selectedGiveaway.no_of_entries + ' ' + selectedGiveaway.entry_text);
        setPremiumText(selectedGiveaway.premium_access);
        setPriceId(selectedGiveaway.price_id);
        setTerm(selectedGiveaway.payment_term);
        setDetail(selectedGiveaway.premium_access);
        setSelectedPackage(selectedGiveaway.product_name);
        if (client) {
            setLoader(true);
            await createOnStripe(client?.euid, {
                firstName: client.firstname,
                // lastName: data ? data.lastName : lastName,
                email: client.email,
                //euid: euid,
                priceId: selectedGiveaway.price_id,
                term: selectedGiveaway.payment_term,
                detail: selectedGiveaway.premium_access
            });
            setLoader(false);
        } else if (euid) {
            setLoader(true);
            await createOnStripe(euid, {
                firstName: firstName,
                email: email,
                priceId: selectedGiveaway.price_id,
                term: selectedGiveaway.payment_term,
                detail: selectedGiveaway.premium_access
            });
            setLoader(false);
        } else if (discountGiveaway) {
            const signUpData = document.cookie
                .split("; ")
                .find((row) => row.startsWith("signUpData"))
                ?.split("=")[1];

            const signUpDataExt = (signUpData
                ? JSON.parse(decodeURIComponent(signUpData))
                : null);

            if (signUpDataExt) {
                setLoader(true);
                await createOnStripe(signUpDataExt.euid, {
                    firstName: signUpDataExt.firstName,
                    email: signUpDataExt.email,
                    priceId: selectedGiveaway.price_id,
                    term: selectedGiveaway.payment_term,
                    detail: selectedGiveaway.premium_access
                });
                setLoader(false);
            }

            setSignupPage("d-none");
            setPaymentPage("d-block");
        }


        signupModal(true)
    }


    const {
        alert,
        showSuccessAlert,
        showErrorAlert,
        showWarningAlert,
        clearAlert,
    } = useAlert();

    // const navigate = useNavigate();

    // useEffect(() => {
    //     // Parse the URL query string
    //     const queryParams = new URLSearchParams(window.location.search);
    //     const referrerParam = queryParams.get("referrer");

    //     // Set the referrer state to the parameter value or default to an empty string
    //     setReferrer(referrerParam || "");
    // }, []);

    // useEffect(() => {
    //     // When the component mounts
    //     document.body.classList.add("login-background");

    //     // When the component unmounts
    //     return () => {
    //         document.body.classList.remove("login-background");
    //     };
    // }, []);

    const validateName = (name, type) => {
        if (!name) return `Full name is required.`;
        if (name.length < 2 || name.length > 100)
            return `full name should be between 2 and 100 characters.`;
        if (!/^[A-Za-z ]+$/.test(name))
            return `full name should only contain letters.`;
        return "";
    };

    const validateEmail = (email) => {
        if (!email) return "Email address is required.";
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) return "Please enter a valid email address.";
        return "";
    };

    const validateState = (state) => {
        // if (!state) return "Email address is required.";
        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (state === "0") return "Please select a state.";
        return "";
    };

    const validatePhoneNumber = (phoneNumber) => {
        if (!phoneNumber) {
            setPhoneNumberError("Phone number is required.");
            return false;
        }

        // Regex for Australian phone numbers (landline and mobile)
        const phoneRegex =
            /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$|^(?:\+?61|0)4\d(?:[ -]?[0-9]){7}$/;
        if (!phoneRegex.test(phoneNumber)) {
            setPhoneNumberError("Please enter a valid Australian phone number.");
            return false;
        }
        setPhoneNumberError("");
        return true;
    };

    const validateOver18 = (isOver18) => {
        if (!isOver18) {
            setIsOver18Error("You must be 18 years or older to register.");

            return false;
        }
        setIsOver18Error("");
        return true;
    };

    const hasValidationErrors = () => {
        if (
            firstNameError === "" &&
            // lastNameError === "" &&
            emailError === "" &&
            stateError === "" &&
            // confirmPasswordError === "" &&
            validatePhoneNumber(phoneNumber) &&
            validateOver18(isOver18)
        ) {
            return true;
        }

        return false;
    };



    const resetForm = () => {
        setEmail("");
        setPassword("");
        setLastName("");
        setFirstName("");
        setState("0");
        setIsOver18(false);
    };

    const handleSubmit = async (event) => {

        event.preventDefault();

        document.getElementById("btnSubmitPD").disabled = true
        setFirstNameError(validateName(firstName, "First"));
        // setLastNameError(validateName(lastName, "Last"));
        // setConfirmPasswordError(validateConfirmPasswords(confirmPassword));
        setEmailError(validateEmail(email));
        // setPasswordError(validatePassword(password));

        if (hasValidationErrors()) {
            const params = {
                username: email,
                password,
                firstname: firstName,
                lastname: "",
                referrer,
                phonenumber: phoneNumber,
                state: state
            };

            try {
                setLoader(true);
                const result = await isnofferwallapi.post("/api/signup", params);

                if (result.data.code === "200") {
                    setEuid(result.data.payload.User.Username);
                    await createOnStripe(result.data.payload.User.Username);
                    setSignupPage("d-none");
                    setPaymentPage("d-block");
                    setStepOne(true);
                    setLoader(false);
                    document.getElementById("btnSubmitPD").disabled = false

                    let signUpData = {
                        firstName: firstName,
                        euid: result.data.payload.User.Username,
                        email: email
                    }
                    document.cookie = `signUpData=${encodeURIComponent(
                        JSON.stringify(signUpData)
                    )}; path=/; Max-Age=10800; Secure`;

                    // showWarningAlert("Please verify the email address");

                    // resetForm();
                    // const encodedEmail = encodeURIComponent(email);
                    // navigate(`/otp?email=${encodedEmail}`);
                } else {
                    setLoader(false);
                    setSignupError(result.data.message.message);
                    document.getElementById("btnSubmitPD").disabled = false;


                    // showErrorAlert(result.data.message.message);
                }
            } catch (error) {
                setLoader(false);
                console.error("Sign up error", error);
                document.getElementById("btnSubmitPD").disabled = false;
                setSignupError((error.response) ? error.response.data.payload.message : "Something went wrong , Please try again")
                // if (error.response) {
                //     showErrorAlert(error.response.data.payload.message);
                // } else {
                //     showErrorAlert("Something went wrong , Please try again");
                // }
            }
        }
        else {
            document.getElementById("btnSubmitPD").disabled = false
        }


    };


    const createOnStripe = async (euid, data) => {
        try {
            const res = await isnofferwallapi.post("/api/giveaways/checkout", {
                firstName: data ? data.firstName : firstName,
                // lastName: "",
                email: data ? data.email : email,
                euid: euid,
                priceId: data ? data.priceId : priceId,
                paymentTerm: data ? data.term : term,
                details: data ? data.detail : detail

            });
            setClientSecret(res.data.clientSecret);
            setStripeKey(res.data.stripeKey);
        } catch (error) {
            console.log(error)
            if (error.response) {
                showErrorAlert("Something went wrong , Please try again");
            }
        }
    }


    // const [signupMDL, setSignupMDL] = useState();

    const signupModal = (isShow, redirectlink) => {
        // console.log(isShow)
        // const mysignupmodal = new Modal('#signupModal');
        // mysignupmodal.show()

        // setTimeout(() => { mysignupmodal.hide() }, 2000);
        (isShow) ? signupMDL.show() : signupMDL.hide()

        if (redirectlink) setTimeout(() => { navigate(redirectlink); }, 500)

    }
    const handleLoginClose = () => setLoginIsOpen(false);
    const handleLoginShow = () => {
        signupModal(false);
        setLoginIsOpen(true);
    }
    // const loginModal = () => {
    //     setIs
    // }

    return <div className="giveaway-page">
        {/* <PageHeader title={(params.month) ? params.month : "Giveaways"} breadcrumbs={breadcrumbs} /> */}

        <div>
            <Link className="giveaway-link-header-banner"
                to={(giveaway?.header_banner.showlink) ? `/giveaways/${months[new Date(giveaway?.offer.validTo).getMonth()]}/detail/${giveaway?.offer.id}` : "#"}>
                <img src={giveaway?.header_banner.image.web} className="d-none d-md-block w-100" />
                <img src={giveaway?.header_banner.image.mobile} className="d-block d-md-none w-100" />
            </Link>
        </div>

        <div className=" giveaway-primary-text">
            {
                (giveaway?.timer_section.show) ?
                    <div id="divCountDownSection container" className="text-center mt-5 mb-5">
                        <h3 className="fw-bold">{giveaway?.timer_section.header}</h3>
                        {/* <h5>{displayDate}</h5> */}
                        <h5>{giveaway?.timer_section.closedatetext}</h5>

                        <small>{giveaway?.timer_section.sub_text}</small>
                        <div className="mt-4 mb-4">
                            <div className="giveaway-time-box">
                                <div >{cdDays}</div>
                                Days
                            </div>
                            <h1 className="giveaway-time-box-colon ">:</h1>
                            <div className="giveaway-time-box">
                                <div>{cdHours}</div>
                                Hours
                            </div>
                            <h1 className="giveaway-time-box-colon">:</h1>
                            <div className="giveaway-time-box">
                                <div>{cdMinutes}</div>
                                Minutes
                            </div>
                            <h1 className="giveaway-time-box-colon">:</h1>
                            <div className="giveaway-time-box">
                                <div>{cdSeconds}</div>
                                Seconds
                            </div>
                        </div>
                        <a href={giveaway?.timer_section.button.link} className="btn giveaway-btn-countdown-enternow rounded-5 isn-btn-premium-primary">
                            {giveaway?.timer_section.button.text}
                        </a>
                    </div>
                    :
                    ""
            }
            {
                (giveaway?.entries_section.show) ?
                    <div id={giveaway?.entries_section.section_id} className="giveaway-entry-container-main mt-5 mb-5 giveaway-entry-purple p-md-5 pt-5 ps-3 pe-3 ">
                        <div className="container">
                            <h1 className="text-center giveaway-entry-header m-auto mb-4 fw-semibold">{giveaway?.entries_section.header}</h1>
                            <h4 className="text-center giveaway-entry-header m-auto mb-4">{giveaway?.entries_section.sub_header}</h4>

                            <div className="row">
                                {
                                    giveaway?.entries_section.entries.map((entry) => {
                                        return <div className={`col-12 col-md-6 p-2`}>
                                            <div className={`p-3 rounded-5 giveaway-entry-container ${entry.isDefault ? "giveaway-bg-blue giveaway-subscription-entry-default-container" : "bg-white"}`}>
                                                <div className={(entry.isDefault) ? "giveaway-subscription-entry-default" : "giveaway-subscription-entry"}>
                                                    {
                                                        entry.product_name
                                                    }
                                                </div>

                                                <div className="row">
                                                    <p className={`col giveaway-entry-count-text fw-semibold fs-5 mb-0 align-content-center ${(entry.payment_term === "Subscription") ? "giveaway-entry-count-text-sub" : ""}`}>
                                                        <h1 className="d-inline-block display-5 fw-semibold mb-0"><span dangerouslySetInnerHTML={{ __html: `${entry.no_of_entries_prefix}&nbsp;` }}></span>{entry.no_of_entries}</h1> &nbsp;
                                                        {entry.entry_text}
                                                        {/* <p className="d-inline-block">{entry.entry_text}</p> */}
                                                    </p>
                                                    <div className="col-5 col-md-3 text-center">
                                                        {
                                                            (entry.payment_term === "Subscription") ?
                                                                <div>
                                                                    <h1 className="m-0 d-inline-block display-5 fw-semibold">${entry.price}</h1>
                                                                    <p className="d-inline-block isn-fs-10 m-0">{entry.payment_frequency}</p>
                                                                    <p className="giveaway-payment-freq-text">{entry.subscription_cancel}</p>
                                                                </div>
                                                                :
                                                                <div>
                                                                    <h1 className="m-0 display-5 fw-semibold">${entry.price}</h1>
                                                                    <p className="giveaway-payment-freq-text">{entry.payment_frequency}</p>

                                                                </div>
                                                        }

                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col align-content-center">
                                                        <p className="giveaway-entry-desc">{entry.premium_access}</p>
                                                    </div>
                                                    <div className="col-5 col-md-3 text-center">
                                                        {
                                                            (client && client.subscription_status === "active"
                                                                && (client.subscription_type === ""
                                                                    || client.subscription_type === "RECURRING")
                                                                && entry.payment_term === "Subscription") ?
                                                                <button className={`btn giveaway-btn-entry-buy-${entry.product_name.toLowerCase()} rounded-5 isn-btn-premium-primary giveaway-entry-btn fw-semibold`}
                                                                    // data-bs-toggle="modal"
                                                                    // data-bs-target="#signupModal"
                                                                    onClick={() => clickBuy(entry)}
                                                                    disabled>
                                                                    {entry.button.text}
                                                                </button>
                                                                :
                                                                <button className={`btn giveaway-btn-entry-buy-${entry.product_name.toLowerCase()} rounded-5 isn-btn-premium-primary giveaway-entry-btn fw-semibold`}
                                                                    // data-bs-toggle="modal"
                                                                    // data-bs-target="#signupModal"
                                                                    onClick={() => clickBuy(entry)}>
                                                                    {entry.button.text}
                                                                </button>
                                                        }



                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    })
                                }
                            </div>



                            <div className="text-center mt-4">
                                <h5 className="fw-bold"><i class="bi bi-lock-fill giveaway-secure-paddlock"></i> {giveaway?.entries_section.security.text}</h5>
                            </div>
                            <div className="row justify-content-center" >
                                <div className="col-12 col-md-6 row justify-content-center justify-content-md-end">
                                    {
                                        giveaway?.entries_section.payment_options.map((option, index) => {
                                            return (index < 4) ? <div className="giveaway-payment-options" style={{
                                                backgroundImage: `url(${option})`
                                            }}>
                                            </div> : ""
                                        })
                                    }
                                </div>
                                <div className="col-12 col-md-6 row justify-content-center justify-content-md-start mb-5">
                                    {
                                        giveaway?.entries_section.payment_options.slice(4).map((option, index) => {
                                            return (index < 4) ? <div className="giveaway-payment-options" style={{
                                                backgroundImage: `url(${option})`
                                            }}>
                                            </div> : ""
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }
            {
                (giveaway?.isn_premium_section.show) ?
                    <div id="divPremiumSection" className="container text-center mt-5 mb-5">
                        <h2>{giveaway?.isn_premium_section.header}</h2>
                        <div className="m-auto mb-4 mt-4 p-5 rounded-3 text-start guveaway-premium-box">
                            {
                                giveaway?.isn_premium_section.points.map((point) => {
                                    return <p><i class="bi bi-check2-circle"></i> {point.icon} {point.text}</p>
                                })
                            }
                        </div>
                        <a href={giveaway?.timer_section.button.link} className="btn giveaway-btn-premium-signmeup rounded-5 isn-btn-premium-primary">
                            {giveaway?.isn_premium_section.button.text}
                        </a>
                        <small className="d-block isn-fs-10 mt-3">{giveaway?.isn_premium_section.terms_text}</small>
                    </div>
                    : ""

            }
            {
                (giveaway?.Previous_Win_Section.show) ?
                    <div className="mt-5 mb-5 container">
                        <h2 className="text-center">{giveaway?.Previous_Win_Section.header}</h2>
                        <div className="text-center">

                            {

                                offers?.["ISN_Winners"]?.toReversed().slice(offers?.["ISN_Winners"].length > 3 ? (offers?.["ISN_Winners"].length - 4) : 0).map((offer, index) => {
                                    return (index < 4) ? <Link to={`/about/aboutus/Previous Winners/${offer.id}`} className="runded-3 d-inline-block m-3" style={{ width: "40%" }}>
                                        <div className="ratio ratio-16x9">
                                            <img src={offer.img[0]}
                                                className="card-img-top rounded-3 previous-card-img" alt="..." />
                                        </div>
                                    </Link>
                                        : ""

                                })
                            }


                        </div>
                        <div className="text-center">
                            <a href={giveaway?.timer_section.button.link} className="btn giveaway-btn-prevwinner-enternow rounded-5 isn-btn-premium-primary">
                                {giveaway?.Previous_Win_Section.button.text}
                            </a>
                        </div>

                    </div>
                    : ""
            }
            {
                (giveaway?.prev_member_section.show) ?
                    <div className="mt-5 mb-5 container">
                        <h2 className="text-center">{giveaway?.prev_member_section.header}</h2>
                        <div className="text-center giveaway-member-container">
                            {
                                giveaway?.prev_member_section.members.map((member) => {
                                    return <div className="row mt-4">
                                        <div className="ms-4 giveaway-member-profile-pic" >
                                            <div className="ratio ratio-1x1">
                                                <img src={member.image} className="" />
                                            </div>
                                        </div>
                                        <div className="col text-start">
                                            <h5>{member.name}, {member.state}</h5>
                                            <p>{member.win}</p>
                                            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(member.desc) }}></p>
                                        </div>
                                    </div>
                                })

                            }
                        </div>
                    </div>
                    : ""

            }
            {
                (giveaway?.faq_section.show) ?
                    <div className="mt-5 mb-5 container">
                        <h2 className="text-center">{giveaway?.faq_section.header}</h2>
                        <div className="m-auto giveaway-faq-container">
                            <div class="accordion" id="accordionExample">
                                {
                                    giveaway?.faq_section.faqs.map((faq, index) => {
                                        return <div class="accordion-item border-0 m-3">
                                            <h2 class="accordion-header rounded-5 overflow-hidden">
                                                <button class={`giveaway-faq-accordian-button accordion-button ${(index === 0) ? "" : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target={`#q${index}`} aria-expanded="true" aria-controls="collapseOne">
                                                    {faq.question}
                                                </button>
                                            </h2>
                                            <div id={`q${index}`} className={`accordion-collapse collapse ${(index === 0) ? "show" : ""} `} data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    {faq.answer}
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    : ""
            }
            {
                (giveaway?.who_we_are_section.show) ?
                    <div className="mt-5 mb-5 container">
                        <h2 className="text-center">{giveaway?.who_we_are_section.header}</h2>
                        <div className="text-center" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(giveaway?.who_we_are_section.text_paras) }}></div>
                        {
                            (giveaway?.who_we_are_section.button.show) ?
                                <div className="text-center">
                                    <a href={giveaway?.who_we_are_section.button.link} className="btn giveaway-btn-prevwinner-enternow rounded-5 isn-btn-premium-primary">
                                        {giveaway?.who_we_are_section.button.text}
                                    </a>
                                </div>
                                : ""
                        }

                    </div>
                    : ""
            }
            {
                (giveaway?.brands_section.show) ?
                    <div className="mt-5 mb-5">
                        <h2 className="text-center">{giveaway?.brands_section.header}</h2>
                        <div className="mt-4">
                            <Link to={`/brands`} className="giveaway-link-brands">
                                <img src={giveaway?.brands_section.image.web} className="d-none d-md-block w-100" />
                                <img src={giveaway?.brands_section.image.mobile} className="d-block d-md-none w-100" />
                            </Link>
                        </div>
                    </div>
                    :
                    ""
            }



            {/* {
                (params.month) ?
                    <div className="row">
                        {
                            giveaways?.[params.month]?.toReversed()?.map((offer, index) => {
                                return <GiveawayThumb thumblink={(params.month)}
                                    sectiontype={params.month} offer={offer} key={index} parenttype="page" />
                            })
                        }
                    </div>
                    :
                    (monthNames) ?
                        monthNames.map((mname, index) => {
                            return <div className="mt-5">
                                <GiveawaySection sectionlink={`/${mname}`} sectiontype={mname} offers={giveaways?.[mname]} color="#D0E518" showviewmore={!params.month} />
                            </div>
                        })
                        : ""

            } */}

        </div>

        <div class="modal fade" id="signupModal" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content giveaway-bg-blue" style={{ borderRadius: "0 0 90px 0" }}>
                    <div class="modal-header border-0">
                        {/* <img src="/login/log.png" alt="iSaveNow Logo" className="m-auto" style={{ width: "35%" }} /> */}
                        {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}

                        <button type="button" class="giveaway-signup-modal-close btn-close end-0 me-3 mt-3 position-absolute text-bg-light" data-bs-dismiss="modal" aria-label="Close"></button>

                    </div>
                    <div class="modal-body" style={{ minHeight: "500px" }}>

                        <ul class="border-0 me-3 ms-3 nav nav-tabs pb-2 pt-2 row text-center text-white">
                            {
                                (client || (!client && discountGiveaway)) ? "" :
                                    <li className={`giveaway-tabs col nav-item p-2 ${(stepOne) ? "border-bottom" : "border-end border-2 fw-bold border-start border-top"}`}>
                                        <div className="giveaway-text-green">Personal Details</div>
                                    </li>
                            }
                            <li className={`giveaway-tabs ${(stepOne) ? "border-start border-top border-end text-white fw-bold border-2" : "border-bottom"} col nav-item p-2 `}>
                                <div className="giveaway-text-green">
                                    {
                                        (term === "Subscription") ? "Payment Details" : "One Time Payment"
                                    }
                                </div>
                            </li>
                        </ul>
                        <div>
                            {
                                (client || (!client && discountGiveaway)) ? "" :
                                    <div className={`${signupPage} me-3 ms-3 mt-3`}>
                                        <form onSubmit={handleSubmit} className="me-3 ms-3">
                                            {/* First Name Field */}
                                            <div class="input-group mb-3 px-3">
                                                <input
                                                    type="text"
                                                    className={`rounded-5 form-control isn-fs-13 signup-text-field ${firstNameError ? "is-invalid" : ""
                                                        }`}
                                                    id="firstName"
                                                    value={firstName}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                    placeholder="Full name"
                                                    onBlur={() =>
                                                        setFirstNameError(validateName(firstName, "First"))
                                                    }
                                                    required
                                                />
                                                <div className="invalid-feedback isn-fs-11">
                                                    {firstNameError}
                                                </div>
                                            </div>

                                            {/* Email Field */}
                                            <div class="input-group mb-3 px-3">
                                                <input
                                                    type="email"
                                                    className={`rounded-5 form-control isn-fs-13 ${emailError ? "is-invalid" : ""
                                                        }`}
                                                    id="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="Email address"
                                                    onBlur={() => setEmailError(validateEmail(email))}
                                                    required
                                                />
                                                <div className="invalid-feedback isn-fs-11">
                                                    {emailError}
                                                </div>
                                            </div>

                                            {/* Phone Number Field */}
                                            <div className="input-group mb-3 px-3">
                                                <input
                                                    type="text"
                                                    className={`rounded-5 form-control isn-fs-13 ${phoneNumberError ? "is-invalid" : ""
                                                        }`}
                                                    id="phoneNumber"
                                                    value={phoneNumber}
                                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                                    placeholder="Phone number"
                                                    onBlur={() => validatePhoneNumber(phoneNumber)}
                                                    required
                                                />
                                                <div className="invalid-feedback isn-fs-11">
                                                    {phoneNumberError}
                                                </div>
                                            </div>

                                            <div class="input-group mb-3 px-3">
                                                <select
                                                    id="cboxState"
                                                    onChange={(e) => {
                                                        setState(e.target.value);
                                                    }}
                                                    onBlur={() =>
                                                        setStateError(validateState(state))
                                                    }
                                                    required
                                                    className={`rounded-5 form-select pwd-input isn-fs-13 ${stateError ? "is-invalid" : ""}`}>
                                                    <option value="0">State</option>
                                                    <option value="New South Wales">New South Wales</option>
                                                    <option value="Queensland">Queensland</option>
                                                    <option value="South Australia">South Australia</option>
                                                    <option value="Tasmania">Tasmania</option>
                                                    <option value="Victoria">Victoria</option>
                                                    <option value="Western Australia">Western Australia</option>
                                                    <option value="Australian Capital Territory">Australian Capital Territory</option>
                                                    <option value="Northern Territory">Northern Territory</option>
                                                </select>

                                                <div className="invalid-feedback isn-fs-11">
                                                    {stateError}
                                                </div>
                                                {/* <PasswordStrengthIndicator /> */}
                                            </div>


                                            {/* Checkbox for 18+ Confirmation */}
                                            <div className="mb-3 px-3">
                                                <input
                                                    type="checkbox"
                                                    id="isOver18"
                                                    className={`form-check-input ${isOver18Error ? "is-invalid" : ""
                                                        }`}
                                                    checked={isOver18}
                                                    onChange={(e) => setIsOver18(e.target.checked)}
                                                    onBlur={() => validateOver18(isOver18)}
                                                />
                                                <label
                                                    htmlFor="isOver18"
                                                    className="giveaway-text-green form-check-label isn-fs-13 ml-2 ms-2 text-white"
                                                >
                                                    I am over 18 years old
                                                </label>
                                                {isOver18Error && (
                                                    <div className="invalid-feedback isn-fs-11">
                                                        {isOver18Error}
                                                    </div>
                                                )}
                                            </div>

                                            {/* Submit Button */}
                                            <div className="row px-4 pt-2">
                                                <button
                                                    id="btnSubmitPD"
                                                    type="submit"
                                                    className="giveaway-btn-login giveaway-signup-modal-proceed btn btn-primary border-0 rounded-pill btn-signup-pg"
                                                >
                                                    Proceed to payment
                                                </button>
                                                <div className="text-danger mt-2 isn-fs-12 text-center">
                                                    {signupError}
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <p className="mt-3 isn-fs-11">
                                                    By clicking on Proceed to payment, you agree to iSaveNow's
                                                    <br />{" "}
                                                    <Link
                                                        className="giveaway-text-green"
                                                        // to={"/about/terms-and-conditions"}
                                                        onClick={() => { signupModal(false, "/about/terms-and-conditions") }}
                                                    // data-bs-dismiss="modal"
                                                    >
                                                        Terms & Conditions{" "}
                                                    </Link>
                                                    and{" "}
                                                    <Link
                                                        className="giveaway-text-green"
                                                        // to={"/about/privacy-policy"}
                                                        onClick={() => { signupModal(false, "/about/privacy-policy") }}

                                                    // data-bs-dismiss="modal"
                                                    >
                                                        Privacy Policy
                                                    </Link>
                                                </p>
                                                <p className="mt-3 isn-fs-13 giveaway-text-green">
                                                    Already have an account? &nbsp;
                                                    <Link onClick={handleLoginShow} className="giveaway-text-green">
                                                        Login
                                                    </Link>
                                                </p>
                                                {/* <p className="mt-3 isn-fs-13">
                                            Already have an account? &nbsp;
                                            <Link to="/login" className="pgDirectLink">
                                                Login
                                            </Link>
                                        </p> */}
                                            </div>
                                        </form>
                                    </div>
                            }

                            <div className={paymentPage}>
                                {stripeKey && clientSecret && (
                                    <Payment
                                        entryText={entryText}
                                        premiumText={premiumText}
                                        packageVal={packageVal}
                                        stripeKey={stripeKey}
                                        clientSec={clientSecret}
                                        selectedPackage={selectedPackage}
                                        priceId={priceId} />
                                )}
                            </div>
                        </div>


                    </div>
                    {/* <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Understood</button>
                    </div> */}
                </div>
            </div>
        </div>








        <div class="modal" id="signupSuccessModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content giveaway-bg-blue-wt" style={{ borderRadius: "0 0 90px 0" }}>
                    <div class="modal-header border-0">
                        {/* <img src="/login/log.png" alt="iSaveNow Logo" className="m-auto" style={{ width: "35%" }} /> */}
                        {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                        {(discountGiveaway) ?
                            <button type="button" class="btn-close end-0 me-3 mt-3 position-absolute text-bg-light"
                                onClick={(discountGiveaway) ? showWarning : null} aria-label="Close"></button>
                            :
                            <button type="button" class="btn-close end-0 me-3 mt-3 position-absolute text-bg-light"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        }
                    </div>
                    <div class="modal-body text-white">
                        {
                            (discountGiveaway) ?
                                <div>
                                    <h2 className="text-white">{discountGiveaway.page_title}</h2>
                                    <p className="text-white">Add an <span className="fw-bold dg-md-fs">additional {discountGiveaway.no_of_entries} {discountGiveaway.entry_text} </span> and {discountGiveaway.premium_access}.</p>
                                    <p className="text-white fw-bold dg-md-fs">For only : ${discountGiveaway.price}</p>
                                    <div className="d-flex justify-content-center">
                                        <button className={`btn giveaway-btn-entry-buy-${discountGiveaway.product_name.toLowerCase()} rounded-5 isn-btn-premium-primary giveaway-entry-btn fw-semibold  mb-3`}

                                            onClick={() => clickBuy(discountGiveaway)}
                                        >
                                            {discountGiveaway.button.text}
                                        </button>
                                    </div>
                                </div>
                                :
                                <div></div>

                        }

                        {

                            (!isPaymentforReguler) ? <div>
                                <h2 className="text-white">Payment Successful!</h2>
                            </div> :
                                (client) ?
                                    (userPreviousStatus === "active") ?
                                        (searchParams.get("redirect_status") === "succeeded") ?
                                            <div>
                                                {
                                                    (displayRegulerTitle) ?
                                                        <h2 className="text-white">Payment Successful!</h2> : <div><p className="text-white">Payment Successful!</p></div>
                                                }
                                            </div>
                                            :
                                            <div>
                                                <h3 className="text-white">Transaction failed!</h3>
                                                <p className="text-white">Please try again in a couple of minutes and if the issue persists, please contact <a className="text-white-50" href="mailto:support@isavenow.com.au">support@isavenow.com.au</a></p>
                                            </div>
                                        :
                                        (searchParams.get("redirect_status") === "succeeded") ?
                                            <div>
                                                {(displayRegulerTitle) ? <h3 className="text-white">Congratulations!</h3> : <div></div>}
                                                <p className="text-white">You have successfully upgraded to iSaveNow Premium!</p>
                                            </div>
                                            :
                                            <div>
                                                <h3 className="text-white">Transaction failed!</h3>
                                                <p className="text-white">Please try again in a couple of minutes and if the issue persists, please contact <a className="text-white-50" href="mailto:support@isavenow.com.au">support@isavenow.com.au</a></p>
                                            </div>
                                    : (searchParams.get("redirect_status") === "succeeded") ?
                                        <div>
                                            {(displayRegulerTitle) ? <h3 className="text-white">Congratulations!</h3> : <div></div>}
                                            <p className="text-white">We have sent you an email with your unique username and password.
                                                <br />Don't see it? Be sure to check your junk folder!</p>
                                        </div>
                                        :
                                        <div>
                                            <h3 className="text-white">Transaction failed!</h3>
                                            <p className="text-white">Please try again in a couple of minutes and if the issue persists, please contact <a className="text-white-50" href="mailto:support@isavenow.com.au">support@isavenow.com.au</a></p>
                                        </div>

                        }


                    </div>
                    {/* <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Understood</button>
                    </div> */}


                </div>
            </div>
        </div>


        <div class="modal" id="offerwarningModal" data-bs-backdrop="true" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabelWarning" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content giveaway-bg-blue-wt" style={{ borderRadius: "0 0 90px 0" }}>
                    <div class="modal-header border-0">
                        <h1 class="modal-title fs-5" id="staticBackdropLabelWarning">Are you sure?</h1>
                        <button type="button" class="btn-close end-0 me-3 mt-3 position-absolute text-bg-light"
                            onClick={hideWarningModal} aria-label="Close"></button>
                    </div>
                    <div class="modal-body text-white">
                        <p>This is a one-time offer and will not be available once this window is closed</p>
                    </div>
                    <div class="modal-footer" style={{ justifyContent: "center" }}>
                        <button type="button" class="pe-4 ps-4 btn rounded-5 mb-3 border-0 giveaway-entry-btn fw-semibold isn-btn-premium-primary"
                            onClick={hideWarnignandOfferModal}>Yes</button>
                        <button type="button"
                            class="pe-4 ps-4 btn rounded-5 isn-btn-premium-primary giveaway-entry-btn fw-semibold mb-3 border-0"
                            onClick={hideWarningModal}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>


        <LoginModal
            show={isLoginOpen}
            handleClose={handleLoginClose}
            handleShow={handleLoginShow}
        />


        <Alert
            show={alert.show}
            type={alert.type}
            message={alert.message}
            onClose={clearAlert}
        />
        <Loader loader={loader} />
    </div>
}