import { isnofferwallapi } from "../config/axios/axiosconfig";


const addFavourites = async (euid, offerid) => {

    const res = await isnofferwallapi.post("/api/favourite-offers", {
        euid: euid,
        offerIds: [offerid]
    });
    return res.data   
}


const deleteFavourites = async (euid, offerid) => {

    const res = await isnofferwallapi.delete("/api/favourite-offers", {
        params: {
            euid: euid,
            offerIds: [offerid]
        }
    });
    return res.data   
}

const getFavourites = async (euid) => {
// alert("A")
    const res = await isnofferwallapi.get("/api/favourite-offers", {
        params: {
            euid
        },
       
    });
    return res.data   
}

export {getFavourites, addFavourites, deleteFavourites}