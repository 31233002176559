import { React, useContext } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "./navigationBar.css"; // Make sure you have the CSS file in the same directory
import { Link } from "react-router-dom";

import { ClientContext } from "../../../context/client/ClientContext";

const NavigationBar = () => {
  let isLoggedIn = false;
  
  const { client } = useContext(ClientContext);

  if (client) {
    isLoggedIn = true;
  }

  return (
    <div className="bg-light ">
      <div className="container">
        <nav className="bg-light ms-auto nav-bar-nav navbar navbar-expand-lg navbar-light p-0 w-auto">
          <div className="dropdown has-megamenu nav-bar navbar-nav overflow-auto isn-hide-scroll">
            <div className="nav-item nav-link scrollable-links p-0">
              <div style={{ marginLeft: "120px" }}></div>
             
              <Link className="nav-link" to="/giveaways">
              iSaveNow Premium
              </Link>
              <Link className="nav-link" to="/about/how-it-works">
                How It Works
              </Link>
              <Link className="nav-link" to="/offers/Featured Brands">
                Featured Brands
              </Link>
              <Link className="nav-link" to="/offers/Hot Deals">
                Hot Deals
              </Link>
              <Link className="nav-link" to="/brands">
                Popular
              </Link>
              {/* <Link className="nav-link" to="/offers/International">
								International
							</Link> */}
             
              {/* { <Link className="nav-link" to="/giftcards">
                Gift Cards
              </Link> } */}
              {isLoggedIn ? (
                <a
                  href="https://www.unitedpetroleum.com.au/fuel-discount-cards/our-partners/isavenow/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link gtm-external-click"
                >
                  Fuel Discount Card
                </a>
              ) : (
                <Link className="nav-link" to="/login">
                  Fuel Discount Card
                </Link>
              )}

              {/* <Link className="nav-link" to="/fuel/fuelcard">
							    Fuel Discount Card
							</Link> */}
              

            </div>
            <li
              className="dropdown-toggle nav-item nav-link position-absolute pt-2 bg-light "
              to="#"
              id="fixed-categories"
              data-bs-toggle="dropdown"
            >
              Categories{" "} <i class="bi bi-caret-down-fill" style={{
                verticalAlign: "middle",
                display: "inline-block",
                height: "17px"
              }}></i>
              {/* <img
                src="/Category.png"
                alt="Categories"
                style={{ width: "16px", height: "16px" }}
              /> */}
            </li>
            <div
              className="dropdown-menu megamenu overflow-auto"
              role="menu"
              style={{ maxHeight: "60vh" }}
            >
              <div className="row">
                <div className="col-md-3">
                  <Link
                    className="dropdown-item"
                    to="/categories/Beauty, Personal Care & Cosmetics"
                  >
                    <div className="">
                      <div className="cat-img cat-img c-cat-img-1" />
                      <span className="c-cat-name" id="cat-name ">
                      Beauty, Personal Care & Cosmetics
                      </span>
                    </div>
                  </Link>
                 

                  <Link
                    className="dropdown-item"
                    to="/categories/Books, Flowers & Gifts"
                  >
                    <div className="">
                      <div className="cat-img c-cat-img-2" />
                      <span className="c-cat-name" id="cat-name ">
                        Books, Flowers & Gifts
                      </span>
                    </div>
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/categories/Department Stores"
                  >
                    <div className="">
                      <div className="cat-img c-cat-img-3" />
                      <span className="c-cat-name" id="cat-name ">
                        Department Stores
                      </span>
                    </div>
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/categories/Electronics & Accessories"
                  >
                    <div className="">
                      <div className="cat-img c-cat-img-4" />
                      <span className="c-cat-name" id="cat-name ">
                        Electronics & Accessories
                      </span>
                    </div>
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/categories/Entertainment"
                  >
                    <div className="">
                      <div className="cat-img c-cat-img-5" />
                      <span className="c-cat-name" id="cat-name ">
                        Entertainment
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="col-md-3">
                  <Link
                    className="dropdown-item"
                    to="/categories/Clothing & Accessories"
                  >
                    <div className="">
                      <div className="cat-img c-cat-img-6" />
                      <span className="c-cat-name" id="cat-name ">
                        Clothing & Accessories
                      </span>
                    </div>
                  </Link>
                  <Link className="dropdown-item" to="/categories/Shoes">
                    <div className="">
                      <div className="cat-img c-cat-img-7" />
                      <span className="c-cat-name" id="cat-name ">
                        Shoes
                      </span>
                    </div>
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/categories/Health and Wellness"
                  >
                    <div className="">
                      <div className="cat-img c-cat-img-8" />
                      <span className="c-cat-name" id="cat-name ">
                        Health and Wellness
                      </span>
                    </div>
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/categories/Hobbies & Games"
                  >
                    <div className="">
                      <div className="cat-img c-cat-img-9" />
                      <span className="c-cat-name" id="cat-name ">
                        Hobbies & Games
                      </span>
                    </div>
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/categories/Home, Living & Garden"
                  >
                    <div className="">
                      <div className="cat-img c-cat-img-10" />
                      <span className="c-cat-name" id="cat-name ">
                        Home, Living & Garden
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="col-md-3">
                  <Link
                    className="dropdown-item"
                    to="/categories/Jewellery & Watches"
                  >
                    <div className="">
                      <div className="cat-img c-cat-img-11" />
                      <span className="c-cat-name" id="cat-name ">
                        Jewellery & Watches
                      </span>
                    </div>
                  </Link>
                  <Link className="dropdown-item" to="/categories/Marketplace">
                    <div className="">
                      <div className="cat-img c-cat-img-12" />
                      <span className="c-cat-name" id="cat-name ">
                        Marketplace
                      </span>
                    </div>
                  </Link>
                  <Link className="dropdown-item" to="/categories/Others">
                    <div className="">
                      <div className="cat-img c-cat-img-13" />
                      <span className="c-cat-name" id="cat-name ">
                        Others
                      </span>
                    </div>
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/categories/Kids, Babies & Toys"
                  >
                    <div className="">
                      <div className="cat-img c-cat-img-14" />
                      <span className="c-cat-name" id="cat-name ">
                        Kids, Babies & Toys
                      </span>
                    </div>
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/categories/Food & Drinks"
                  >
                    <div className="">
                      <div className="cat-img c-cat-img-15" />
                      <span className="c-cat-name" id="cat-name ">
                        Food & Drinks
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="col-md-3">
                  <Link className="dropdown-item" to="/categories/Adult">
                    <div className="">
                      <div className="cat-img c-cat-img-16" />
                      <span className="c-cat-name" id="cat-name ">
                        Adult
                      </span>
                    </div>
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/categories/Sports and Fitness"
                  >
                    <div className="">
                      <div className="cat-img c-cat-img-17" />
                      <span className="c-cat-name" id="cat-name ">
                        Sports and Fitness
                      </span>
                    </div>
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/categories/Groceries & Pets"
                  >
                    <div className="">
                      <div className="cat-img c-cat-img-18" />
                      <span className="c-cat-name" id="cat-name ">
                        Groceries & Pets
                      </span>
                    </div>
                  </Link>
                  <Link className="dropdown-item" to="/categories/Travel">
                    <div className="">
                      <div className="cat-img c-cat-img-19" />
                      <span className="c-cat-name" id="cat-name ">
                        Travel
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default NavigationBar;
