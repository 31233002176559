import React from "react";
import WinAndShareCompetition from "./winAndShare";
import GroceryCompetition from "./grocery";
import GiveawaysCompetition from "./giveaways"

export default function ISaveMoreFAQ() {
  return (
    <div className="container my-5">
      <div className="accordion" id="">
        {/* <div className="accordion-item">
          <h2 className="accordion-header" id="subHeadingThree">
            <button
              className="accordion-button  collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#subCollapseThree"
              aria-expanded="false"
              aria-controls="subCollapseThree"
            >
              <span className="mainFaqHeading">
              iSaveNow VISA Grocery Card Giveaway
              </span>
            </button>
          </h2>
          <div
            id="subCollapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="subHeadingThree"
            data-bs-parent="collapse"
          >
            <div className="accordion-body">
              <GroceryCompetition />
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="subHeadingFour">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#subCollapseFour"
              aria-expanded="false"
              aria-controls="subCollapseFour"
            >
              <span className="mainFaqHeading">
              iSaveNow Win a share of $2,750 Competition
              </span>
            </button>
          </h2>
          <div
            id="subCollapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="subHeadingFour"
            data-bs-parent="collapse"
          >
            <div className="accordion-body">
              <WinAndShareCompetition />
            </div>
          </div>
        </div> */}

        <div className="accordion-item">
          <h2 className="accordion-header" id="subHeadingFour">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#subCollapseFour"
              aria-expanded="false"
              aria-controls="subCollapseFour"
            >
              <span className="mainFaqHeading">
              iSaveNow Giveaways
              </span>
            </button>
          </h2>
          <div
            id="subCollapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="subHeadingFour"
            data-bs-parent="collapse"
          >
            <div className="accordion-body">
              <GiveawaysCompetition />
            </div>
          </div>
        </div>


      </div>
    </div>
  );
}
