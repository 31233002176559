import { useEffect, useState, useContext } from "react";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import "./rewardcardstat.css";
import PageHeader from "../../components/masterlayout/PageHeader";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import { ClientContext } from "../../context/client/ClientContext"

export default function RewardCardStat() {
    const location = useLocation()
    const { client, clientdispatch } = useContext(ClientContext);
    const { orderNumber, transactionId } = location.state;
    const [giftFail, setGiftFail] = useState(false);

    const [searchParams] = useSearchParams();

    const GetRewardValues = async () => {
        try {
          const res = await isnofferwallapi.get("/api/rewards/rewardvalues", {
            params: {
              customerId: client.euid,
            },
          });
         
          if (client && res.data) {
            const userSessionCookie = document.cookie
                .split("; ")
                .find((row) => row.startsWith("userSession"))
                ?.split("=")[1];

            const userSession = (userSessionCookie
                ? JSON.parse(decodeURIComponent(userSessionCookie))
                : null);

            const clientobj = client
            clientobj.rewardAmount = res.data.lifeTimeEarnings;
            clientdispatch({ type: "CLIENT_SUCCESS", payload: clientobj });

            userSession.rewardAmount = res.data.lifeTimeEarnings;
            document.cookie = `userSession=${encodeURIComponent(
                JSON.stringify(userSession)
            )}; path=/; Max-Age=10800; Secure`;

            
        } 
        } catch (error) {
          console.error("get reward :", error);
        }
      };


    useEffect(() => {
        GetRewardValues();
        if (searchParams.get("status") !== "success") {
            setGiftFail(true);
        }
    }, []);

    const breadcrumbs = [
        { label: "Home", path: "/" },
        { label: "Cashback Overview", path: "/user/cashback" }
        // Do not include the current page here
    ];


    return (
        <div>
            {
                (searchParams.get("status") === "success" && !giftFail) ?

                    <div>
                        <PageHeader title={"Withdrawal Status"} breadcrumbs={breadcrumbs} />
                        <div className="container my-5">
                            <div className="row justify-content-center">
                                <div className="card-style-box card shadow col-md-6">
                                    <div className="row g-0">
                                        <div className="text-center">
                                            <img src="/success.png" className="rounded" alt="..." />
                                        </div>
                                        <div style={{ marginTop: "2%" }} className="text-center">
                                            <h2 style={{ color: "#002848" }}>Congratulations, successful withdrawal!</h2>
                                            <p style={{ color: "#556068" }}>
                                                Your request has been processed successfully and you will receive your iSaveNow VISA via email shortly!
                                            </p>
                                        </div>
                                        <div style={{ marginTop: "3%" }} className="text-center">
                                            <p style={{ color: "#002848" }}>Order Number: {orderNumber}</p>
                                            <p style={{ color: "#002848" }}>
                                                Transaction ID: {transactionId}
                                            </p>
                                        </div>
                                        <div
                                            style={{ marginTop: "15%", marginBottom: "5%" }}
                                            className="text-center"
                                        >

                                            <Link className="col" to="/">
                                                <button style={{ backgroundColor: "#FB6E2C", width: "166px", height: "46px", border: "0px solid", }}
                                                    type="button" className="btn btn-outline-dark rounded-pill m2">
                                                    HOME
                                                </button>
                                            </Link>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    :
                    <div>
                        <PageHeader title={"Withdrawal Status"} breadcrumbs={breadcrumbs} />
                        <div className="container my-5">
                            <div className="row justify-content-center">
                                <div className="card-style-box card shadow col-md-6">
                                    <div className="row g-0">
                                        <div className="text-center">
                                            <img src="/fail.png" className="rounded" alt="..." />
                                        </div>
                                        <div style={{ marginTop: "2%" }} className="text-center">
                                            <h2 style={{ color: "#002848" }}>Transaction failed.</h2>
                                            <p style={{ color: "#556068" }}>
                                                {
                                                    <div>
                                                        Please try again in a couple of minutes and if the issue persists, please contact <a href='mailto:support@isavenow.com.au'>support@isavenow.com.au</a>.
                                                    </div>

                                                }

                                            </p>
                                        </div>
                                        {/* <div style={{ marginTop: "3%" }} className="text-center">
                                    <p style={{ color: "#002848" }}>{expDate}</p>

                                </div> */}
                                        <div
                                            style={{ marginTop: "15%", marginBottom: "5%" }}
                                            className="text-center"
                                        >

                                            <Link className="col" to="/">
                                                <button
                                                    style={{
                                                        backgroundColor: "#FB6E2C",
                                                        width: "166px",
                                                        height: "46px",
                                                        border: "0px solid",
                                                    }}
                                                    type="button"
                                                    className="btn btn-outline-dark rounded-pill m2"
                                                >
                                                    HOME
                                                </button>
                                            </Link>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}
