import React, { useState } from "react";
import PageHeader from "../../components/masterlayout/PageHeader";
import "./forgotPassword.css";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import Alert from "../../components/alert/Alert";
import useAlert from "../../components/alert/useAlert";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const breadcrumbs = [
    { label: "Home", path: "/" },
    // Do not include the current page here
  ];
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const {
    alert,
    showSuccessAlert,
    showErrorAlert,
    showWarningAlert,
    clearAlert,
  } = useAlert();

  const onSubmit = async (event) => {
    event.preventDefault();
    
    try {
      const result = await isnofferwallapi.post("/api/login/forgot_password", {
        username: email,
      });

      if (result.data.code === "200") {
        
        showSuccessAlert("Verification code have been sent to your email.");

        setTimeout(() => {
          navigate("/user/forgot_pasword_verification", { state: { email } });
        }, 1000);
      } else {
        
        showErrorAlert("Email could not be verified. Please try again.");
      }
    } catch (error) {
      console.error("Error  : ", error);
      showErrorAlert("Email could not be verified. Please try again.");
    }
  };

  return (
    <>
      <div>
        <PageHeader title={"Forgot Password"} breadcrumbs={breadcrumbs} />
      </div>
      <div className="container">
        <div className="card mx-auto rounded-3 shadow">
          <div className="card-body">
            <h2 className="card-title text-left mb-4 isn-fs-24 px-3">
              Forgot your password?
            </h2>
            <p className="text-left text-muted isn-fs-14 px-3">
              Enter your email below to receive your password reset instructions
            </p>
            <form onSubmit={onSubmit}>
              <div className="row mb-3 p-3">
                <div className="col-md-3 ">
                  <input
                    type="email"
                    className="form-control isn-fs-13"
                    id="txtEmail"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="px-3">
                <button
                  type="submit"
                  className="btn btn-primary btn-fp w-10 px-4 border-0 rounded-pill"
                >
                  SEND
                </button>
              </div>
            </form>
          </div>
          <Alert
            show={alert.show}
            type={alert.type}
            message={alert.message}
            onClose={clearAlert}
          />
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
