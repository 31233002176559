import PageHeader from "../../components/masterlayout/PageHeader";
import { useEffect,useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ClientContext } from "../../context/client/ClientContext";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import Common from "./common";
import Loader from "../../components/skeleton/Loader";
import ISaveMoreModal from "../../components/masterlayout/ISaveMoreModal";
import SubCancleModal from "./subscriptionCancleModal";
import Alert from "../../components/alert/Alert";
import useAlert from "../../components/alert/useAlert";
import { format } from 'date-fns';

import "./profile.css";

export default function Account() {
    const navigate = useNavigate();
    const { client,clientdispatch } = useContext(ClientContext);
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () => {
        setIsOpen(false);
        document.cookie = `request-path=; path=/; Max-Age=-1; Secure`;
    }
    const handleShow = () => {
        setIsOpen(true);
        document.cookie = `request-path=/user/account; path=/; Max-Age=3600; Secure`;
    }

    const showLoader = () =>{
        setLoader(true)
    }

    const hideLoader = () =>{
        setLoader(false)
    }
    const [isOpenCancle, setIsOpenCancle] = useState(false);
    const handleCancleClose = () => {
        setIsOpenCancle(false);
    }
    const handleCancleShow = () => {
        setIsOpenCancle(true);
    }

    const {
        alert,
        showSuccessAlert,
        showErrorAlert,
        showWarningAlert,
        clearAlert,
      } = useAlert();
const showSuccess = (message) =>{
    showSuccessAlert(message)
}
const showError = (message) =>{
    showErrorAlert(message)
}
      


    
    const [firstName, setFirstName] = useState(client?.firstname || "");
    const [lastName, setLastName] = useState(client?.lastname || "");
    const [email, setEmail] = useState(client?.email || "");
    const [euid, setEUID] = useState(client?.euid || "");
    const [dob, setDOB] = useState(client.dob ? format(new Date(client.dob), 'dd MMMM yyyy') : "");
    const [phoneNumber, setPhoneNumber] = useState(client?.phone_number || "");

    const [profileVisibility, setProfileVisibility] = useState("hide-edit");
    const [emaiVerifyVisibility, setEmaiVerifyVisibility] = useState("show-edit");
    const [passwordVisibility, setpasswordVisibility] = useState("hide-edit");
    const [passwordViewVisibility, setpasswordViewVisibility] = useState("show-edit");
    const [profilePointerEvn, setProfilePointerEvn] = useState("disabled");
    const [profileEdit, setprofileEdit] = useState("form-control-plaintext");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loader, setLoader] = useState(false);
    const [currentPasswordError, setCurrentPasswordError] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    if (!client) {
        navigate("/login");
    }

    const breadcrumbs = [
		{ label: "Home", path: "/" },
		{ label: "Account Information", path: "/user/account" }, // Do not include the current page here
	];

    const validateName = (name, type) => {
        if (!name) return `${type} name is required.`;
        if (name.length < 2 || name.length > 30)
          return `${type} name should be between 2 and 30 characters.`;
        if (!/^[A-Za-z]+$/.test(name))
          return `${type}  name should only contain letters.`;
        return "";
      };
    
      const validatePassword = (password) => {
       
        if (!password) return "Password is required.";
        if (password.length < 8){
          return "Password should be at least 8 characters long.";
        }
        if (
          !/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/.test(password)
        ) {
           
          return "Password should contain at least one uppercase letter, one lowercase letter, one number, and one special character.";
        }
        return "";
      };

      const validateCurrentPassword = (password) => {
       
        if (!password) return "Password is required.";
        return "";
      };
    
      const validateConfirmPasswords = (confirmPassword) => {
        if (!confirmPassword) return "Please confirm your password.";
        if (newPassword !== confirmPassword) {
          return "Passwords do not match.";
        }
      };
    
      const hasValidationErrors = () => {
        return (
          firstNameError ||
          lastNameError
        );
      };

      const hasPasswordValidationErrors = () => {
        return (
            currentPasswordError||
            newPasswordError ||
            confirmPasswordError
        );

      }

    const MakeEditProfile = () => {
        if (profileVisibility === "hide-edit"){
            setProfileVisibility("show-edit");
            setEmaiVerifyVisibility("hide-edit");
            setProfilePointerEvn("");
            setprofileEdit("form-control");
        }else{
            setProfileVisibility("hide-edit");
            setProfilePointerEvn("disabled");
            setEmaiVerifyVisibility("show-edit");
            setprofileEdit("form-control-plaintext")
            setFirstName(client?.firstname);
            setLastName(client?.lastname);
            setFirstNameError("");
            setLastNameError("");
        }
    }

    const MakeEditPassword = () => {
        if (passwordVisibility === "hide-edit"){
            setpasswordVisibility("show-edit");
            setpasswordViewVisibility("hide-edit");
            setCurrentPassword("");
            setConfirmPassword("");
            setNewPassword("");
            setNewPasswordError("");
            setCurrentPasswordError("");
            setConfirmPasswordError("");
          
        }else{
            setpasswordVisibility("hide-edit");
            setpasswordViewVisibility("show-edit");
            
        }
    }

    const handleProfileSubmit = async (event) => {
        setFirstNameError(validateName(firstName, "First"));
        setLastNameError(validateName(lastName, "Last"));
        event.preventDefault();
            
        if (!hasValidationErrors()) {
            setLoader(true)
            const params = {
                firstName,
                lastName,
                euid
            };
            try {
            
                const result = await isnofferwallapi.put(
                "/api/profile",
                params,{
                    headers: {
                        "access-token": client.idToken
                    }
                }
                );
        
                if (result.data.code === "200") {
            
                
                const userSessionCookie = document.cookie
                    .split("; ")
                    .find((row) => row.startsWith("userSession"))
                    ?.split("=")[1];
        
                if (userSessionCookie) {
                    const userSessionData = JSON.parse(
                    decodeURIComponent(userSessionCookie)
                    );
                    userSessionData.firstname = firstName;
                    userSessionData.lastname = lastName;
        
                    document.cookie = `userSession=${encodeURIComponent(
                    JSON.stringify(userSessionData)
                    )}; path=/; Max-Age=10800; Secure`;
                }
                    const clientobj = client
                    clientobj.firstname = firstName;
                    clientobj.lastname = lastName;
                    clientdispatch({ type: "CLIENT_SUCCESS", payload: clientobj });
                    setProfileVisibility("hide-edit");
                    setProfilePointerEvn("disabled");
                    setEmaiVerifyVisibility("show-edit");
                    setprofileEdit("form-control-plaintext")
                    setLoader(false)
                    showSuccessAlert("Your profile saved successfully ");
                } else {
                    setLoader(false)
                    showErrorAlert("Error occurred while updating profile!");
                  
                }
        
                
            } catch (error) {
                console.error("Profile update failed", error.response.data.payload.message);
                setLoader(false)
                showErrorAlert( error.response.data.payload.message);
            }

        }

    };

    const handlePasswordSubmit = async (event) => {
            
        

        setNewPasswordError(validatePassword(newPassword));
        setCurrentPasswordError(validateCurrentPassword(currentPassword));
        setConfirmPasswordError(validateConfirmPasswords(confirmPassword));
        event.preventDefault();
        const params = {
            password:currentPassword,
            newPassword:newPassword
        };
        
        if(!hasPasswordValidationErrors()){
            setLoader(true)
            try {
            
                const result = await isnofferwallapi.put(
                "/api/profile/password",
                params,{
                    headers: {
                        "access-token": client.idToken
                    }
                }
                );
        
                if (result.data.code === "200") {
                   
                    setpasswordVisibility("hide-edit");
                    setpasswordViewVisibility("show-edit")
                    setLoader(false)
                    showSuccessAlert("Your password successfully updated.");
                } else {
                    setLoader(false)
                   
                    showErrorAlert("Error occurred while updating password!");
                }
        
                
            } catch (error) {
                    console.error("Profile password update failed", error.response.data.payload.message);
                    setLoader(false)
                    showErrorAlert(error.response.data.payload.message);
            }
        }

    };

    useEffect (() => {
        if (client) {
            setFirstName(client.firstname);
            setLastName(client.lastname);
            setEmail(client.email);
            setEUID(client.euid);
        }
        
    }, [client,navigate]);

    return (
        <div className="isn-page-bg pb-5">
			<div>
				<PageHeader title="Account Information" breadcrumbs={breadcrumbs} />
			</div>
            <div className="container">
				<div className="row">
                    <div className="col-md-3">
					    <Common  module={"profile"} />
                    </div>
                    <div className="col-md-9">
                        <div className="isn-bradius bg-white p-4 mt-4">
                            <h5 className="mb-4">Account Information</h5>
                            <form class="row g-3 needs-validation" onSubmit={handleProfileSubmit}>
                                <div class="d-flex">
                                    <div class="p-2 w-100 account-section-heading">My Profile</div>
                                    <div class="p-2 flex-shrink-1  bi-pencil edit-circle" onClick={MakeEditProfile}></div>
                                </div>
                                <div class="form-group">
                                    <label for="accountFname" className="account-form-lable p-2">First Name</label>
                                
                                    <input type="text"  class={`account-form-text  p-2 ${profileEdit} ${firstNameError ? "is-invalid" : ""}`} id="firstName" 
                                    value={firstName} 
                                    onChange={event => setFirstName(event.target.value)} 
                                    onBlur={() => setFirstNameError(validateName(firstName, "First")) }
                                    disabled={profilePointerEvn} required></input>
                                    <div className="invalid-feedback isn-fs-10  p-2">
                                        {firstNameError}
                                    </div>   
                                    
                                </div>
                                <div class="form-group">
                                    <label for="accountLname" className="account-form-lable  p-2">Last Name</label>
                                    <input type="text" readonly class={`account-form-text  p-2 ${profileEdit} ${lastNameError ? "is-invalid" : ""}`} id="lastName" value={lastName} 
                                    onChange={event => setLastName(event.target.value)} 
                                    onBlur={() => setLastNameError(validateName(lastName, "Last")) }
                                    disabled={profilePointerEvn}></input>
                                    <div className="invalid-feedback isn-fs-10  p-2">
                                        {lastNameError}
                                    </div>
                                </div>
                                {/* <div class="form-group">
                                    <label for="accountDOB" className="account-form-lable  p-2">Date of Birth</label>
                                    <input type="text" readonly class={`account-form-text  p-2 ${profileEdit}`} id="dob" value={dob} 
                                    disabled="disabled"></input>
                                </div> */}
                                <div class="form-group">
                                    <label for="accountPhoneNumber" className="account-form-lable  p-2">Phone number</label>
                                    <input type="text" readonly class={`account-form-text  p-2 ${profileEdit}`} id="dob" value={phoneNumber} 
                                    disabled="disabled"></input>
                                </div>
                                <div class="form-group">
                                    <label for="accountEmail" className="account-form-lable p-2">Email address</label>
                                    <div className="d-flex account-email">
                                        <label  readonly class={`d-flex account-form-text overflow-hidden p-2 ${profileEdit}`}>{email}
                                        <span id="boot-icon" className={`bi bi-check-circle-fill ${emaiVerifyVisibility}`}  style={{fontSize :"22px", color:"#28D294",marginLeft:"10px"}}></span> 
                                        </label>
                                        
                                    </div>
                                </div>
                                <div className={profileVisibility} style={{marginTop:"25px"}}>
                                    <button type="submit" class="border-0 rounded-pill btn-profile-save">SAVE</button>
                                    <button type="button" class="border-0 rounded-pill btn-profile-cancle" style={{marginLeft:"15px"}}  onClick={MakeEditProfile}>CANCEL</button>
                                </div>
                            </form>
                            <hr class="hr" />
                            <div class="d-flex">
                                <div class="p-2 w-100 account-section-heading">Change Password</div>
                                <div class="p-2 flex-shrink-1  bi-pencil edit-circle" onClick={MakeEditPassword}></div>
                            </div>
                            
                            <div className={passwordViewVisibility}>
                                <div class="form-group">
                                    <label for="accountPassword" className="account-form-lable p-2">Password</label>
                                    <input type="password" readonly class="form-control-plaintext account-form-text p-2" id="password" value="12345678"></input>
                                </div>
                            </div>

                            <div className={passwordVisibility}>
                            <form class="row g-3 needs-validation" onSubmit={handlePasswordSubmit}>
                                <div class="form-group">
                                    <input type="password" readonly class={`form-control account-form-text p-2 ${currentPasswordError ? "is-invalid" : ""}`} id="currentPassword" 
                                        placeholder="Current Password" 
                                        value={currentPassword} 
                                        onChange={event => setCurrentPassword(event.target.value)} 
                                        onBlur={() =>
                                            setCurrentPasswordError(validateCurrentPassword(currentPassword))
                                        }
                                        style={{marginTop:"10px"}}></input>
                                    <div className="invalid-feedback isn-fs-10  p-2">
                                        {currentPasswordError}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="password" readonly class={`form-control account-form-text p-2 ${newPasswordError ? "is-invalid" : ""}`} id="newPassword" 
                                        placeholder="New Password" 
                                        value={newPassword} 
                                        onChange={event => {
                                            setNewPassword(event.target.value);
                                        }}
                                        onBlur={() =>
                                            setNewPasswordError(validatePassword(newPassword))
                                        }
                                        style={{marginTop:"25px"}}></input>
                                        <div className="invalid-feedback isn-fs-10  p-2">
                                            {newPasswordError}
                                        </div>
                                </div>
                                <div class="form-group">
                                    <input type="password" readonly class={`form-control account-form-text p-2 ${confirmPasswordError ? "is-invalid" : ""}`} id="confirmPassword" placeholder="Confirm Password" 
                                    value={confirmPassword} 
                                    onChange={event => setConfirmPassword(event.target.value)}
                                    onBlur={() =>
                                        setConfirmPasswordError(
                                          validateConfirmPasswords(confirmPassword)
                                        )
                                      }
                                    style={{marginTop:"25px"}}></input>
                                    <div className="invalid-feedback isn-fs-10  p-2">
                                        {confirmPasswordError}
                                    </div>
                                </div>

                                <div class="form-group" style={{marginTop:"25px"}}>
                                    <button type="submit" class="border-0 rounded-pill btn-passwword-save" >Change Password</button>
                                </div>
                            </form>

                            </div>

                            <hr class="hr" />
                            <div class="d-flex">
                                <div class="p-2 w-100 account-section-heading">Subscription</div>
                            </div>
                            {client?.subscription_status === 'active' ? (
                            <div class="form-group">
                                <label for="accountPassword" className="account-form-lable p-2">Premium Subscription</label>
                                {/* <div className="account-form-text p-2">$19.99 Per/mo</div> */}
                                <div className="account-form-text p-2">Expire on: {client.subscription_expiry_date ? 
                                format(new Date(client?.subscription_expiry_date), 'dd MMMM yyyy'):""}</div>
                                <a href="#" className="acount-cancle-sub isn-text-normal p-2" onClick={handleCancleShow}>Cancel Subscription</a>
                            </div>) :(
                            <div>
                                <label className="account-form-text p-2" style={{minWidth:"35%"}}><a href="/giveaways" className="acount-cancle-sub isn-text-normal gtm-upgrade-isaveMore-account" >Upgrade</a> to iSaveNow Premium for more savings.</label>
                            </div>)
                            }
                       </div>
                    </div>
                </div>
            </div>
            <ISaveMoreModal
                show={isOpen}
                handleClose={handleClose}
                handleShow={handleShow}
            />

            <SubCancleModal
                show={isOpenCancle}
                handleClose={handleCancleClose}
                handleShow={handleCancleShow}
                showLoader={showLoader}
                hideLoader={hideLoader}
                showSuccess={showSuccess}
                showError={showError}

            />
            <Loader loader={loader} />
            <Alert
                show={alert.show}
                type={alert.type}
                message={alert.message}
                onClose={clearAlert}
                showSuccessAlert={showSuccessAlert}
                showErrorAlert={showErrorAlert}
            />
        </div>
    )

}