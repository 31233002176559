import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useState } from 'react'

import "./payment.css";

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const { priceId } = props
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/giveaways?price=`+priceId,
        payment_method_data: { billing_details: { address: { country: "AU" } } }
      },
    });
    if (result.error.type === "card_error" || result.error.type === "validation_error" || result.error.type === "invalid_request_error") {
      setMessage(result.error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement options={{ terms: { card: 'never' }, fields: { billingDetails: { address: { country: 'never' } } } }} />
      {message && <div className="payment-message">{message}</div>}
      {stripe && elements && (
        <div class="text-center"> <button className='border-0 rounded-pill btn-payment-confirm' disabled={!stripe}>Confirm</button></div>
      )}

    </form>

  )
};

export default CheckoutForm;