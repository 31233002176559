import React, { useEffect, useState, useContext, useRef } from "react";
import "./fuelcard.css";
import PageHeader from "../../components/masterlayout/PageHeader";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import QRCode from "qrcode";
import { Link } from "react-router-dom";
import { ClientContext } from "../../context/client/ClientContext";
import { useNavigate } from "react-router-dom";
import Alert from "../../components/alert/Alert";
import useAlert from "../../components/alert/useAlert";
import Loader from "../../components/skeleton/Loader";

const FuelCard = () => {
  const navigate = useNavigate();
  const {
    alert,
    showSuccessAlert,
    showErrorAlert,
    showWarningAlert,
    clearAlert,
  } = useAlert();
  const { client, clientdispatch } = useContext(ClientContext);

  const canvasRef = useRef(null);

  const [firstName, setFirstName] = useState(client?.firstname || "");
  const [lastName, setLastName] = useState(client?.lastname || "");
  const [email, setEmail] = useState(client?.email || "");
  const [mobileNo, setMobileNo] = useState("");
  const [postCode, setPostCode] = useState("");
  const [euid, setEUID] = useState(client?.euid || "");
  const [isPostCodeValid, setIsPostCodeValid] = useState(true);
  const [isMobileNoValid, setIsMobileNoValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [fuelCardNo, setfuelCardNo] = useState(client?.fuel_card_number || "");
  const [loader, setLoader] = useState(false);

  const resetForm = () => {
    setPostCode("");
    setMobileNo("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const params = {
      firstName,
      lastName,
      email,
      mobileNo,
      postCode,
      userId: euid,
    };

    try {
      setLoader(true);
      const result = await isnofferwallapi.post(
        "/api/user/fuel_card_sub",
        params
      );

      if (result.data.code === "200") {
        setLoader(false);
        showSuccessAlert("You are now entitled to 4c off a litre at participating United Fuel Service Stations");

        const clientObj = client;
        clientObj.fuel_card_number = result.data.payload.cardNumber;
        clientdispatch({ type: "CLIENT_SUCCESS", payload: clientObj });
        setfuelCardNo(result.data.payload.cardNumber);

        // Update fuel card number in cookie
        const userSessionCookie = document.cookie
          .split("; ")
          .find((row) => row.startsWith("userSession"))
          ?.split("=")[1];

        if (userSessionCookie) {
          const userSessionData = JSON.parse(
            decodeURIComponent(userSessionCookie)
          );
          userSessionData.fuel_card_number = result.data.payload.cardNumber;

          document.cookie = `userSession=${encodeURIComponent(
            JSON.stringify(userSessionData)
          )}; path=/; Max-Age=10800; Secure`; // Add other cookie attributes as needed
        }
      } else {
        setLoader(false);
        showErrorAlert("Unable to retrieve digital fuel card. Please try again!");
      }

      resetForm();
    } catch (error) {
      setLoader(false);
      console.error("fuel discount error", error.response);
      showErrorAlert("Something went wrong , Please try again");
    }
  };

  const breadcrumbs = [
    { label: "Home", path: "/" },
    // Do not include the current page here
  ];

  const validatePostCode = (postcode) => {
    const regex = /^\d{4}$/;
    return regex.test(postcode);
  };

  const validateMobileNo = (mobileNo) => {
    const regex = /^\d{10}$/;
    return regex.test(mobileNo.replace(/\s+/g, "")); // Remove spaces before testing
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateNotEmpty = (value) => {
    return value.trim() !== "";
  };

  useEffect(() => {
    if (client) {
      setFirstName(client.firstname);
      setLastName(client.lastname);
      setEmail(client.email);
      setEUID(client.euid);
      setfuelCardNo(client.fuel_card_number);
    } else {
      navigate("/login");
    }
  }, [client]);

  useEffect(() => {
    if (fuelCardNo && canvasRef.current) {
      QRCode.toCanvas(
        canvasRef.current,
        fuelCardNo, // Update this to the data you want in the QR code
        {
          width: 220, // Set the width of the QR code
          height: 220, // Set the height of the QR code
        },
        function (error) {
          if (error) console.error(error);
        }
      );
    }
  }, [fuelCardNo]);
  return (
    <>
      <div>
        <PageHeader title={"Fuel Discount Card"} breadcrumbs={breadcrumbs} />
      </div>
      {client && fuelCardNo !== "" ? (
        <div className="pb-5" style={{ backgroundColor: "#0088DB" }}>
          <div className="container" >
            <div className="row justify-content-center">
              <div className="col-12 col-md-6">
                <div
                  style={{ backgroundColor: "#0079C2" }}
                  className="rounded-top overflow-hidden"
                >
                  <img
                    src="/osfc-united-logo.png"
                    alt="Fuel Discount"
                    className="img-fluid"
                  />
                </div>
                <div style={{ backgroundColor: "#0079C2" }}>
                  <img
                    src="/FuelBannerImg.png"
                    alt="Fuel Discount"
                    className="w-100"
                  />
                </div>
              </div>
            </div>

            <div className="row justify-content-center mt-5">
              <div className="col-12 col-md-4 mb-5">
                <div className="row">
                  <div className="col-6 text-center">
                    <p className="isn-fs-13 isn-fuel-text-blue fw-bold m-0 font-bold">
                      Name
                    </p>
                    <p className="isn-fs-13 text-white">
                      {client.firstname} {client.lastname}
                    </p>
                  </div>
                  <div className="col-6 text-center">
                    <p className="isn-fs-13 isn-fuel-text-blue fw-bold m-0 font-bold">
                      Association
                    </p>
                    <p className="isn-fs-13 text-white">iSaveNow</p>
                  </div>
                </div>
                <div
                  className="bg-white text-center p-3 mx-auto"
                  style={{ width: "75%" }}
                >
                  {/* <canvas id="canvas"></canvas> */}
                  <canvas ref={canvasRef}></canvas>
                  <div className="font-bold isn-fs-18">{fuelCardNo}</div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-3 text-center text-white">
            <div className="col-md-11  isn-fs-13">
              Show this QR code to save 4c per litre. 
              <div>Locate participating
              United service stations{" "}
              <a href="https://servicestations.unitedpetroleum.com.au/?fuelCards=acceptsUPDiscountFuelCards" target="_blank" rel="noopener noreferrer" className="text-white fw-bolder">here.</a></div>
              <div>Cannot be used at 24hr Ufill self serve locations.</div> By using this
              Fuel Disocunt Card, you agree that <div>you have read and accept
              United’s&nbsp;  <a href="https://www.unitedpetroleum.com.au/terms-and-conditions/" target="_blank" rel="noopener noreferrer" className="text-white fw-bolder">Terms and Conditions.</a>
              </div>
              
            </div>
          </div>
          </div>
         
        </div>
      ) : (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <img
                src="/Fuel.png"
                alt="Fuel Discount"
                className="w-100 img-fuelcard"
              />
              <div className="card shadow card-content p-4">
                <div className="card-body">
                  <div className="d-flex align-items-center mb-3">
                    <img
                      src="/Fuel.png"
                      alt="Fuel Discount"
                      className="rounded-circle circle-img"
                      style={{ width: "44px", height: "44px" }}
                    />
                    <h3 className="mb-0 card-logo-header ms-3 isn-fs-16">
                      United Petroleum
                    </h3>
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className={`isn-fs-13 form-control ${
                        isFirstNameValid ? "" : "is-invalid"
                      }`}
                      placeholder="First name"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        setIsFirstNameValid(validateNotEmpty(e.target.value));
                      }}
                    />
                    <div className="invalid-feedback">
                      First name is required.
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className={`isn-fs-13 form-control ${
                        isLastNameValid ? "" : "is-invalid"
                      }`}
                      placeholder="Last name"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        setIsLastNameValid(validateNotEmpty(e.target.value));
                      }}
                    />
                    <div className="invalid-feedback">
                      Last name is required.
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="email"
                      className={`isn-fs-13 form-control ${
                        isEmailValid ? "" : "is-invalid"
                      }`}
                      placeholder="Email address"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setIsEmailValid(validateEmail(e.target.value));
                      }}
                    />
                    <div className="invalid-feedback">
                      Please enter a valid email address.
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="tel"
                      className={`isn-fs-13 form-control ${
                        isMobileNoValid ? "" : "is-invalid"
                      }`}
                      placeholder="Mobile number"
                      value={mobileNo}
                      onChange={(e) => {
                        setMobileNo(e.target.value);
                        setIsMobileNoValid(validateMobileNo(e.target.value));
                      }}
                    />
                    <div className="invalid-feedback">
                      Please enter a valid phone number.
                    </div>
                  </div>
                  <div className="isn-fs-13 form-group mb-3">
                    <input
                      type="text"
                      className={`isn-fs-13 form-control ${
                        isPostCodeValid ? "" : "is-invalid"
                      }`}
                      placeholder="Postcode"
                      value={postCode}
                      onChange={(e) => {
                        setPostCode(e.target.value);
                        setIsPostCodeValid(validatePostCode(e.target.value));
                      }}
                    />
                    <div className="invalid-feedback">
                      Please enter a valid postcode.
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <button
                      onClick={handleSubmit}
                      className="btn btn-block btn-fuel-discount ps-5 pe-5 isn-fs-14"
                      disabled={
                        !isFirstNameValid ||
                        !isLastNameValid ||
                        !isEmailValid ||
                        !isPostCodeValid ||
                        !isMobileNoValid
                      }
                    >
                      GET MY FUEL DISCOUNT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Alert
        show={alert.show}
        type={alert.type}
        message={alert.message}
        onClose={clearAlert}
      />
       <Loader loader={loader} />
    </>
  );
};
export default FuelCard;
