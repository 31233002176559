import React from "react";

export default function DeleteAccountFAQ() {
	return (
		<div className="container my-5">
			<div className="accordion" id="">
				<div className="accordion-item">
					<h2 className="accordion-header" id="subHeadingOne">
						<button
							className="accordion-button  collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#subCollapseOne"
							aria-expanded="false"
							aria-controls="subCollapseOne"
						>
							<span className="mainFaqHeading">How do I delete my account</span>
						</button>
					</h2>
					<div
						id="subCollapseOne"
						className="accordion-collapse collapse"
						aria-labelledby="subHeadingOne"
						data-bs-parent="collapse"
					>
						<div className="accordion-body">
							<p>
							If you wish to delete your account, simply contact <a class="faq-delete-acc" href="mailto:support@isavenow.com.au">support@isavenow.com.au</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
