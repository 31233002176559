import React from "react";
import { CONTENT } from "./content";

export default function WinGiveaway() {
  return (
    <>
      <p className="mb-2"> 
 	
   The winner of the monthly giveaway will be notified via phone call and email. Additionally, winners may be contacted via phone on camera for marketing purposes and referred to only by first name and last initial.</p>
      <p>iSaveNow will never contact iSaveNow Premium major giveaway winners via social media direct message—should this occur, please report.</p>
    </>
  )
}
