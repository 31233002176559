import { useContext } from "react"
import BrandThumb from "../../components/thumbnails/BrandThumb"
import { OffersContext } from "../../context/offers/OffersContext"
import PageHeader from "../../components/masterlayout/PageHeader";



export default function Brands() {

    const { offers } = useContext(OffersContext);
    const breadcrumbs = [
        { label: "Home", path: "/" },
    ]


    return <div>
        <div>
            <PageHeader title={"Brands"} breadcrumbs={breadcrumbs} />
        </div>
        <div className="container">
            <div className="row">
                {
                    offers?.Hot?.map((offer, index) => {
                        return <BrandThumb offer={offer} key={index} parenttype={"page"} />
                    })
                }
            </div>
        </div>
    </div>
}