import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function GiveawayThumb(obj) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const [giveawaywidth, setGiveawayWidth] = useState("12.5%");

    const resizeWindow = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            //alert(obj.parenttype === "page")
            const width = (obj.parenttype === "page") ? "100%" : "33.33%"
            setGiveawayWidth(width);
        } else {
            setGiveawayWidth("33.33%");
        }
    }
    useEffect(() => {
        window.addEventListener('resize', resizeWindow)
        resizeWindow();
    }, []);

    return <div className="" style={{ width: giveawaywidth }}>
        <Link className="card border-0 nav-link" 
        to={`/giveaways/${months[new Date(obj?.offer.vTo).getMonth()]}/detail/${obj?.offer.id}`}>
            <div className="position-relative" style={{ borderRadius: "12px" }}>
                <div className="ratio ratio-1x1">
                    <img src={obj?.offer.img[0]}
                        className="card-img-top rounded-3" alt="..." />
                    {/* <div className="position-absolute start-0"
                        style={{ background: "linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(0,212,255,0) 100%)", borderRadius: "12px" }}></div> */}

                </div>
                {/* <div className="position-absolute top-0 end-0 text-white mt-2 me-2">
                    <i className="bi bi-heart "></i>
                    <i className="bi bi-heart-fill text-primary"></i>
                </div> */}
                {/* <div className="position-absolute bottom-0 start-0 translate-middle-y ms-2">
                    <img src={obj.offer.mImg} className="bg-white d-inline-block rounded-circle object-fit-cover"
                        style={{ width: "35px", height: "35px" }} />
                    <span className="text-white isn-thumb-merchant ms-2">{obj.offer.mName}</span>
                </div> */}
            </div>
            <div className="card-body ps-0 pe-0">
                <h5 className="card-title text-truncate isn-thumb-title">{obj?.offer.title}</h5>
                {/* <p className="card-text isn-thumb-desc text-truncate" dangerouslySetInnerHTML={{ __html: obj?.offer.desc }}
                    style={{ lineClamp: 2, boxOrient: "vertical" }}></p> */}
                {/* <span className="badge rounded-pill bg-primary isn-thumb-pill me-2">
                    {
                        (obj.offer.rwrdT === "PERCENTAGE") ?
                            `${obj.offer.rwrdV}% CASHBACK`
                            :
                            `${obj.offer.cur} ${obj.offer.rwrdV} CASHBACK`
                    }
                </span>
                {
                    (obj.offer.tgs[1]) ?
                        <span className="badge rounded-pill text-bg-success isn-thumb-pill text-dark">Exclusive</span>
                        : ""
                } */}
            </div>
        </Link>
    </div>
}