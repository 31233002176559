import React, { useEffect, useContext } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";


import { Route, Routes, useLocation } from "react-router-dom";

import ISNLayout from "./pages/layouts/master/master";
import Login from "./pages/auth/Login";
import SignupForm from "./pages/auth/SignUp";
import OTPForm from "./pages/auth/OTP";
import TermsAndConditions from "./pages/terms";
import FAQS from "./pages/faq/faqs";
// import Policy from "/terms/privacy-policy/policy";
import PrivacyPolicyISaveNow from "./pages/terms/privacy-policy/policy";
import HowItWorks from "./pages/howitworks/howItWorks";
// import * as bootstrap from 'bootstrap';
import { ClientContext } from "./context/client/ClientContext";
import { isnofferwallapi } from "./config/axios/axiosconfig";
import AboutUs from "./pages/aboutus/AboutUs";
import Offers from "./pages/offers/Offers";
import OfferDetail from "./pages/offers/OfferDetail";
import Modal from "bootstrap/js/dist/modal";

function App() {
  const { pathname } = useLocation();
  const { client, clientdispatch } = useContext(ClientContext);
  useEffect(() => {
    window.scrollTo(0, 0);
    var body = document.getElementsByTagName('body');
    const element = body[0].getElementsByClassName("modal-backdrop");

    // alert(element.length)
    // for (let i = 0, j = element.length; i < j; i++) {
    //   element[0].remove();
    // }

    // const page = document.getElementById("divPage");
    // const footer = document.getElementById("divFooter");

    // const footerHeight = footer.offsetHeight;
    // const offsetTop = footer.offsetTop;
    // const windowHeight = window.innerHeight;
    // if (offsetTop < windowHeight - footerHeight) {
    //   //alert("short")
    //   page.style.minHeight = (windowHeight - footerHeight - page.offsetTop-48) + "px"
    // } else {
    //  // alert("long")

    // }


  }, [pathname]);

  useEffect(() => {
    setTimeout(() => {
      var body = document.getElementsByTagName('body');
      var images = body[0].getElementsByTagName("img")
      for (const img of images) {
        if (img.parentElement.tagName === "BODY")
          img.remove();
      }
    }, 1000)



  }, [])

  let subscriptionEndTime = null
  if (client?.subscription_expiry_date) {
    subscriptionEndTime = new Date(client?.subscription_expiry_date + ' UTC').getTime();
  }

  useEffect(() => {
    // Calculate the time remaining until subscription end time
    if (subscriptionEndTime) {
      const currentTime = new Date(new Date().toUTCString()).getTime();
      const remainingTime = subscriptionEndTime - currentTime + 30000;
      if (remainingTime > -60000 && remainingTime < 10800000) {
        console.log("remaing time", remainingTime)
        // Schedule to check subscription renewal
        const timeoutId = setTimeout(() => {
          checkUserSubscription(client);

        }, remainingTime);
        // Cleanup function to clear the timeout when the component unmounts
        return () => clearTimeout(timeoutId);
      }
    }

  }, [pathname, subscriptionEndTime]);

  const checkUserSubscription = async (client) => {
    const preStatus = client.subscription_status;
    const PreExpDate = client.subscription_expiry_date;

    // Make API call to check subscription renewal
    const newValues = await updateUserSubscription(client.euid);
    //Check if subscription updated in backend
    if (preStatus === newValues.newStatus && PreExpDate === newValues.newExpDate) {
      const timeoutId = setTimeout(() => {
        // Make API call to check subscription renewal
        updateUserSubscription(client.euid);
      }, 300000);
      // Cleanup function to clear the timeout when the component unmounts
      return () => clearTimeout(timeoutId);
    }
  }

  const updateUserSubscription = async (euid) => {
    let newStatus = null;
    let newExpDate = null;
    try {
      const res = await isnofferwallapi.get("/api/user", {
        params: {
          euid: euid,
        },
      });
      if (res.data[0]) {
        const userSessionCookie = document.cookie
          .split("; ")
          .find((row) => row.startsWith("userSession"))
          ?.split("=")[1];

        const userSession = (userSessionCookie
          ? JSON.parse(decodeURIComponent(userSessionCookie))
          : null);

        if (userSession) {
          newExpDate = res.data[0].subscription_expiry_date;
          newStatus = res.data[0].subscription_status;
          userSession.subscription_status = res.data[0].subscription_status;
          userSession.subscription_id = res.data[0].subscription_id;
          userSession.subscription_expiry_date = res.data[0].subscription_expiry_date;
          setUserSessionCookie(userSession);

          const clientobj = client
          clientobj.subscription_status = res.data[0].subscription_status;
          clientobj.subscription_expiry_date = res.data[0].subscription_expiry_date;
          clientobj.subscription_id = res.data[0].subscription_id;
          clientdispatch({ type: "CLIENT_SUCCESS", payload: clientobj });

        }
      }
    } catch (error) {
      console.log("Error on Subscription checking", error);
    }
    return { newExpDate, newStatus };
  };

  const getUserSessionCookie = async () => {
    const userSessionCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("userSession"))
      ?.split("=")[1];

    if (userSessionCookie) {
      return JSON.parse(
        decodeURIComponent(userSessionCookie)
      );

    }

  }

  const setUserSessionCookie = async (userSessionData) => {
    document.cookie = `userSession=${encodeURIComponent(
      JSON.stringify(userSessionData)
    )}; path=/; Max-Age=10800; Secure`;
  }

  return (
    <>
      <Routes>
        <Route path={"*"} element={<ISNLayout />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignupForm />} />
        <Route path="/otp" element={<OTPForm />} />
        <Route path={"/mabout/terms-and-conditions"} element={<TermsAndConditions />} />
        <Route path={"/mabout/faqs"} element={<FAQS />} />
        <Route path={"/mabout/privacy-policy"} element={<PrivacyPolicyISaveNow />} />
        <Route path={"/mabout/how-it-works"} element={<HowItWorks />} />
        <Route path={"/mabout/aboutus"} element={<AboutUs />} />
        <Route path={"/mabout/about/aboutus"} element={<AboutUs />} />
        <Route path={"/mabout/about/aboutus/:previouswinners"} exact element={<Offers />} />
        <Route path={"/mabout/about/aboutus/:previouswinners/:aboutus"} element={<OfferDetail />} />
        <Route path={"/mabout/about/aboutus/:aboutus"} element={<OfferDetail />} />

      </Routes>
    </>
  );
}

export default App;
