import { CONTENT } from "./content";
import "../terms.css";

export default function PrivacyPolicyISaveNow() {
  return (
    <div className="container" style={{marginTop:"6%"}}>
      <div style={{textAlign:"center", marginBottom:"3%"}}>
      <h2 className="policyHeading">Privacy Policy iSaveNow</h2>
      </div>
      <p>iSaveNow ("we," "our," or "us") is committed to protecting your privacy. iSaveNow endeavours at all times to comply with the Privacy Act 1988 and the Australian Privacy Principles which together will be referred to in this policy as “Australian Privacy Law.” </p>
      <p>This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you visit our website and use our services. By accessing or using our website and services, you agree to the terms outlined in this Privacy Policy.</p>
      <p>1.	<strong className="font-bold">Information we collect</strong></p>
      <p>1.1. Personal Information </p>
      <p>We may collect personal information that you provide directly to us, including but not limited to:</p>
      <ul>
        {CONTENT.personal.map(item => (
          <li
            key={item.id}
            style={{
              display: 'list-item',
              listStylePosition: 'inside',
              listStyleType: 'disc'
            }}>
            {item.content}
          </li>
        ))}
      </ul>
      <p>1.2. Non-Personal Information</p>
      <p>We may also collect non-personal information automatically when you access our website or use our services. This may include:</p>
      <ul>
        {CONTENT.nonPersonal.map(item => (
          <li
            key={item.id}
            style={{
              display: 'list-item',
              listStylePosition: 'inside',
              listStyleType: 'disc'
            }}>
            {item.content}
          </li>
        ))}
      </ul>
      <p>2.	<strong className="font-bold">How we use your information</strong></p>
      <p>We may use your personal and non-personal information as follows:</p>
      <ul>
        {CONTENT.yourInfo.map(item => (
          <li
            key={item.id}
            style={{
              display: 'list-item',
              listStylePosition: 'inside',
              listStyleType: 'disc'
            }}>
            {item.content}
          </li>
        ))}
      </ul>
      <p>3.	<strong className="font-bold">Disclosure of your information</strong></p>
      <p>We may share your personal information with third parties under the following circumstances:</p>
      <ul>
        {CONTENT.disclosure.map(item => (
          <li
            key={item.id}
            style={{
              display: 'list-item',
              listStylePosition: 'inside',
              listStyleType: 'disc'
            }}>
            {item.content}
          </li>
        ))}
      </ul>
      <p>4.	<strong className="font-bold">Cookies and tracking technologies</strong></p>
      <p>We may use cookies and similar tracking technologies to collect information about your browsing behaviour on our website. You can control the use of cookies through your browser settings.</p>
      <p>5.	<strong className="font-bold">Third-party links</strong></p>
      <p>Our website may contain links to third-party websites or services. We are not responsible for the privacy practices of those third parties or content of their websites. We encourage you to review the privacy policies of these parties.</p>
      <p>6.	<strong className="font-bold">Security</strong></p>
      <p>We use our best endeavours to protect your personal information from unauthorised access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, so we cannot guarantee absolute security. We are not responsible for the security of any computer or other device which you use to access our website.</p>
      <p>7.	<strong className="font-bold">Retention of Information</strong></p>
      <p>We shall retain your personal and non-personal information for as long as: </p>
      <ul>
        {CONTENT.retention.map(item => (
          <li
            key={item.id}
            style={{
              display: 'list-item',
              listStylePosition: 'inside',
              listStyleType: 'disc'
            }}>
            {item.content}
          </li>
        ))}
      </ul>
      <p>and for no longer after which time it will be deleted from our systems.</p>
      <p>8.	<strong className="font-bold">Overseas recipients</strong></p>
      <p>We may supply your personal information to overseas recipients as part of our information technology systems. Those recipients are likely to be in the following countries: the United States of America, the European Union and the United Kingdom.</p>
      <p>9.	<strong className="font-bold">Your choices under Australian Privacy Law</strong></p>
      <p>You have the right to:</p>
      <ul>
        {CONTENT.choices.map(item => (
          <li
            key={item.id}
            style={{
              display: 'list-item',
              listStylePosition: 'inside',
              listStyleType: 'disc'
            }}>
            {item.content}
          </li>
        ))}
      </ul>
      <p>To exercise these rights or for any questions regarding your personal information, please contact us at support@isavenow.com.au.</p>
      <p>10.	<strong className="font-bold">Changes to this privacy policy</strong></p>
      <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with a revised "Last Updated" date. Your continued use of our website and services after any modifications indicate your acceptance of the updated Privacy Policy.</p>
      <p>11.	<strong className="font-bold">Contact Us</strong></p>
      <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at support@isavenow.com.au.</p>
      <p>Please read this Privacy Policy carefully and review it periodically for any updates or changes.</p>
      <p>12.	<strong className="font-bold">If European Union Privacy Law applies to you</strong></p>
      <p>If you are a resident of a country within the European Union, then the General Data Protection Regulation (“GDPR”) may apply to and protect your personal information or, as it is described in the GDPR, “personal data”. The provisions of the GDPR are very similar to those of Australian Privacy Law and to the extent that they are so similar, you are similarly protected by this Privacy Policy.</p>
      <p>However, in addition, but we shall also comply with the GDPR where possible in the following ways:</p>
      <ul>
        {CONTENT.europe.map(item => (
          <li
            key={item.id}
            style={{
              display: 'list-item',
              listStylePosition: 'inside',
              listStyleType: 'disc'
            }}>
            {item.content}
          </li>
        ))}
      </ul>
    </div>
  )
}
