import { createContext, useReducer } from "react"
import ClientReducer from "./ClientReducer";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const userSessionCookie = document.cookie
.split("; ")
.find((row) => row.startsWith("userSession"))
?.split("=")[1];


const userSession = userSessionCookie
? JSON.parse(decodeURIComponent(userSessionCookie))
: null;

const CLIENT_INITIAL_STATE = {
    client: userSession || null,
    clientIsFetching: false,
    clientError: false
}

export const ClientContext = createContext(CLIENT_INITIAL_STATE);

export const ClientContextProvider = ({ children }) => {
    const [clientstate, clientdispatch] = useReducer(ClientReducer, CLIENT_INITIAL_STATE);

    

    return <ClientContext.Provider value={{
        client: clientstate.client,
        clientIsFetching: clientstate.clientIsFetching,
        clientError: clientstate.clientError,
        clientdispatch
    }}>{children}</ClientContext.Provider>

}