import React from "react";
import { Accordion } from "react-bootstrap";

const CONTENT = [
  {
    id: "a2fdb6e2-0f62-5e7d-b0e8-e77c93e4f67e",
    title: "How do I refer a friend?",
    content: (
      <div>
        <p>
          Referring a friend is easy! Simply log in to your iSaveNow Premium account and click the “Refer a Friend” button at the top of your screen.
        </p>
        <p>
          Once you click this button, you will see the Refer a Friend conditions and a link which can be copied and sent to your friend. Once they purchase an iSaveNow Premium package or subscription, you'll both receive $15 in your iSaveNow wallet! Wahoo!
        </p>
      </div>
    ),
  },
  {
    id: "becf233a-8c51-5eb5-ba78-a1e57143a222",
    title: "How many friends can I invite?",
    content: (
      <div>
        <p>
          You can invite as many friends as you like – the more the merrier!
        </p>
      </div>
    ),
  },
];

export default function ReferFriend() {
  return (
    <div className="container my-5">
      <Accordion>
        {CONTENT.map((item, index) => (
          <Accordion.Item key={index} eventKey={index}>
            <Accordion.Header>
              <span className="mainFaqHeading">{item.title}</span>
            </Accordion.Header>
            <Accordion.Body>{item.content}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
}
