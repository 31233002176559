const FavouriteReducer = (state, action) => {
    switch (action.type) {
        case "FAVOURITES_START":
            return {
                favourites: null,
                favouritesIsFetching: true,
                favouritesError: false
            };
        case "FAVOURITES_SUCCESS":
            return {
                favourites: action.payload,
                favouritesIsFetching: false,
                favouritesError: false
            };
        case "FAVOURITES_FAIL":
            return {
                favourites: { favourites: [] },
                favouritesIsFetching: false,
                favouritesError: action.payload
            };
        case "FAVOURITES_CLEAR":
            return {
                favourites: { favourites: [] },
                favouritesIsFetching: false,
                favouritesError: false
            };

        default: return state;

    }
}
export default FavouriteReducer;