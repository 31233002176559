import { useContext } from "react"
import { OffersContext } from "../../context/offers/OffersContext"
import GiveawaySection from "../../components/sectionlayouts/GiveawaySection";
import Banner from "../../components/banners/Banner";
import ThreeOfferSection from "../../components/sectionlayouts/ThreeOfferSection";




export default function AboutUs() {

    const { offers } = useContext(OffersContext);
    const bannerItems = [{
        dImg: 'https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/DESKTOP-Banner-iSaveMORE-About-Us.png',
        mImg: 'https://isn-offerwall-prod-cdn.s3.ap-southeast-2.amazonaws.com/images/MOBILE-Banner-About-Us.png',
        // content: "<div style='color:#002848;'><h1 className='fw-bold'>Monthly giveaways with iSaveMore</h1></div>",
        // link: "/giveaways/December/detail/2554"
    }]

    return <div className="container">
        <div>
            <Banner type="FULL" items={bannerItems} bannerid="banneraboutus" />
        </div>
        <div className="aboutdefault">
            <ThreeOfferSection
                sectionlink=""
                sectiontype="About Us"
                showsectionheader={false}
                showcount={5}
                offers={offers?.["About"]?.toReversed()}
                color="#002848"
                isroundedcorner={true}
                layout="TITLE_MIDDLE"
                scrollable={false}
                parenttype="page"
            />
        </div>
        <div className="aboutwinners">
            <ThreeOfferSection
                sectionlink=""
                sectiontype="Previous Winners"
                showsectionheader={true}
                showcount={3}
                showtitle={false}
                offers={offers?.["ISN_Winners"]?.toReversed()}
                color="#002848"
                isroundedcorner={false}
                scrollable={true}
                parenttype="section"
            />
        </div>

        {/* <div className="aboutgiveaway mt-5">
            <GiveawaySection
                sectionlink=""
                sectiontype="Upcoming Giveaways"
                offers={offers?.["Giveaway"]?.toReversed()}
                color="#002848"
            />

        </div> */}


    </div>

}