import { useEffect, useState } from "react";
import FiveOfferThumb from "../thumbnails/FiveOfferThumb";
import { Link } from "react-router-dom";
import GiveawayThumb from "../thumbnails/GiveawayThumb";
import Skeleton from "../skeleton/Skeleton";


export default function GiveawaySection(obj) {
    const [giveawaywidth, setGiveawayWidth] = useState();

    const resizeWindow = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            const width = window.innerWidth;
            const itemwidth = width / 100 * 38;
            setGiveawayWidth(`${itemwidth * 3}px`);
        } else {
            setGiveawayWidth(`auto`);
        }
    }
    useEffect(() => {
        if (obj.offers) window.addEventListener('resize', resizeWindow)
        resizeWindow()
    }, [obj.offers]);

    return <div className="mb-3 mt-3">
        <div className="row mb-3">
            <h1 className="col isn-sub-title" style={{ color: obj.color }}>{obj?.sectiontype}</h1>
            <div className="col text-end align-middle">
                <Link style={{ textDecoration: "none", color: "black" }}
                    to={`/giveaways${obj?.sectionlink}`}>View all</Link>
                <i className="bi bi-chevron-right"></i>
            </div>
        </div>
        <div className="overflow-auto isn-hide-scroll">
            <div className="row" style={{ width: `${giveawaywidth}` }}>
                {
                    (obj.offers)?
                    obj.offers?.toReversed().map((offer, index) => {
                        if (index < 3)
                            return <GiveawayThumb thumblink={obj?.sectiontype} sectiontype={obj?.sectiontype} 
                            offer={offer} key={index} parenttype="section" />
                    })
                    : Array.from({ length: 3 }, (_, i) => <Skeleton key={i} type="giveaway" />)
                    
                }
            </div>
        </div>
    </div>
}