import React, { useState, useEffect } from "react";
import PageHeader from "../../components/masterlayout/PageHeader";
import "./forgotPassword.css";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import Alert from "../../components/alert/Alert";
import useAlert from "../../components/alert/useAlert";
import { useLocation, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const breadcrumbs = [
    { label: "Home", path: "/" },
    // Do not include the current page here
  ];

  const location = useLocation();
  const navigate = useNavigate();

  const email = location.state?.email || "";
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [confirmationCode, setconfirmationCode] = useState("");
  const [confirmationCodeError, setConfirmationCodeError] = useState("");

  const resetForm = () => {
    setPassword("");
    setConfirmPassword("");
    setconfirmationCode("");
  };

  const {
    alert,
    showSuccessAlert,
    showErrorAlert,
    showWarningAlert,
    clearAlert,
  } = useAlert();

  useEffect(() => {
    const error = checkPasswords();
    setConfirmPasswordError(error);
  }, [password, confirmPassword]);

  const onSubmit = async (event) => {
    event.preventDefault();
    // const isCodeValid = hasValidationErrors();
    if (!passwordError) {
      try {
        const params = {
          username: email,
          confirmationcode: confirmationCode,
          password: password,
        };

        const result = await isnofferwallapi.post(
          "/api/login/confirm_forgot_password",
          params
        );
        if (result.data.code === "200") {
          showSuccessAlert("Your password successfully updated");
          setTimeout(() => {
            navigate("/login");
          }, 1000);
          // navigate("/login", { state: { isFirstLogin: true } });
        } else {
          showErrorAlert("Your password not updated successfully");
        }
        resetForm();
      } catch (error) {
        console.error("Error in password change : ", error);
        showErrorAlert("Password has not been changed! Please try again!");
      }
    }
  };

  const checkPasswords = () => {
    if (password !== confirmPassword) {
      return "Passwords do not match";
    } else {
      return ""; // Clear error if passwords match
    }
  };

  const validatePassword = (password) => {
    if (!password) return "Password is required.";
    if (password.length < 8)
      return "Password should be at least 8 characters long.";
    if (
      !/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/.test(password)
    ) {
      return "Password should contain at least one uppercase letter, one lowercase letter, one number, and one special character.";
    }
    return "";
  };

  const validateConfirmationCode = () => {
    const regex = /^\d{6}$/;
    if (!regex.test(confirmationCode)) {
      return "Confirmation code must be 6 digits";
    }
    return "";
  };

  return (
    <>
      <div>
        <PageHeader
          title={"Forgot Password Verification"}
          breadcrumbs={breadcrumbs}
        />
      </div>
      <div className="container">
        <div className="card mx-auto rounded-3 shadow">
          <div className="card-body">
            <h2 className="card-title text-left mb-4 isn-fs-24 px-3">
              Set your new password
            </h2>
            <p className="text-left text-muted isn-fs-14 px-3">
              Your new password should be different from passwords previously
              used.
            </p>
            <form onSubmit={onSubmit}>
              <div className="row mb-3 ps-3">
                <div className="col-md-3">
                  <input
                    type={"password"}
                    className={`form-control isn-fs-13 ${
                      passwordError ? "is-invalid" : ""
                    }`}
                    id="password"
                    value={password}
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={() => setPasswordError(validatePassword(password))} // No direct state update
                    required
                  />
                  {passwordError && (
                    <div className="invalid-feedback isn-fs-10">
                      {passwordError}
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-3 ps-3">
                <div className="col-md-3">
                  <input
                    id="confirmPassword"
                    type="password"
                    className={`form-control isn-fs-13 ${
                      confirmPasswordError ? "is-invalid" : ""
                    }`}
                    placeholder="Confirm password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    onBlur={() => setConfirmPasswordError(checkPasswords())}
                    required
                  />
                  {confirmPasswordError && (
                    <div className="invalid-feedback isn-fs-10">
                      {confirmPasswordError}
                    </div>
                  )}
                </div>
              </div>
              <h2 className="text-left mb-4 isn-fs-24 px-3">
                Email Verification
              </h2>
              <p className="text-left text-muted isn-fs-14 px-3">
                Please check your inbox and enter the verification code below to
                verify your email address.
              </p>
              <div className="row mb-3 ps-3">
                <div className="col-md-3">
                  <input
                    type="text"
                    className={`form-control isn-fs-13 ${
                      confirmationCodeError ? "is-invalid" : ""
                    }`}
                    id="txtconfirmationCode"
                    placeholder="Enter 6 digits authentication code"
                    value={confirmationCode}
                    onChange={(e) => setconfirmationCode(e.target.value)}
                    onBlur={() =>
                      setConfirmationCodeError(validateConfirmationCode())
                    }
                    required
                  />
                  {confirmationCodeError && (
                    <div className="invalid-feedback isn-fs-10">
                      {confirmationCodeError}
                    </div>
                  )}
                </div>
              </div>
              <div className="px-3">
                <button
                  type="submit"
                  className="btn btn-primary btn-fp w-10 px-4 border-0 rounded-pill"
                >
                  CONFIRM
                </button>
              </div>
            </form>
          </div>
          <Alert
            show={alert.show}
            type={alert.type}
            message={alert.message}
            onClose={clearAlert}
          />
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
