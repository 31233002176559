import { useEffect } from "react";
import "./faqs.css";

import ISaveNowFAQ from "./i-save-now";
import ISaveMoreFAQ from "./i-save-more";
import RedeemingAndShopping from "./redeeming-and-shopping";
import CashBack from "./cashback";
import GiftCard from "./gift-card";
import ReferFriend from "./refer-a-friend";
import DeleteAccountFAQ from "./delete-account";



export default function FAQS() {
	const ValidateGiftCardTransaction = async () => {};
	useEffect(() => {
		ValidateGiftCardTransaction();
	}, []);
	return (
		<div className="container my-5">
			<div
				style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }}
				className="text-center"
			>
				<h2 style={{ color: "black" }}>Frequently Asked Questions</h2>
			</div>
			<div className="accordion" id="mainFaq">
				<div className="accordion-item">
					<h2 className="accordion-header" id="headingOne">
						<button
							className="accordion-button  collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseOne"
							aria-expanded="false"
							aria-controls="collapseOne"
						>
							<span className="mainFaqHeading">iSaveNow</span>
						</button>
					</h2>
					<div
						id="collapseOne"
						className="accordion-collapse collapse"
						aria-labelledby="headingOne"
						data-bs-parent="#accordionExample"
					>
						<div className="accordion-body">
							<ISaveNowFAQ />
						</div>
					</div>
				</div>
				<div className="accordion-item">
					<h2 className="accordion-header" id="headingTwo">
						<button
							className="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseTwo"
							aria-expanded="false"
							aria-controls="collapseTwo"
						>
							<span className="mainFaqHeading">iSaveNow Premium</span>
						</button>
					</h2>
					<div
						id="collapseTwo"
						className="accordion-collapse collapse"
						aria-labelledby="headingTwo"
						data-bs-parent="#accordionExample"
					>
						<div className="accordion-body">
							<ISaveMoreFAQ />
						</div>
					</div>
				</div>
				<div className="accordion-item">
					<h2 className="accordion-header" id="headingThree">
						<button
							className="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseThree"
							aria-expanded="false"
							aria-controls="collapseThree"
						>
							<span className="mainFaqHeading">Redeeming and Shopping</span>
						</button>
					</h2>
					<div
						id="collapseThree"
						className="accordion-collapse collapse"
						aria-labelledby="headingThree"
						data-bs-parent="#accordionExample"
					>
						<div className="accordion-body">
							<RedeemingAndShopping />
						</div>
					</div>
				</div>
				<div className="accordion-item">
					<h2 className="accordion-header" id="headingFour">
						<button
							className="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseFour"
							aria-expanded="false"
							aria-controls="collapseFour"
						>
							<span className="mainFaqHeading">Cashback</span>
						</button>
					</h2>
					<div
						id="collapseFour"
						className="accordion-collapse collapse"
						aria-labelledby="headingFour"
						data-bs-parent="#accordionExample"
					>
						<div className="accordion-body">
							<CashBack />
						</div>
					</div>
				</div>
				{/* <div className="accordion-item">
					<h2 className="accordion-header" id="headingFive">
						<button
							className="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseFive"
							aria-expanded="false"
							aria-controls="collapseFive"
						>
							<span className="mainFaqHeading">Gift Cards</span>
						</button>
					</h2>
					<div
						id="collapseFive"
						className="accordion-collapse collapse"
						aria-labelledby="headingFive"
						data-bs-parent="#accordionExample"
					>
						<div className="accordion-body">
							<GiftCard />
						</div>
					</div>
				</div> */}

				<div className="accordion-item">
					<h2 className="accordion-header" id="headingSix">
						<button
							className="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseSix"
							aria-expanded="false"
							aria-controls="collapseSix"
						>
							<span className="mainFaqHeading">Refer a Friend</span>
						</button>
					</h2>
					<div
						id="collapseSix"
						className="accordion-collapse collapse"
						aria-labelledby="headingSix"
						data-bs-parent="#accordionExample"
					>
						<div className="accordion-body">
							<ReferFriend />
						</div>
					</div>
				</div>

				<div className="accordion-item">
					<h2 className="accordion-header" id="headingSeven">
						<button
							className="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseSeven"
							aria-expanded="false"
							aria-controls="collapseSeven"
						>
							<span className="mainFaqHeading">Delete Account</span>
						</button>
					</h2>
					<div
						id="collapseSeven"
						className="accordion-collapse collapse"
						aria-labelledby="headingSeven"
						data-bs-parent="#accordionExample"
					>
						<div className="accordion-body">
							<DeleteAccountFAQ />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
