import React from "react";

export default function ISaveNowFAQ() {
	return (
		<div className="container my-5">
			<div className="accordion" id="">
				<div className="accordion-item">
					<h2 className="accordion-header" id="subHeadingOne">
						<button
							className="accordion-button  collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#subCollapseOne"
							aria-expanded="false"
							aria-controls="subCollapseOne"
						>
							<span className="mainFaqHeading">What is iSaveNow?</span>
						</button>
					</h2>
					<div
						id="subCollapseOne"
						className="accordion-collapse collapse"
						aria-labelledby="subHeadingOne"
						data-bs-parent="collapse"
					>
						<div className="accordion-body">
							<p>
							iSaveNow is Australia's all-in-one digital destination for savings, cashback, and MASSIVE giveaways. Shop 1,500+ brands across travel, fashion, lifestyle, and more, earn cashback on eligible purchases, and go in the draw for exclusive giveaway!
							</p>
						</div>
					</div>
				</div>
				<div className="accordion-item">
					<h2 className="accordion-header" id="subHeadingTwo">
						<button
							className="accordion-button  collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#subCollapseTwo"
							aria-expanded="false"
							aria-controls="subCollapseTwo"
						>
							<span className="mainFaqHeading">How does it work?</span>
						</button>
					</h2>
					<div
						id="subCollapseTwo"
						className="accordion-collapse collapse"
						aria-labelledby="subHeadingTwo"
						data-bs-parent="collapse"
					>
						<div className="accordion-body">
							<p>
							Sign up for a free account to shop your favourite brands and earn cashback. Want to be level up your experience? Access iSaveNow Premium for as little as a $10 one-time payment and earn double cashback on purchases PLUS free entry to our MAJOR cash giveaways!
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
