import { useContext } from "react";
import { Link } from "react-router-dom";
import { FavouritesContext } from "../../context/favourites/FavouritesContext";
import { OffersContext } from "../../context/offers/OffersContext";
import { addFavourites, deleteFavourites } from "../../utils/favourites";
import { ClientContext } from "../../context/client/ClientContext";
import { setFavourites } from "../../utils/offers";
import "./sixofferthumb.css";
import { TenantContext } from "../../context/tenant/TenantContext";
import DOMPurify from "dompurify";

export default function SixOfferThumb(obj) {

    const { favourites, favouritesdispatch } = useContext(FavouritesContext);
    const { offers, offersdispatch } = useContext(OffersContext);
    const { tenant } = useContext(TenantContext);

    const { client } = useContext(ClientContext);

    const updateFavourites = async (e, offerid) => {
        e.preventDefault();
        try {
            const favs = [...favourites];
            favouritesdispatch({ type: "FAVOURITES_START" });

            if (favs.indexOf(offerid) === -1) {
                const res = await addFavourites(client.euid, offerid);
                favs.push(offerid);
            } else {
                const res = await deleteFavourites(client.euid, offerid);
                favs.splice(favs.indexOf(offerid), 1);
            }

            favouritesdispatch({ type: "FAVOURITES_SUCCESS", payload: favs });

            const offersOrganized = setFavourites(offers.all, favs);
            offersdispatch({ type: "OFFERS_SUCCESS", payload: offersOrganized });

        } catch (error) {
            console.log("FAV UPDATE ERROR", error)
            favouritesdispatch({ type: "FAVOURITES_FAIL", payload: error });
        }


    }

    return <div className="" style={{ width: "16.6%" }}>
        <Link className="card border-0 nav-link" to={`/offers/${obj?.sectiontype}/detail/${obj.offer.id}`}>
            <div className="position-relative">
                <img src={obj.offer.img[0]}
                    className="card-img-top" alt="..." style={{ borderRadius: "12px" }} />

                <div className="position-absolute top-0 end-0 text-white mt-2 me-2" onClick={(e) => updateFavourites(e, obj?.offer.id)}>
                    {
                        (obj.offer.fav) ?
                            <i className="bi bi-heart-fill text-primary"></i>
                            : <i className="bi bi-heart "></i>
                    }
                </div>
                <div className="position-absolute bottom-0 start-0 translate-middle-y ms-2">
                    <img src={obj.offer.mImg} className="bg-white d-inline-block rounded-circle object-fit-cover"
                        style={{ width: "35px", height: "35px" }} />

                </div>
            </div>
            <div className="card-body ps-0 pe-0">
                <h5 className="card-title text-truncate isn-thumb-title">{obj.offer.mName}</h5>
                <p className="card-text isn-thumb-desc text-truncate" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(obj.offer.title) }}
                    style={{ lineClamp: 2, boxOrient: "vertical" }}></p>

                <span className="badge isn-fs-10 fw-normal me-2 ps-2 mb-2 rounded-pill text-dark">
                    Standard - {
                        (obj.offer.rwrdT === "PERCENTAGE") ?
                            `${(obj.offer.rwrdV * tenant?.tenantrewards.tier1 / 100).toFixed(1)}% Cashback`
                            :
                            `${obj.offer.cur} ${(obj.offer.rwrdV * tenant?.tenantrewards.tier1 / 100).toFixed(1)} Cashback`
                    }
                </span>
                <span className="badge rounded-pill isn-thumb-pill isn-thumb-pill-green me-2 p-2 fw-bold">
                    Premium - {
                        (obj.offer.rwrdT === "PERCENTAGE") ?
                            `${(obj.offer.rwrdV * tenant?.tenantrewards.tier2 / 100).toFixed(1)}% Cashback`
                            :
                            `${obj.offer.cur} ${(obj.offer.rwrdV * tenant?.tenantrewards.tier2 / 100).toFixed(1)} Cashback`
                    }
                </span>
                {/* {
                    (obj.offer.tgs[1]) ?
                        <span className="badge rounded-pill text-bg-success isn-thumb-pill isn-exclusive-pill">Exclusive</span>
                        : ""
                } */}
            </div>
        </Link>
    </div>
}