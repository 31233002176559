import React from "react";

export default function RedeemingAndShopping() {
  return (
    <div className="container my-5">
      <div className="accordion" id="">
        <div className="accordion-item">
          <h2 className="accordion-header" id="subHeadingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#subCollapseTwo"
              aria-expanded="false"
              aria-controls="subCollapseTwo"
            >
              <span className="mainFaqHeading">
                Can I redeem offers in-store?
              </span>
            </button>
          </h2>
          <div
            id="subCollapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="subHeadingTwo"
            data-bs-parent="collapse"
          >
            <div className="accordion-body">
              <p>
                Some offers can be redeemed in-store, others are online only.
                Any offer in the app that presents you with a QR Code or
                barcode, can be redeemed in store. Redemption details will be
                available on each specific offer.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="subHeadingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#subCollapseThree"
              aria-expanded="false"
              aria-controls="subCollapseThree"
            >
              <span className="mainFaqHeading">
                Do I need to present anything in-store at the time of purchase?
              </span>
            </button>
          </h2>
          <div
            id="subCollapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="subHeadingThree"
            data-bs-parent="collapse"
          >
            <div className="accordion-body">
              <p>
                If a particular deal or offer is only redeemable in-store, you
                will be required to show the store the Voucher, code, or coupon
                attached to that deal.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="subHeadingFour">
            <button
              className="accordion-button  collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#subCollapseFour"
              aria-expanded="false"
              aria-controls="subCollapseFour"
            >
              <span className="mainFaqHeading">
                A Redeem link isn't working.
              </span>
            </button>
          </h2>
          <div
            id="subCollapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="subHeadingFour"
            data-bs-parent="collapse"
          >
            <div className="accordion-body">
              <p>
                Try closing down and reopening the app. If the link is still not
                working - please reach out to our customer support team so we
                can investigate.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="subHeadingFive">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#subCollapseFive"
              aria-expanded="false"
              aria-controls="subCollapseFive"
            >
              <span className="mainFaqHeading">
                I haven't received the item that I bought through iSaveNow.
              </span>
            </button>
          </h2>
          <div
            id="subCollapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="subHeadingFive"
            data-bs-parent="collapse"
          >
            <div className="accordion-body">
              <p>
                If you have not received an item or product, you will need to
                contact the company with whom you purchased the item directly.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="subHeadingSeven">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#subCollapseSeven"
              aria-expanded="false"
              aria-controls="subCollapseSeven"
            >
              <span className="mainFaqHeading">
                Why has a store disappeared?
              </span>
            </button>
          </h2>
          <div
            id="subCollapseSeven"
            className="accordion-collapse collapse"
            aria-labelledby="subHeadingSeven"
            data-bs-parent="collapse"
          >
            <div className="accordion-body">
              <p>
                From time to time, we update the brands and businesses we work
                with to ensure customers get the most out of the iSaveNow app.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="subHeadingEight">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#subCollapseEight"
              aria-expanded="false"
              aria-controls="subCollapseEight"
            >
              <span className="mainFaqHeading">
                What is the wallet feature and how do I use it?
              </span>
            </button>
          </h2>
          <div
            id="subCollapseEight"
            className="accordion-collapse collapse"
            aria-labelledby="subHeadingEight"
            data-bs-parent="collapse"
          >
            <div className="accordion-body">
              <p>
                The wallet feature allows you to track your Cash Back history.
                It is here that you will be able to view all previous, pending,
                and confirmed transactions, the store it was from, and the
                amount paid!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
