
const setFavourites = (offers, favs) => {
    const offersOrganized = {};

    const sections = ["Featured", "Hot", "Personalised", "Giveaway", "About", "ISN_Winners"]
    for (let i = 0; i < offers.length; i++) {
        const offer = offers[i];


        offer["fav"] = (favs && favs.indexOf(offer.id) > -1) ? true : false


        offersOrganized["all"] = offersOrganized["all"] ?
            [...offersOrganized["all"], offer] : [offer];

        let count = 0
        for(const tag of offer.tgs){
            // console.log("tag :", count, tag, offer.id)
            // count++;

            if(sections.indexOf(tag.trim())>-1){
                offersOrganized[tag.trim()] = offersOrganized[tag.trim()] ?
                [...offersOrganized[tag.trim()], offer] : [offer];
            }
        }

        // offersOrganized[offer.tgs[0]?.trim()] = offersOrganized[offer.tgs[0]?.trim()] ?
        //     [...offersOrganized[offer.tgs[0]?.trim()], offer] : [offer];


    }
    // console.log("ORGANIZED OFFERS : ", offersOrganized)

    return offersOrganized;
}

export { setFavourites }