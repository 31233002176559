import { useEffect, useState } from "react";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import GiftCardThumb from "../../components/thumbnails/GiftCardThumb";
import PageHeader from "../../components/masterlayout/PageHeader";
import Loader from "../../components/skeleton/Loader";

export default function GiftCatalogue() {
  const [giftCards, setGiftCards] = useState();
  const [loader, setLoader] = useState(false);

  const GetGiftCards = async () => {
    setLoader(true);
    try {
      const res = await isnofferwallapi.get("/api/giftcards/giftcards");
      setGiftCards(res.data);
      setLoader(false);
    } catch (error) { setLoader(false); }
  };
  useEffect(() => {
    GetGiftCards();
  }, []);

  const breadcrumbs = [
    { label: "Home", path: "/" },
    // Do not include the current page here
  ];

  return (
    <>
      <PageHeader title={"Gift Cards"} breadcrumbs={breadcrumbs} />
      <div className="container">
        <div className="row">
          {giftCards?.map((giftCard, index) => {
            return <GiftCardThumb giftCard={giftCard} key={index} />;
          })}
        </div>
      </div>
      <Loader loader={loader} />
    </>
  );
}
