import { useEffect } from "react";
import "./howItWorks.css";
import Banner from "../../components/banners/Banner";
import { Link } from "react-router-dom";

export default function HowItWorks() {
	const ValidateGiftCardTransaction = async () => { };
	useEffect(() => {
		ValidateGiftCardTransaction();
	}, []);

	const bannerItemsRight = [{
		dImg: '/how_it_works/how-it-works.webp',
		mImg: '/how_it_works/how-it-works.webp',
		pill: "Exclusive",
		cashback: "15% Cashback",
		merchant: "Adidas",
		link: "/offers/search/detail/7578",
		title: "How it works",
		bgColor:"#D0E518",
		txtColor: "#212529",
		txtSize:"45px"
	}]

	return (
		<div className="container">
			{/* <div style={{ marginBottom: "3%", marginTop: "3%" }} className="row">
				<div style={{ paddingRight: "0px" }} className="col-md-8">
					<img
					className="col-12"
						style={{ borderRadius: "18px 0 0 18px", paddingRight: "0px" }}
						src="/how_it_works/how-it-works.webp"
						alt=".."
					></img>
				</div>
				<div className="col-md-4 how-it-works-title">
					<div style={{ paddingTop: "40%" }}>How it works</div>
				</div>
			</div> */}
			<div className="mt-5 mb-4">
				<Banner type="LEFT" contentType="TITLE" items={bannerItemsRight} bannerid="howitworks" />

			</div>
			<div>
				{/* <div className=""> */}
				<div className="row">
					{/* <div className="col-md-4 mb-3">
						<Link className="card border-0 nav-link" to={"/offers/search/detail/2539"}>
							<div className="position-relative">
								<div className="ratio ratio-1x1">
									<img
										src="/how_it_works/GiveAway.png"
										style={{ borderRadius: "15px 15px 50px 15px" }}
										alt="..."
									/>
									<div className="how-it-woks-overlay"></div>
								</div>
								<div className="position-absolute top-50 start-50 translate-middle">
									<h3 style={{ color: "#ffffff", textAlign: "center" }}>
										Giveaways
									</h3>
								</div> */}
								{/* <div className="position-absolute how-it-works-top-60 start-50 translate-middle">
									<button
										type="button"
										className="btn rounded-pill"
										style={{ backgroundColor: "red", color: "#0e2948" }}
									>
										How it works
									</button>
								</div> */}
							{/* </div>
						</Link>
					</div> */}
					<div className="col-md-4 mb-3">
						<Link className="card border-0 nav-link" to={"/offers/search/detail/2542"}>
							<div className="position-relative">
								<div className="ratio ratio-1x1">
									<img
										src="/how_it_works/iSaveMore.png"
										style={{ borderRadius: "15px 15px 50px 15px" }}
										alt="..."
									/>
									<div className="how-it-woks-overlay"></div>
								</div>
								<div className="position-absolute top-50 start-50 translate-middle">
									<h3 style={{ color: "#ffffff", textAlign: "center" }}>
										iSaveNow Premium
									</h3>
								</div>
								{/* <div className="position-absolute how-it-works-top-60 start-50 translate-middle">
									<button
										type="button"
										className="btn rounded-pill"
										style={{ backgroundColor: "red", color: "#0e2948" }}
									>
										How it works
									</button>
								</div> */}
							</div>
						</Link>
					</div>
					<div className="col-md-4 mb-3">
						<Link className="card border-0 nav-link" to={"/offers/search/detail/2540"}>
							<div className="position-relative">
								<div className="ratio ratio-1x1">
									<img
										src="/how_it_works/MyAccount.png"
										style={{ borderRadius: "15px 15px 50px 15px" }}
										alt="..."
									/>
									<div className="how-it-woks-overlay"></div>
								</div>
								<div className="position-absolute top-50 start-50 translate-middle">
									<h3 style={{ color: "#ffffff", textAlign: "center" }}>
										My account
									</h3>
								</div>
								{/* <div className="position-absolute how-it-works-top-60 start-50 translate-middle">
									<button
										type="button"
										className="btn rounded-pill"
										style={{ backgroundColor: "red", color: "#0e2948" }}
									>
										How it works
									</button>
								</div> */}
							</div>
						</Link>
					</div>
					{/**Below tile need to move next row is one tile is enable form this row */}
					<div className="col-md-4 mb-3">
						<Link className="card border-0 nav-link" to={"/offers/search/detail/2543"}>
							<div className="position-relative">
								<div className="ratio ratio-1x1">
									<img
										src="/how_it_works/Discounts.png"
										style={{ borderRadius: "15px 15px 50px 15px" }}
										alt="..."
									/>
									<div className="how-it-woks-overlay"></div>
								</div>
								<div className="position-absolute top-50 start-50 translate-middle">
									<h3 style={{ color: "#ffffff", textAlign: "center" }}>
										Discounts
									</h3>
								</div>
								{/* <div className="position-absolute how-it-works-top-60 start-50 translate-middle">
									<button
										type="button"
										className="btn rounded-pill"
										style={{ backgroundColor: "red", color: "#0e2948" }}
									>
										How it works
									</button>
								</div> */}
							</div>
						</Link>
					</div>
				</div>

				<div className="row">
					
					<div className="col-md-4 mb-3">
						<Link className="card border-0 nav-link" to={"/offers/search/detail/2544"}>
							<div className="position-relative">
								<div className="ratio ratio-1x1">
									<img
										src="/how_it_works/Cashback.png"
										style={{ borderRadius: "15px 15px 50px 15px" }}
										alt="..."
									/>
									<div className="how-it-woks-overlay"></div>
								</div>
								<div className="position-absolute top-50 start-50 translate-middle">
									<h3 style={{ color: "#ffffff", textAlign: "center" }}>
										Cashback
									</h3>
								</div>
								{/* <div className="position-absolute how-it-works-top-60 start-50 translate-middle">
									<button
										type="button"
										className="btn rounded-pill"
										style={{ backgroundColor: "red", color: "#0e2948" }}
									>
										How it works
									</button>
								</div> */}
							</div>
						</Link>
					</div>
					{/* <div className="col-md-4 mb-3">
						<Link className="card border-0 nav-link" to={"/offers/search/detail/2545"}>
							<div className="position-relative">
								<div className="ratio ratio-1x1">
									<img
										src="/how_it_works/GiftCards.png"
										style={{ borderRadius: "15px 15px 50px 15px" }}
										alt="..."
									/>
									<div className="how-it-woks-overlay"></div>
								</div>
								<div className="position-absolute top-50 start-50 translate-middle">
									<h3 style={{ color: "#ffffff", textAlign: "center" }}>
										Gift Cards
									</h3>
								</div> */}
								{/* <div className="position-absolute how-it-works-top-60 start-50 translate-middle">
									<button
										type="button"
										className="btn rounded-pill"
										style={{ backgroundColor: "red", color: "#0e2948" }}
									>
										How it works
									</button>
								</div> */}
							{/* </div>
						</Link>
					</div> */}

					<div className="col-md-4 mb-3">
						<Link className="card border-0 nav-link" to={"/offers/search/detail/2546"}>
							<div className="position-relative">
								<div className="ratio ratio-1x1">
									<img
										src="/how_it_works/General.png"
										style={{ borderRadius: "15px 15px 50px 15px" }}
										alt="..."
									/>
									<div className="how-it-woks-overlay"></div>
								</div>
								<div className="position-absolute top-50 start-50 translate-middle">
									<h3 style={{ color: "#ffffff", textAlign: "center" }}>
										General
									</h3>
								</div>
								{/* <div className="position-absolute how-it-works-top-60 start-50 translate-middle">
									<button
										type="button"
										className="btn rounded-pill"
										style={{ backgroundColor: "red", color: "#0e2948" }}
									>
										How it works
									</button>
								</div> */}
							</div>
						</Link>
					</div>

					<div className="col-md-4 mb-3">
						<Link className="card border-0 nav-link" to={"/offers/search/detail/2547"}>
							<div className="position-relative">
								<div className="ratio ratio-1x1">
									<img
										src="/how_it_works/United Fuel Discount.png"
										style={{ borderRadius: "15px 15px 50px 15px" }}
										alt="..."
									/>
									<div className="how-it-woks-overlay"></div>
								</div>
								<div className="position-absolute top-50 start-50 translate-middle">
									<h3 style={{ color: "#ffffff", textAlign: "center" }}>
										United Fuel Discount
									</h3>
								</div>
								{/* <div className="position-absolute how-it-works-top-60 start-50 translate-middle">
									<button
										type="button"
										className="btn rounded-pill"
										style={{ backgroundColor: "red", color: "#0e2948" }}
									>
										How it works
									</button>
								</div> */}
							</div>
						</Link>
					</div>
				</div>

				<div className="row">
					
					
					<div className="col-md-4 mb-3">
						<Link className="card border-0 nav-link" to={"/offers/search/detail/2548"}>
							<div className="position-relative">
								<div className="ratio ratio-1x1">
									<img
										src="/how_it_works/Refer A Friend.png"
										style={{ borderRadius: "15px 15px 50px 15px" }}
										alt="..."
									/>
									<div className="how-it-woks-overlay"></div>
								</div>
								<div className="position-absolute top-50 start-50 translate-middle">
									<h3 style={{ color: "#ffffff", textAlign: "center" }}>
										Refer a friend
									</h3>
								</div>
								{/* <div className="position-absolute how-it-works-top-60 start-50 translate-middle">
									<button
										type="button"
										className="btn rounded-pill"
										style={{ backgroundColor: "red", color: "#0e2948" }}
									>
										How it works
									</button>
								</div> */}
							</div>
						</Link>
					</div>
				</div>
				{/* </div> */}
			</div>
			{/* <div className="">
				<div className="how-it-works-email">
					Sign up to our email list & be the first to know when savings,
					cashback & giveaways drop.{" "}
				</div>
				<div style={{ marginTop: "2%" }} className="how-it-works-form">
					<form>
						<div className="mb-3">
							<label for="client-name" className="form-label">
								Name
							</label>
							<input
								type="email"
								className="form-control"
								id="client-name"
								aria-describedby="namelHelp"
							/>
						</div>
						<div className="mb-3">
							<label for="client-email" className="form-label">
								Email address
							</label>
							<input
								type="email"
								className="form-control"
								id="client-email"
								aria-describedby="emailHelp"
							/>
							<div id="emailHelp" className="form-text">
								We'll never share your email with anyone else.
							</div>
						</div>
						<div style={{ textAlign: "center" }}>
							<button type="submit" className="btn btn-primary how-it-works-email-submit">
								Submit
							</button>
						</div>
					</form>
				</div>
			</div> */}
		</div>
	);
}
