import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { OffersContextProvider } from './context/offers/OffersContext';
import { FavouritesContextProvider } from './context/favourites/FavouritesContext';
import { ClientContextProvider } from './context/client/ClientContext';
import { TenantContextProvider } from './context/tenant/TenantContext';


const root = ReactDOM.createRoot(document.getElementById('isnowroot'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <TenantContextProvider>
      <ClientContextProvider>
        <OffersContextProvider>
          <FavouritesContextProvider>
            <App />
          </FavouritesContextProvider>
        </OffersContextProvider>
      </ClientContextProvider>
    </TenantContextProvider>
  </BrowserRouter>
  // </React.StrictMode>
);


