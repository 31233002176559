import { useEffect, useState, useMemo, useContext } from "react";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import Common from "./common";
// import { useParams } from "react-router-dom";
// import { uuid } from "uuidv4";
import PageHeader from "../../components/masterlayout/PageHeader";
import "./profile.css";
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from "react-table";
// import FilterForm from "./filters";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
//import { useNavigate } from "react-router-dom";
import { ClientContext } from "../../context/client/ClientContext";

// const statuses = [
// 	{
// 		id: 1,
// 		name: "Pending",
// 	},
// 	{
// 		id: 2,
// 		name: "Confirm",
// 	},
// 	{
// 		id: 3,
// 		name: "Reject",
// 	},
// ];

export default function Cashback() {
	const breadcrumbs = [
		{ label: "Home", path: "/" },
		{ label: "Cashback Overview", path: "/user/cashback" }, // Do not include the current page here
	];
	const { client } = useContext(ClientContext);

	const [RewardsInfo, setRewardsInfo] = useState();
	const [getMerchantList, setMerchantList] = useState();
	//const [startDate, setStartDate] = useState();
	const [euid, setEUID] = useState(client?.euid || "");

	useEffect(() => {
		if (client) {
			setEUID(client.euid);
			getRewardInfo();
		}
	}, [client]);

	const getRewardInfo = async () => {
		try {
			const res = await isnofferwallapi.get("/api/rewards/rewardinfo", {
				params: {
					customerId: client.euid
				},
			});

			const rewards = res.data.rewards
			const merchantList = res.data.merchantList
			setRewardsInfo(rewards);
			setMerchantList(merchantList);
		} catch (error) { }
	};

	

	const data = useMemo(
		() => RewardsInfo ? RewardsInfo : [
			{
				txDateUtc: "N/A",
				merchantName: "N/A",
				txAmount: "N/A",
				rewardAmount: "N/A",
				rewardStatus: "N/A",
			},
		],
		[RewardsInfo]
	);

	const merchants = useMemo(
		() => getMerchantList ? getMerchantList : [
			{
				merchantId: 0,
				merchantName: "N/A",
			},
		],
		[getMerchantList]
	);

	const Dot = ({ color }) => (
		<span
			style={{
				height: "10px",
				width: "10px",
				backgroundColor: color,
				borderRadius: "50%",
				display: "inline-block",
				marginRight: "5px",
			}}
		/>
	);

	const columns = useMemo(
		() => [
			{
				Header: "Date",
				accessor: "txDateUtc",
				Filter: false,
			},
			{
				Header: "Merchant",
				accessor: "merchantName",
				//	Filter: FilterForm,
				Filter: false,
			},
			{
				Header: "Transaction Amount",
				accessor: "txAmount",
				Filter: false,
			},
			{
				Header: "Cashback Amount",
				accessor: "rewardAmount",
				Filter: false,
			},
			{
				Header: "Status",
				accessor: "rewardStatus",
				//Filter: FilterForm,
				Filter: false,
				Cell: ({ value }) => {
					// Determine the dot color based on the status value
					let color;
					switch (value) {
						case "Pending":
							color = "#FB6E2C";
							break;
						case "Confirmed":
							color = "#28D294";
							break;
						case "Rejected":
							color = "#FB2C2C";
							break;
						default:
							color = "gray";
					}
					// Return a JSX element that contains the dot and the status text
					return (
						<div>
							<Dot color={color} />
							{value}
						</div>
					);
				},
			},
		],
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		setGlobalFilter,
		prepareRow,
	} = useTable({ columns, data }, useFilters, useGlobalFilter, useSortBy, usePagination);

	const { globalFilter } = state;

	return (
		<div className="isn-page-bg pb-5">
			<div>
				<PageHeader title="Cashback Overview" breadcrumbs={breadcrumbs} />
			</div>
			<div className="container">
				<div className="row">
					<div className="col-md-3">
						<Common module={"cashback"} />
					</div>
					<div
						className="col isn-bradius bg-white p-4 mt-4"
						style={{ padding: "20px 10% 20px 10%" }}
					>
						<h5 className="mb-4">Cashback Overview</h5>
						<div className="">
							{/* <div style={{ display: "flex" }} className="col-md-6 mb-4">
							<DatePicker
								className="form-control border-end-0 border rounded-pill"
									selected={startDate}
									onChange={(date) => setStartDate(date)}
									placeholderText="Date"
								/>
								<span style={{fontSize: "25px", marginLeft: "-30px", position: "relative"}} className="input-group-append">
										<i className="bi bi-calendar-date"></i>
								</span>
							</div> */}

							<div style={{ display: "flex" }} className="col-md-6 mb-4">
								<input
									className="form-control border-end-0 border rounded-pill"
									type="search"
									value={globalFilter || ""}
									onChange={(e) => setGlobalFilter(e.target.value)}
									placeholder="Search"
									aria-label="Search"
								/>
								<span className="input-group-append">
									<button
										className="btn btn-outline-secondary bg-white border-bottom-0 border rounded-pill ms-n5"
										type="button"
									>
										<i className="bi bi-search"></i>
									</button>
								</span>
							</div>
						</div>
						<div className="table-responsive">
							<table
								style={{ width: "100%" }}
								className="table"
								{...getTableProps()}
							>
								<thead className="table-light">
									{headerGroups.map((headerGroup) => (
										<tr {...headerGroup.getHeaderGroupProps()}>
											{headerGroup.headers.map((column) => (
												<th
													className="table-header-style"
													{...column.getHeaderProps(
														column.getSortByToggleProps()
													)}
												>
													<div>
														{column.canFilter ? column.render("Filter") : null}
													</div>
													{column.render("Header")}
													<span>
														{column.isSorted
															? column.isSortedDesc
																? " 🔽"
																: " 🔼"
															: ""}
													</span>
												</th>
											))}
										</tr>
									))}
								</thead>
								<tbody className="table-body-style" {...getTableBodyProps()}>
									{page.map((row) => {
										prepareRow(row);
										return (
											<tr {...row.getRowProps()}>
												{row.cells.map((cell) => {
													return (
														<td {...cell.getCellProps()}>
															{cell.render("Cell")}
														</td>
													);
												})}
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
						<div
							className="row"
							style={{ position: "relative", float: "right" }}
						>
							<div className="col-6">
								<button
									type="button"
									className="btn btn-light"
									onClick={() => previousPage()}
								>
									Previous
								</button>
							</div>
							<div className="col-6">
								<button
									type="button"
									className="btn btn-light"
									onClick={() => nextPage()}
								>
									Next
								</button>
							</div>
						</div>
						<div className="row pt-5 container-fluid">Please note: Cashback information can take up to 14 days to appear and, depending on the type of transaction, up to 90 days to be disbursed if the cashback offer has been approved by the merchant. Cashback is disbursed to a digital pre-paid iSaveNow Visa card.</div>
					</div>
				</div>
			</div>
		</div>
	);
}
