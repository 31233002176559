import React from "react";
import { Accordion } from "react-bootstrap";

const CONTENT = [
  {
    id: "c7062e69-b047-5f2b-a079-7d517afdbf88",
    title: "How does it work?",
    content: (
      <div>
        <p>Receiving cash back is easier than you may think!</p>

        <ol>
          <li>Simply browse the brands and deals we have on offer.</li>
          <li>Select the one that you wish to redeem.</li>
          <li>Click “shop now” which will take you to the brand's website.</li>
          <li>Shop away!</li>
        </ol>

        <p>
          To ensure the cash back is properly tracked and redeemed, it is always
          best to try to ensure cookies are enabled and to complete the
          transaction in the one sitting. If you leave the website or open
          through other tabs, we may not be able to track the transaction and
          you may miss out on the cash back.
        </p>

        <p>
          We also recommend reviewing the brand's Terms and Conditions in case
          they have special requirements!
        </p>
      </div>
    ),
  },
  {
    id: "1178f24e-72f9-5a4c-a943-61fa75d95dac",
    title: "How do I know how much cash back I will get?",
    content: (
      <div>
        <p>
          The percentage of cash back you receive will vary deal to deal,
          however it can be difficult to track the dollar value which is owed,
          which is why we do the hard work for you!
        </p>
        <p>
          Once the purchase has been confirmed, simply go to the “wallet” in
          your profile. Here you will see any pending transactions, the store,
          and the amount due to be paid! it's important to note that
          transactions may not be immediately visible in the wallet. Sometimes
          it can take up to 14 days based on the brand
        </p>
      </div>
    ),
  },
  {
    id: "81cf0673-75ed-5bd6-ae37-5b316dc4bc8a",
    title: "Will I still earn cashback if I browse other sites first?",
    content: (
      <div>
        <p>
          To ensure cash back is received, we always recommend completing the
          transaction in one smooth process: from the iSaveNow website, to your
          selected brand, and then to checkout.
        </p>
        <p>
          You are of course welcome to browse other sites prior to redeeming a
          deal through iSaveNow. However, to ensure we can track the transaction
          to confirm your cash back, it is best not to change sites and browsers
          after selecting a deal from the iSaveNow website.
        </p>
      </div>
    ),
  },
  {
    id: "aed00848-0e22-5be4-86ba-bb507cbc0925",
    title:
      "How long do I have to complete my purchase after activating cashback?",
    content: (
      <div>
        <p>
          This time can vary and usually ranges up to 14 days. However, to
          ensure you don’t miss out on the cash back, we recommend activating
          cash back once you are ready to make a purchase.
        </p>
        <p>
          Don’t forget: if you see a deal you like, but aren’t ready to check
          out, you can always save it to your favourites to access later!
        </p>
      </div>
    ),
  },
  {
    id: "6ccfecdb-b080-5123-9ac1-7a814ba266a5",
    title: "Will I receive cashback if I use a coupon code?",
    content: (
      <p>
        This may vary brand to brand therefore it is best to check the specific
        brands Terms and Conditions!
      </p>
    ),
  },
  {
    id: "a6f60a87-63e1-558e-878c-a196c1f27243",
    title: "When will my cashback be approved?",
    content: (
      <p>
        Cashback can take up to 60 days to be approved and 90 days to be
        received.
      </p>
    ),
  },
  {
    id: "a7984e31-b398-5aa9-942e-3615e3d9d3af",
    title: "Why is my cashback pending?",
    content: (
      <p>
        Prior to cash back being approved, it will be in a “pending” state. Cash
        back is “pending” whilst the business you made the purchase from,
        verifies all transactions within the month and waits for the
        return/refund policy to lapse.
      </p>
    ),
  },
  {
    id: "03376800-e657-57ef-822e-10713e056cd4",
    title: "Why does the confirmation period vary from store to store?",
    content: (
      <p>
        Confirmation can vary from store-to-store depending on their return
        policy and how frequently they report.
      </p>
    ),
  },
];

export default function CashBack() {
  return (
    <div className="container my-5">
      <Accordion>
        {CONTENT.map((item, index) => (
          <Accordion.Item key={index} eventKey={index}>
            <Accordion.Header>
              <span className="mainFaqHeading">{item.title}</span>
            </Accordion.Header>
            <Accordion.Body>{item.content}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
}
