import React from "react";
import { CONTENT } from "./content";

export default function ClaimGiveaway() {
  return (<div>    
    <ul className='flex flex-col gap-3'>
    {CONTENT.claimGiveaway.map(item => (
      <li
        key={item.id}
        style={{
          display: 'list-item',
          listStylePosition: 'inside',
          listStyleType: 'circle'
        }}>
        {item.content}
      </li>
    ))}
  </ul></div>)

  
}
