import { useEffect } from "react";
import "./terms.css";
import MemberAndSubscriber from "./member-and-subscriber";
import TermsAndConditionsISN from "./terms-and-conditions-isn";
import PrivacyPolicyISaveNow from "./privacy-policy";
import TermsAndConditionsFuel from "./united-fuel";

export default function TermsAndConditions() {
	const ValidateGiftCardTransaction = async () => {};
	useEffect(() => {
		ValidateGiftCardTransaction();
	}, []);
	return (
		<div className="container my-5">
			<div
				style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }}
				className="text-center"
			>
				<h2 style={{ color: "black" }}>Terms and Conditions</h2>
			</div>
			<div className="accordion" id="">
				<div className="accordion-item">
					<h2 className="accordion-header" id="headingOne">
						<button
							className="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseOne"
							aria-expanded="false"
							aria-controls="collapseOne"
						>
							<span className="mainHeading">iSaveNow General Terms</span>
						</button>
					</h2>
					<div
						id="collapseOne"
						className="accordion-collapse collapse"
						aria-labelledby="headingOne"
						data-bs-parent="#accordionExample"
					>
						<div className="accordion-body">
							<MemberAndSubscriber />
						</div>
					</div>
				</div>
				<div className="accordion-item">
					<h2 className="accordion-header" id="headingTwo">
						<button
							className="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseTwo"
							aria-expanded="false"
							aria-controls="collapseTwo"
						>
							<span className="mainHeading">Competition Terms & Conditions</span>
						</button>
					</h2>
					<div
						id="collapseTwo"
						className="accordion-collapse collapse"
						aria-labelledby="headingTwo"
						data-bs-parent="#accordionExample"
					>
						<div className="accordion-body">
							<TermsAndConditionsISN />
						</div>
					</div>
				</div>
				<div className="accordion-item" id="headingThree">
					<h2 className="accordion-header">
						<button
							className="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseThree"
							aria-expanded="false"
							aria-controls="collapseThree"
						>
							<span className="mainHeading">Privacy Policy iSaveNow</span>
						</button>
					</h2>
					<div
						id="collapseThree"
						className="accordion-collapse collapse"
						aria-labelledby="headingThree"
						data-bs-parent="#accordionExample"
					>
						<div className="accordion-body">
							<PrivacyPolicyISaveNow />
						</div>
					</div>
				</div>
				<div className="accordion-item">
					<h2 className="accordion-header" id="headingFour">
						<button
							className="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseFour"
							aria-expanded="false"
							aria-controls="collapseFour"
						>
							<span className="mainHeading">
								Terms and Conditions iSaveNow/United Fuel
							</span>
						</button>
					</h2>
					<div
						id="collapseFour"
						className="accordion-collapse collapse"
						aria-labelledby="headingFour"
						data-bs-parent="#accordionExample"
					>
						<div className="accordion-body">
							<TermsAndConditionsFuel />
						</div>
					</div>
				</div>
				<div className="accordion-item">
					<h2 className="accordion-header" id="headingFive">
						<button
							className="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseFive"
							aria-expanded="false"
							aria-controls="collapseFive"
						>
							<span className="mainHeading">Terms and Conditions Stripe</span>
						</button>
					</h2>
					<div
						id="collapseFive"
						className="accordion-collapse collapse"
						aria-labelledby="headingFive"
						data-bs-parent="#accordionExample"
					>
						<div className="accordion-body">
							<a
								className="text-blue-600 font-semibold"
								href="https://stripe.com/en-au/legal/consumer"
							>
								Stripe's T&Cs
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
