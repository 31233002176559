import { useEffect, useState, useContext } from "react";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faEnvelope,
  faCopy,
} from "@fortawesome/free-regular-svg-icons";
import "./offerdetail.css";
import PageHeader from "../../components/masterlayout/PageHeader";
import Loader from "../../components/skeleton/Loader";
import { ClientContext } from "../../context/client/ClientContext"
import { useNavigate } from "react-router-dom";
import ISaveMoreModal from "../../components/masterlayout/ISaveMoreModal";
import { FavouritesContext } from "../../context/favourites/FavouritesContext";
import { addFavourites, deleteFavourites } from "../../utils/favourites";
import { setFavourites } from "../../utils/offers";
import { OffersContext } from "../../context/offers/OffersContext";
import { TenantContext } from "../../context/tenant/TenantContext";
import DOMPurify from "dompurify";

export default function OfferDetail() {
  const { client } = useContext(ClientContext);
  const { offers, offersdispatch } = useContext(OffersContext);
  const { tenant } = useContext(TenantContext);

  const { favourites, favouritesdispatch } = useContext(FavouritesContext);
  const navigate = useNavigate();
  let trackingUrl = "";

  const [offer, setOffer] = useState();
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const [isGiveaway, setIsGiveaway] = useState(false);
  const [isFav, setIsFav] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const handleShow = () => setIsOpen(true);
  const [copied, setCopied] = useState(false);
  const [isCouponCopied, setIsCouponCopied] = useState(false)

  const [isHow, setIsHow] = useState()

  const getPathSegment = () => {
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const segments = pathname.split("/");
    const id = segments[segments.length - 1]; // Gets the last segment
    return id;
  };

  const directToLogin = () => {
    navigate("/login");
  }

  const allowedIds = ['2539', '2540', '2542', '2543', '2544', '2545', '2546', '2547', '2548', '7602'];
  const id = getPathSegment();

  if (!client) {
    if (!allowedIds.includes(id)) {
      // navigate("/login");
    }
  } else {
    console.log("tenant?.tenanttier.allowappend", tenant?.tenanttier.allowappend)
    console.log("tenant?.tenanttier.tier2", tenant?.tenanttier.tier2)
    console.log("tenant?.tenanttier.tier1", tenant?.tenanttier.tier1)

    const tier = (tenant?.tenanttier.allowappend === 1) ?
      (client?.subscription_status === "active") ? `_${tenant?.tenanttier.tier2}` : `_${tenant?.tenanttier.tier1}`
      : "";
    trackingUrl = `${offer?.trackingUrl}&externalUserId=${client.euid}${tier}`
  }


  const getOfferbyID = async () => {

    try {
      setLoader(true);
      const res = await isnofferwallapi.get("/api/offers/offersgetbyid", {
        params: {
          offerId: params.favid ? params.favid : params.aboutus ? params.aboutus : params.id,
        },
      });

      setOffer(res.data);
      if (res.data.tags.length > 0)
        setIsHow(res.data.tags[0].trim() === "How")

      if (params.aboutus) {
        setIsHow(true)

      }
      setLoader(false);


    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getOfferbyID();

  }, []);

  useEffect(() => {



    if (favourites) {
      const favid = parseInt(params.favid ? params.favid : params.id)
      setIsFav(favourites.indexOf(favid) > -1)

    }
  }, [favourites]);

  const copyLink = () => {
    let text = window.location.href;
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  const copyCouponCode = () => {
    let text = document.getElementById("txtCouponCode").value;
    navigator.clipboard.writeText(text);
    setIsCouponCopied(true);
    setTimeout(() => setIsCouponCopied(false), 3000);
  };

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  // useEffect(() => {
  //   if (client?.subscription_status !== "active") {

  //   }
  // }, []);

  useEffect(() => {
    const detailBread = [{ label: "Home", path: "/" }];
    if (params.type) {
      detailBread.push({ label: params.type, path: `/offers/${params.type}` })
    } else if (params.month) {
      setIsGiveaway(true)
      detailBread.push({ label: "Giveaways", path: `/giveaways` })
      detailBread.push({ label: params.month, path: `/giveaways/${params.month}` })
    } else if (params.favid) {
      // setIsGiveaway(true)
      detailBread.push({ label: "My Favourites", path: `/myfavourites` })
    } else if (params.category) {
      detailBread.push({ label: params.category, path: `/categories/${params.category}` })
    } else if (params.previouswinners && params.previouswinners === "Previous Winners") {
      detailBread.push({ label: "About Us", path: `/about/aboutus` })
      detailBread.push({ label: "Previous Winners", path: `/about/aboutus/Previous Winners` })
    } else if (params.aboutus) {
      detailBread.push({ label: "About Us", path: `/about/aboutus` })
    }

    setBreadcrumbs(detailBread);
  }, []);


  //  useEffect(() => {
  //   if (!client) {
  //     if(!allowedIds.includes(id) && (!params.month)){
  //      //navigate("/login");
  //     }
  //   }
  // }, []);

  const updateFavourites = async (e, offerid) => {
    e.preventDefault();
    try {
      const favs = [...favourites];
      favouritesdispatch({ type: "FAVOURITES_START" });

      if (favs.indexOf(offerid) === -1) {
        const res = await addFavourites(client.euid, offerid);
        favs.push(offerid);
      } else {
        const res = await deleteFavourites(client.euid, offerid);
        favs.splice(favs.indexOf(offerid), 1);
      }
      favouritesdispatch({ type: "FAVOURITES_SUCCESS", payload: favs });
      const offersOrganized = setFavourites(offers.all, favs);
      offersdispatch({ type: "OFFERS_SUCCESS", payload: offersOrganized });

    } catch (error) {
      console.log("FAV UPDATE ERROR", error)
      favouritesdispatch({ type: "FAVOURITES_FAIL", payload: error });
    }


  }


  return (
    <>
      <PageHeader title={offer?.merchant.name} breadcrumbs={breadcrumbs} />
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div
              id="carouselExample"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              {/* Carousel indicators */}
              <div className="carousel-indicators">
                {offer?.images.map((image, index) => (
                  <button
                    type="button"
                    data-bs-target="#divOD"
                    data-bs-slide-to={index}
                    className={`${index === 0 ? "active" : ""}`}
                    key={index}
                  ></button>
                ))}
              </div>

              {/* Carousel inner */}
              <div className="carousel-inner">
                {offer?.images.map((image, index) => (
                  <div
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                    key={index}
                  >
                    <img
                      src={image}
                      className="d-block w-100 img-prod-details"
                      alt="..."
                    />
                    {
                      (isGiveaway) ? ""
                        :
                        <img
                          src={offer?.merchant.image} // Replace with the path to your merchant logo
                          className="od-merchant-logo"
                          alt="Merchant Logo"
                        />
                    }
                  </div>
                ))}
              </div>

              {/* Carousel controls */}
              {
                (offer?.images.length > 1) ?
                  <button
                    className="carousel-control-prev carousel-control-od"
                    type="button"
                    data-bs-target="#carouselExample"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon-od"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button> : ""
              }
              {
                (offer?.images.length > 1) ?
                  <button
                    className="carousel-control-next carousel-control-od"
                    type="button"
                    data-bs-target="#carouselExample"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon-od"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button> : ""
              }

            </div>
          </div>
          <div className="col-md-8 mt-4 mt-md-0">
            <div className="card shadow od-card">
              <div className="card-body od-card-body">
                <h1 className="isn-sub-title od-isn-sub-title">
                  {offer?.merchant.name}
                </h1>
                <h3 className="isn-sub2-title">{offer?.title}</h3>
                <p
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(offer?.description) }}
                  className="od-offer-desscription"
                ></p>

                <div>
                  {offer?.couponCode ? (
                    <span className="input-group  w-25 d-inline">
                      <input
                        id="txtCouponCode"
                        type="text"
                        className="form-control rounded-start-pill align-middle d-inline w-25"
                        placeholder="Coupon Code"
                        aria-label="Coupon Code"
                        aria-describedby="button-addon2"
                        // readOnly
                        value={offer?.couponCode}
                      />
                      <button
                        className="btn btn-outline-secondary rounded-end-pill d-inline od-btns-upper"
                        type="button"
                        id="button-addon2"
                        onClick={copyCouponCode}
                      >
                        {
                          (isCouponCopied) ? "Copied!" : "Copy"

                        }
                      </button>
                    </span>
                  ) : ""
                  }
                  {
                    (isGiveaway) ?
                      // (params.month && tenant?.tenantcontentconfig.giveaway.offer.id === parseInt(params.id)) ?
                      //   "" :
                        <div className="mt-3">
                          {

                            (client?.subscription_status === "active") ?
                              <a
                                className="btn btn-primary bg-primary border-0 rounded-pill m-2 ms-0 od-btns-upper">
                                You're in the draw!
                              </a>
                              :
                              // <a onClick={client ? handleShow : directToLogin}
                              <Link to="/giveaways"
                                className="btn btn-primary bg-primary border-0 rounded-pill m-2 ms-0 od-btns-upper">
                                {client ? `ENROL NOW` : `JOIN NOW`}
                              </Link>
                          }

                        </div>
                      :

                      <div className="mt-3">
                        {
                          (offer) ?
                            (isHow) ? ""
                              :
                              <div className="mb-3">

                                <span className="badge fw-bold isn-fs-12 mb-2 rounded-pill isn-thumb-pill isn-thumb-pill-green me-2 p-2">
                                  Premium - {
                                    (offer?.rewardType === "PERCENTAGE") ?
                                      `${(offer?.rewardValue * tenant?.tenantrewards.tier2 / 100).toFixed(1)}% Cashback`
                                      :
                                      `${offer?.currency} ${(offer?.rewardValue * tenant?.tenantrewards.tier2 / 100).toFixed(1)} Cashback`
                                  }
                                </span>
                                <span className="badge me-2 p-2 text-dark">
                                  Standard - {
                                    (offer?.rewardType === "PERCENTAGE") ?
                                      `${(offer?.rewardValue * tenant?.tenantrewards.tier1 / 100).toFixed(1)}% Cashback`
                                      :
                                      `${offer?.currency} ${(offer?.rewardValue * tenant?.tenantrewards.tier1 / 100).toFixed(1)} Cashback`
                                  }
                                </span>
                              </div>
                            : ""
                        }

                        {
                          (offer?.couponCode) ? "" :
                            (isHow) ? ""
                              :
                              (client) ?
                                <a href={trackingUrl} target="_blank"
                                  className="btn btn-primary bg-primary border-0 rounded-pill m-2 ms-0 od-btns-upper">
                                  SHOP NOW
                                </a>
                                :
                                <Link to={`/login?redirectto=${window.location.pathname}`}
                                  className="btn btn-primary bg-primary border-0 rounded-pill m-2 ms-0 od-btns-upper">
                                  SHOP NOW
                                </Link>
                        }
                        {/* (favourites?.indexOf(params.favid) > -1) ? 'btn-dark' : 'btn-outline-dark' */}
                        { //AI Modified 
                          (isHow) ? "" :
                            (client) ?
                              <button
                                type="button"
                                className={`btn ${(isFav) ? "btn-dark" : "btn-outline-dark"} rounded-pill m2 od-btns-upper`}
                                onClick={(e) => updateFavourites(e, offer?.id)}
                              >
                                <FontAwesomeIcon
                                  icon={faHeart}
                                  id="btn-SFL"
                                  style={{ cursor: "pointer" }}
                                />
                                &nbsp; {
                                  (isFav) ? "SAVED FOR LATER" : "SAVE FOR LATER"
                                }
                              </button>
                              : null
                        }
                      </div>

                  }

                </div>
                {
                  (true) ? "" :// (isHow) ? "" : << Original code  PULSE-3237
                    <div className="mt-4 of-detail-font-size">
                      <label className="fw-bold of-lbl-validDate">
                        {
                          (isGiveaway) ? `Giveaway End Date: `
                            : `Offer Valid Period: `
                        }

                        &nbsp;
                      </label>
                      <label>
                        {
                          (offer) ?
                            offer?.validTo
                              ? ` Until ${new Date(offer?.validTo).toJSON().slice(0, 10).split('-').reverse().join('/')}`
                              : ` ${new Date(offer?.validFrom).toJSON().slice(0, 10).split('-').reverse().join('/')} onwards`
                            : ""
                        }
                      </label>
                    </div>
                }

                {

                  (isHow) ? "" :
                    (isGiveaway) ? (client?.subscription_status === "active") ? <p className="od-offer-desscription"> Good News! As an iSaveNow Premium member, you're already in the draw to WIN!
                      There's nothing else you need to do - Good Luck! </p> : ``
                      : ``

                  // <div className="of-detail-font-size">
                  //   <label className="fw-bold">
                  //     Offer Available Countries: &nbsp;
                  //   </label>
                  //   <label>{offer?.countryNames}</label>
                  // </div>


                }
                {/* {
                  console.log(typeof(tenant?.tenantcontentconfig.giveaway.offer.id))
                }{console.log(typeof(params.id))
                }{console.log(tenant?.tenantcontentconfig.giveaway.offer.id === params.id)
                }
                {console.log(params.month)
                } */}
                {
                  (isHow) ? "" :
                    // (params.month && tenant?.tenantcontentconfig.giveaway.offer.id === parseInt(params.id))
                    //   ? "" :
                      <div className="mt-5">
                        <p className="fw-bold od-shareDeals">Share this deal</p>

                        <a href={`mailto:?body=${window.location.href}`}

                          className="btn btn-outline-dark rounded-pill m-2 od-btns-lower"
                        >
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            id="btn-SFL"
                            style={{ cursor: "pointer" }}
                          />
                          &nbsp; <span className="od-btn-email">Email</span>
                        </a>
                        <button
                          type="button"
                          className="btn btn-outline-dark rounded-pill m-2 od-btns-lower"
                          onClick={copyLink}
                        >
                          <FontAwesomeIcon
                            icon={faCopy}
                            id="btn-SFL"
                            style={{ cursor: "pointer" }}
                          />
                          &nbsp; <span className="od-btn-copy"> {copied ? "Copied!" : "Copy Link"}</span>
                        </button>
                      </div>
                }
                {
                  (params.aboutus) ? "" :
                    <div className="mt-5 od-tc">
                      <div className="row">
                        <label className="fw-bold">Terms and Conditions:</label>
                      </div>
                      <div className="row">
                        {

                          (isHow) ?
                            <div>
                              The Terms and Conditions for ISaveNow are listed at this page
                              <Link to={"/about/terms-and-conditions"}>T&C</Link>
                            </div>
                            :
                            <label dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(offer?.termsAndConditions), }}></label>

                        }
                      </div>
                    </div>
                }

              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader loader={loader} />
      <ISaveMoreModal show={isOpen} handleClose={handleClose} handleShow={handleShow} />
    </>
  );
}

