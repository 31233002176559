

import "./skeleton.css";



import { Col, Container, Ratio, Row } from "react-bootstrap";
import "./skeleton.css";
import { useEffect, useState } from "react";

export default function Skeleton({ type, count, parenttype}) {

    const SixOfferSkeleton = () => (
        <div className="placehoder-glow" style={{ width: "16.6%" }}>
            <div className="card border-0 nav-link">
                <div className="position-relative">
                    <div className="ratio ratio-1x1">
                        <div style={{ backgroundColor: "#ccc" }}></div>
                    </div>
                </div>
                <div className="card-body ps-0 pe-0 placeholder-glow">
                    <div className="card-title text-truncate isn-thumb-title placeholder w-100"></div>
                    <div className="card-text isn-thumb-desc text-truncate placeholder w-50"
                        style={{ lineClamp: 2, boxOrient: "vertical" }}></div>
                    {/* <div className="rounded-pill me-2 placeholder w-25 d-block"></div>

                    <div className="placeholder rounded-pill w-25"></div> */}

                </div>
            </div>
        </div>
    );

    const FiveOfferSkeleton = ({parenttype}) => {
        const [fivewidth, setFiveWidth] = useState("20%");
        const resizeWindow = () => {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                //alert(obj.parenttype === "page")
                const width = (parenttype === "page") ? "100%" : "20%"
                setFiveWidth(width);
            } else {
                setFiveWidth("20%");
            }
        }
        useEffect(() => {
            // if (obj.offers) window.addEventListener('resize', resizeWindow)
            resizeWindow();
        }, []);

        return <div className="mb-4 placeholder-glow" style={{ width: fivewidth }}>
            <div className="card border-0 nav-link">
                <div className="position-relative" style={{ borderRadius: "12px" }}>
                    <div className="ratio ratio-1x1">
                        <div style={{ backgroundColor: "#ccc" }}></div>
                    </div>
                </div>
                <div className="card-body ps-0 pe-0 placeholder-glow">
                    <div className="card-title text-truncate isn-thumb-title placeholder w-75"></div>
                    {/* <div className="placeholder rounded-pill me-2"></div>
                    <div className="placeholder rounded-pill"></div> */}
                </div>
            </div>
        </div>
    }

    const GiveawaySkeleton = () => (
        <div className="placehoder-glow col-md-4" >
            <div className="card border-0 nav-link">
                <div className="position-relative">
                    <div className="ratio ratio-1x1">
                        <div style={{ backgroundColor: "#ccc" }}></div>
                    </div>
                </div>
                <div className="card-body ps-0 pe-0 placeholder-glow">
                    <div className="card-title text-truncate isn-thumb-title placeholder w-75"></div>
                    {/* <div className="card-text isn-thumb-desc text-truncate placeholder w-50"
                        style={{ lineClamp: 2, boxOrient: "vertical" }}></div> */}
                    {/* <div className="rounded-pill me-2 placeholder w-25 d-block"></div>

                    <div className="placeholder rounded-pill w-25"></div> */}

                </div>
            </div>
        </div>
    );

    const BrandSkeleton = () => (
        <div className="placehoder-glow col" >
            <div className="card border-0 nav-link">
                <div className="position-relative">
                    <div className="ratio ratio-1x1">
                        <div style={{ backgroundColor: "#ccc" }}></div>
                    </div>
                </div>
            </div>
        </div>
    );

    const OfferDetailSkeleton = () => (
        <div className="row mt-5">
            <div className="col-md-5 placehoder-glow">
                <div class="ratio ratio-1x1">
                    <div className="placeholder" style={{ backgroundColor: "#ccc" }}></div>
                </div>
            </div>
            <div className="col-md-7 mt-5 mt-md-0 ps-5">
                <div className="od-card">
                    <div className="card-body od-card-body placeholder-glow">
                        <div className="row">
                            <div className="w-auto">
                                <div
                                    // Replace with the path to your merchant logo
                                    className="od-merchant-logo placehoder placeholder-lg"
                                    style={{ backgroundColor: "#ccc" }}
                                ></div>
                            </div>
                            <div className="col section_title mt-5">
                                <div className="placeholder placeholder-lg w-50"></div>
                            </div>
                        </div>

                        <div className="od-offer-title mt-3 placeholder placeholder-lg w-75"></div>

                        <div className="mt-4">
                            <div className="placeholder w-25"></div>
                            <div className="placeholder w-50 mt-2"></div>
                        </div>

                        <div className="mt-5 mb-3">
                            <div className="w-25 placeholder"></div>
                        </div>
                        <div className="mt-5 od-tc">
                            <div className="row mt-5">
                                <div className="w-50 placeholder"></div>
                            </div>
                            <div className="row mt-3">
                                <div className="placeholder w-25 mt-3 d-block"></div>
                                <div className="placeholder w-100 mt-3 d-block"></div>
                                <div className="placeholder w-50 mt-3 d-block"></div>
                                <div className="placeholder w-75 mt-3 d-block"></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const CategorySkeleton = () => (
        <div id="catall" className="placeholder-glow d-inline-block category" >
            <div className="grid-icon placeholder m-auto mb-2"
                style={{ width: "50px", height: "50px", backgroundColor: "#ccc", borderRadius: "50%" }}></div>
            <div className="grid-label placeholder ps-5 pe-5"></div>
        </div>
    );

    if (type === "sixoffer") return <SixOfferSkeleton />
    if (type === "fiveoffer") return <FiveOfferSkeleton parenttype={parenttype} />;
    if (type === "giveaway") return <GiveawaySkeleton />;
    if (type === "brands") return <BrandSkeleton />;

    if (type === "offerdetail") return <OfferDetailSkeleton />;
    if (type === "category") return Array.from({ length: count }, (_, i) => <CategorySkeleton key={i} />);

}