import React from "react";
import Included from "./included";
import WinGiveaway from "./win-giveaway";
import ClaimGiveaway from "./claim-giveaway";

export default function ISaveMoreFAQ() {
  return (
    <div className="container my-5">
      <div className="accordion" id="">
        <div className="accordion-item">
          <h2 className="accordion-header" id="subHeadingOne">
            <button
              className="accordion-button  collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#subCollapseOne"
              aria-expanded="false"
              aria-controls="subCollapseOne"
            >
              <span className="mainFaqHeading">
                What is included when I upgrade to iSaveNow Premium?
              </span>
            </button>
          </h2>
          <div
            id="subCollapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="subHeadingOne"
            data-bs-parent="collapse"
          >
            <div className="accordion-body">
              <p>
                Upgrading to iSaveNow Premium lets you access our MAJOR cash giveaways, plus extra perks, every day you're a member!
                </p>
                <p>iSaveNow Premium members enjoy:</p>
                <ul>
                  <li>Access to deals from 1,500+ brand partners</li>
                  <li>DOUBLE cashback on select brands</li>
                  <li>First look at promotional offers and new iSaveNow Premium releases</li>
                </ul>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="subHeadingTwo">
            <button
              className="accordion-button  collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#subCollapseTwo"
              aria-expanded="false"
              aria-controls="subCollapseTwo"
            >
              <span className="mainFaqHeading">
                How do I upgrade to iSaveNow Premium?
              </span>
            </button>
          </h2>
          <div
            id="subCollapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="subHeadingTwo"
            data-bs-parent="collapse"
          >
            <div className="accordion-body">
              <p>
                Upgrading to iSaveNow Premium is easy! Visit our <a href="/giveaways">Giveaways</a> page to browse and choose a one-time payment package, starting from just $10. Once purchased, you'll have access to all iSaveNow Premium perks AND automatic entries into our major giveaways!
              </p>
              <p>Prefer a "set and forget" option? Subscribe to iSaveNow Premium just $40 per month, and also receive 10 entries to our DAILY $1,000 cash giveaways!</p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="subHeadingThree">
            <button
              className="accordion-button  collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#subCollapseThree"
              aria-expanded="false"
              aria-controls="subCollapseThree"
            >
              <span className="mainFaqHeading">
                If I win an iSaveNow Premium giveaway, how will I be notified?
              </span>
            </button>
          </h2>
          <div
            id="subCollapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="subHeadingThree"
            data-bs-parent="collapse"
          >
            <div className="accordion-body">
              <WinGiveaway />
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="subHeadingFour">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#subCollapseFour"
              aria-expanded="false"
              aria-controls="subCollapseFour"
            >
              <span className="mainFaqHeading">
                How can I claim giveaway prizes?
              </span>
            </button>
          </h2>
          <div
            id="subCollapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="subHeadingFour"
            data-bs-parent="collapse"
          >
            <div className="accordion-body">
              <ClaimGiveaway />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
