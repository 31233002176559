import { createContext, useReducer } from "react"
import FavouritesReducer from "./FavouritesReducer";

const FAVOURITES_INITIAL_STATE = {
    favourites: [], //JSON.parse(localStorage.getItem("owos")) || null,
    favouritesIsFetching: false,
    favouritesError: false
}

export const FavouritesContext = createContext(FAVOURITES_INITIAL_STATE);

export const FavouritesContextProvider = ({ children }) => {
    const [favouritesstate, favouritesdispatch] = useReducer(FavouritesReducer, FAVOURITES_INITIAL_STATE);

    // useEffect(() => {
    //     localStorage.setItem("owos", JSON.stringify(favouritesstate.favourites));
    // }, [favouritesstate.favourites]);

    return <FavouritesContext.Provider value={{
        favourites: favouritesstate.favourites,
        favouritesIsFetching: favouritesstate.favouritesIsFetching,
        favouritesError: favouritesstate.favouritesError,
        favouritesdispatch
    }}>{children}</FavouritesContext.Provider>

}