import { useEffect, useState } from "react";
import FiveOfferThumb from "../thumbnails/FiveOfferThumb";
import { Link } from "react-router-dom";
import GiveawayThumb from "../thumbnails/GiveawayThumb";
import Skeleton from "../skeleton/Skeleton";
import ThreeOfferThumb from "../thumbnails/ThreeOfferThumb";


export default function ThreeOfferSection(obj) {

    // console.log(obj)
    const [threecolumnwidth, setThreeColumnWidth] = useState(`auto`);
    const [isMobile, setIsMobile] = useState(false);
    const resizeWindow = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            setIsMobile(true);
            if (obj?.scrollable) {
                const width = window.innerWidth;
                const itemwidth = width / 100 * 38;
                setThreeColumnWidth(`${itemwidth * 3}px`);
            }
        } else {
            setIsMobile(false);
            setThreeColumnWidth(`auto`);
        }
    }
    useEffect(() => {
        if (obj.offers) window.addEventListener('resize', resizeWindow)
        resizeWindow()
    }, [obj.offers]);

    return <div className="mb-3 mt-3">
        {
            (obj?.showsectionheader) ?
                <div className="row mb-3">
                    <h1 className="col isn-sub-title" style={{ color: obj.color }}>{obj?.sectiontype}</h1>
                    <div className="col text-end align-middle">
                        <Link style={{ textDecoration: "none", color: "black" }}
                            to={`/about/aboutus/${obj?.sectiontype}`}>View all</Link>
                        <i className="bi bi-chevron-right"></i>
                    </div>
                </div>
                : ""
        }

        <div className="overflow-auto isn-hide-scroll">
            <div className="row" style={{ width: `${(obj.scrollable) ? threecolumnwidth : "auto"}` }}>
                {
                    (obj.offers) ?
                        obj.offers?.toReversed().map((offer, index) => {
                            if (index < ((obj.showcount) ? obj.showcount : 3))
                                return <ThreeOfferThumb thumblink={obj?.sectiontype} sectiontype={obj?.sectiontype}
                                    offer={offer} key={index} layout={obj?.layout} scrollable={obj.scrollable}
                                    parenttype={obj?.parenttype} isroundedcorner={obj?.isroundedcorner} />
                        })
                        : Array.from({ length: 3 }, (_, i) => <Skeleton key={i} type="giveaway" />)

                }
            </div>
        </div>
    </div>
}