import { useEffect, useRef, useState, useContext } from "react";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import { useParams } from "react-router-dom";
import { uuid } from "uuidv4";
import PageHeader from "../../components/masterlayout/PageHeader";
import "./giftcard.css";
import { ClientContext } from "../../context/client/ClientContext"
import { useNavigate } from "react-router-dom";
import Loader from "../../components/skeleton/Loader";

export default function GiftCard() {
    const navigate = useNavigate();
    const { client } = useContext(ClientContext);
    const [loader, setLoader] = useState(false);
    const [cusEmailError, setCusEmailError] = useState("");
    const [nameError, setNameError] = useState("");

    if (!client) {
        navigate("/login");
    }

    const breadcrumbs = [
        { label: "Home", path: "/" },
        { label: "Gift Cards", path: "/giftcards" }    // Do not include the current page here
    ];

    const [giftCard, setGiftCard] = useState();
    const amount = useRef(0.00);
    const amountShow = useRef("0.00");
    const qty = useRef(1);
    const total = useRef(0);
    const save = useRef(0);
    const finalAmount = useRef(0);
    const cusName = useRef("");
    const cusEmail = useRef("");
    // const unique_id = uuid();
    const params = useParams();
    // const [userSession, setUserSession] = useState({});

    const userSessionCookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("userSession"))
        ?.split("=")[1];

    const userSession = (userSessionCookie
        ? JSON.parse(decodeURIComponent(userSessionCookie))
        : null);


    const addCount = () => {
        qty.current.value = (parseInt(qty.current.value) + 1);
        setPrices();
    }
    const minusCount = () => {
        qty.current.value = (parseInt(qty.current.value) - 1);
        onQtyChange();
    }
    const onQtyChange = () => {
        let cqty = qty.current.value;
        cqty = parseInt((cqty === "") ? 1 : cqty);
        if (cqty < 1) {
            qty.current.value = 1;
        } else {
            qty.current.value = cqty;
        }
        setPrices();
    }

    const addAmount = () => {
        let increment = 5
        if(giftCard.maximum_value > 100){
            increment = 25
        }
        amount.current.value = (parseFloat(amount.current.value) + parseFloat(increment)).toFixed(2);
        onAmountChange();
        setAmountShow();
    }
    const minusAmount = () => {
        let decrement = 5
        if(giftCard.maximum_value > 100){
            decrement = 25
        }
        amount.current.value = (parseFloat(amount.current.value) - parseFloat(decrement)).toFixed(2);
        onAmountChange();
        setAmountShow();
    }
    const onAmountChange = () => {
        let camount = amount.current.value;
        
        camount = parseFloat((camount === "") ? giftCard.minimum_value : camount);
        if (camount < giftCard.minimum_value) {
            amount.current.value = giftCard.minimum_value;
        } else if (camount > giftCard.maximum_value) {
            amount.current.value = giftCard.maximum_value;
        } else {
            amount.current.value = camount.toFixed(2);
        }
       
        setPrices();
    }

    const  setAmountShow = () => {
        amountShow.current.value = giftCard?.currency+" $"+amount.current.value; 
    }

      const setAmountValue = (evt) => {
         if (evt.target.value.split('$').length > 1 ){
            amount.current.value = evt.target.value.split('$')[1]
         }else{
            amount.current.value = evt.target.value.split('$')[0]
         }
         onAmountChange();
      }

    const setPrices = () => {
        const discount = parseFloat(giftCard?.customer_discount);
        const discountType = giftCard?.discount_type;
        total.current.innerText = (parseFloat(qty.current.value) * parseFloat(amount.current.value)).toFixed(2);

        if (discountType === "PERCENTAGE") {
            save.current.innerText = (parseFloat(total.current.innerText) * discount / 100).toFixed(2);

        } else {
            save.current.innerText = (discount * parseFloat(qty.current.value)).toFixed(2);

        }
        finalAmount.current.innerText = (parseFloat(total.current.innerText) - parseFloat(save.current.innerText)).toFixed(2);
        // alert("A")

    }

    const allowNumaric = (evt) => {
        var theEvent = evt || window.event;
    
        // Handle paste
        if (theEvent.type === 'paste') {
            key = evt.clipboardData.getData('text/plain');
        } else {
        // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            if(key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46) { // Left / Up / Right / Down Arrow, Backspace, Delete keys
                return;
            }
            key = String.fromCharCode(key);
        }
        var regex = /[0-9|\b|/]/;///[0-9]|[\b]|[\.]|\./;
        if( !regex.test(key) ) {
          theEvent.returnValue = false;
          if(theEvent.preventDefault) theEvent.preventDefault();
        }
      }

    useEffect(() => {
        setPrices();


    }, [giftCard])

    const GetGiftCards = async () => {
        try {
            setLoader(true);

            const res = await isnofferwallapi.get("/api/giftcards/giftcards");
          

            for (let index = 0; index < res.data.length; index++) {
                const element = res.data[index];
                if (element.code === params.code) {
                    let minValue = element.maximum_value;
                    if(element.maximum_value > 100 && element.minimum_value>100){
                        minValue = element.minimum_value
                    }else if(element.maximum_value > 100){
                        minValue = 100
                    }

                    amount.current.value = minValue;
                    amountShow.current.value = element.currency+" $"+amount.current.value 
                    qty.current.value = 1;
                    cusName.current.value = userSession.firstname+" "+userSession.lastname;
                    cusEmail.current.value = userSession.email;
                    setGiftCard(element);
                    setLoader(false);

                    // setTimeout(() => {
                    //     setPrices();
                    // }, 500);
                    break;
                }

            }

        } catch (error) {
            setLoader(false);

        }
    }
    useEffect(() => {
        GetGiftCards();
    }, [])

    const validateEmail = (email) => {
        if (!email) return "Email address is required.";
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) return "Please enter a valid email address.";
        return "";
      };

      const validateName = (name) => {
        if (!name) return `Name is required.`;
        if (name.length < 2 || name.length > 30)
          return `Name should be between 2 and 30 characters.`;
        if (!/^[A-Za-z ]+$/.test(name))
          return `Name should only contain letters.`;
        return "";
      };

      const hasValidationErrors = () => {
        return (
          cusEmailError ||
          nameError
        );
      };

    const GetStripeURL = async () => {

        setCusEmailError(validateEmail(cusEmail.current.value));
        setNameError(validateName(cusName.current.value));
        if(!hasValidationErrors()){
            try {
                setLoader(true);
                const tot = parseFloat(finalAmount.current.innerText)
                const stripefee = (tot === 0.00) ? 0.00 : tot * 0.0175 + 0.3;
                const finaltot = (tot + stripefee)/ parseInt(qty.current.value);
                let name = cusName.current.value
                let fname = name.substring(0, name.indexOf(' ')).trim();
                let lname = name.substring(name.indexOf(' ') + 1).trim();
                const res = await isnofferwallapi.post("/api/giftcards/stripeurl", {
                    "firstName": fname?fname:lname,
                    "lastName": lname?lname:fname,
                    "email": cusEmail.current.value,
                    "vendor": "BlackHawkNetwork",
                    "currency": giftCard.currency,
                    "userId": userSession.euid,
                    "giftOrderDetails": [
                        {
                            "amount": parseFloat(total.current.innerText),
                            "discount": parseFloat(save.current.innerText),
                            "total": finaltot,
                            "giftCardCode": giftCard.code,
                            "giftId": 1,
                            "name": giftCard.title,
                            "description": giftCard.description,
                            "quantity": parseInt(qty.current.value)
                        }
                    ]
                });
                window.location.replace(res.data.redirectURL);
    
            } catch (error) {
                console.log("STRIPE URL ERROR", error)
                setLoader(false);
    
            }
        }
    }
    

    return <div className="isn-page-bg pb-5">
        <div>
            <PageHeader title={giftCard?.title} breadcrumbs={breadcrumbs} />
        </div>
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="">


                        <img src={giftCard?.image} alt="" className="w-100 isn-bradius isn-curve-bottom-right " />

                        <div className="isn-bradius bg-white p-4 mt-4">
                            <div className="card mb-3 border-0 mt-4">
                                <div className="row g-0">
                                    <div className="col-md-2">
                                        <img src={giftCard?.merchant_logo} className="img-fluid rounded-circle"
                                            style={{ width: "50px", height: "50px" }} alt="..." />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body p-0">
                                            <h5 className="card-title">{giftCard?.merchant_name}</h5>
                                            <p className="card-text">
                                                <small className="text-body-secondary">
                                                    Range {giftCard?.currency} ${giftCard?.minimum_value} - {giftCard?.currency} ${giftCard?.maximum_value}
                                                </small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 isn-fs-12">
                                {giftCard?.description}
                            </div>
                            <div className="mt-4">
                                <div className="accordion" id="divGiftCardAccordian">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button isn-fs-13 fw-bold bg-white text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#gftcrdRedemptionInstructions" aria-expanded="true" aria-controls="gftcrdRedemptionInstructions">
                                                Redemption Instructions
                                            </button>
                                        </h2>
                                        <div id="gftcrdRedemptionInstructions" className="accordion-collapse collapse show">
                                            <div className="accordion-body isn-fs-12">
                                                {giftCard?.redemption_info}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed isn-fs-13 fw-bold bg-white text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#gftcrdTermsandConditions" aria-expanded="false" aria-controls="gftcrdTermsandConditions">
                                                Terms and Conditions
                                            </button>
                                        </h2>
                                        <div id="gftcrdTermsandConditions" className="accordion-collapse collapse">
                                            <div className="accordion-body isn-fs-12">
                                                {giftCard?.terms_and_conditions}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 bg-white isn-bradius isn-curve-bottom-left mt-4 mt-md-0"
                    style={{ padding: "20px 10% 20px 10%" }}>
                    <div>
                        <h3 className="isn-fs-16 mb-4 fw-bold">Who's it for?</h3>
                        <div className="mb-3">
                            <input type="text" ref={cusName} className={`form-control ${nameError ? "is-invalid" : ""}`} 
                             onBlur={(e) => setNameError(validateName(e.target.value))}
                            id="txtGftCrdName" placeholder="Name" />
                            <div className="invalid-feedback isn-fs-10">
                                {nameError}
                            </div>
                        </div>
                        <div className="mb-3">
                            <input type="email" ref={cusEmail} className={`form-control ${cusEmailError ? "is-invalid" : ""}`} 
                            id="txtGftCrdEmail" placeholder="Email Address" 
                            onBlur={(e) => setCusEmailError(validateEmail(e.target.value))}/>
                            <div className="invalid-feedback isn-fs-10">
                                {cusEmailError}
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <h3 className="isn-fs-16 mb-4 fw-bold">Set Gift Card Amount</h3>

                        <div className="input-group mb-3">
                            <button className="btn btn-primary border-0 btn-add-minus rounded-circle me-3 mt-3 mb-3 bg-primary fw-bold"
                                type="button" id="button-addon2" onClick={minusAmount}>-</button>
                                 <input id="txtAmountShow"
                                    onBlur={setAmountShow} ref={amountShow} type="text" onKeyDownCapture={allowNumaric} onKeyUpCapture={setAmountValue}
                                className="form-control text-center p-3" placeholder="" aria-describedby="button-addon1" />
                            
                            <button className="btn btn-primary border-0 btn-add-minus rounded-circle ms-3 mt-3 mb-3 bg-primary fw-bold"
                                type="button" id="button-addon1" onClick={addAmount}>+</button>
                                <input id="txtAmount"
                                onBlur={onAmountChange} ref={amount} type="hidden"
                                className="form-control text-center p-3" placeholder="" aria-describedby="button-addon1" />
                        </div>
                    </div>

                    <div className="mt-5">
                        <h3 className="isn-fs-16 mb-4 fw-bold">Quantity</h3>

                        <div className="input-group mb-3">
                            <button className="btn btn-primary border-0 btn-add-minus rounded-circle me-3 mt-3 mb-3 bg-primary fw-bold"
                                type="button" id="button-addon2" onClick={minusCount}>-</button>
                            <input id="txtQuantity" type="text" ref={qty} onBlur={onQtyChange}
                                className="form-control text-center p-3" placeholder=""
                                aria-describedby="button-addon1" />
                            <button className="btn btn-primary border-0 btn-add-minus rounded-circle ms-3 mt-3 mb-3 bg-primary fw-bold"
                                type="button" id="button-addon1" onClick={addCount}>+</button>
                        </div>

                    </div>
                    <div className="mt-5">
                        <div className="d-flex mb-3  isn-fs-13">
                            <div className="me-auto p-2">Total Value</div>
                           <div className="">{giftCard?.currency} $ </div><div className="text-end" ref={total}>0.00</div>
                        </div>
                        <div className="d-flex mb-3  isn-fs-13" style={{ color: "#28D294" }}>
                            <div className="me-auto p-2">You Saved</div>
                            <div className="">{giftCard?.currency} $ </div>
                            <div className="text-end" ref={save}>0.00</div>
                        </div>
                        <div className="d-flex mb-3 isn-fs-13">
                            <div className="me-auto p-2">You will Pay</div>
                            <div className="">{giftCard?.currency} $ </div>
                            <div className="text-end" ref={finalAmount}>0.00</div>
                        </div>
                        <div className="mt-2">
                            <p className="isn-fs-11">* plus payment processing fees</p>
                        </div>
                        <div className="text-center mt-4">
                            <button type="button" className="btn btn-primary bg-primary border-0 rounded-pill p-2 ps-4 pe-4" onClick={GetStripeURL}>PURCHASE GIFT CARD</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Loader loader={loader} />

    </div>
}