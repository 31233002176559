import React, { useState } from "react";
import PageHeader from "../../components/masterlayout/PageHeader";
import "./contactUs.css";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import Alert from "../../components/alert/Alert";
import useAlert from "../../components/alert/useAlert";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const breadcrumbs = [
    { label: "Home", path: "/" },
    // Do not include the current page here
  ];
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [nameVal, setName] = useState("");
  const [description, setDescription] = useState("");
  const [emailError, setEmailError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const resetForm = () => {
    setEmail("");
    setName("");
    setSubject("");
    setDescription("");
  };

  const {
    alert,
    showSuccessAlert,
    showErrorAlert,
    showWarningAlert,
    clearAlert,
  } = useAlert();

  const validateEmail = (email) => {
    if (!email) return "Email address is required.";
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) return "Please enter a valid email address.";
    return "";
  };

  const validateSubject = (subject) => {
    if (!subject) return "Subject is required.";
    // Add more conditions if needed
    return "";
  };

  const validateName = (name) => {
    if (!name) return "Name is required.";
    // Add more conditions if needed
    return "";
  };

  const validateDescription = (description) => {
    if (!description) return "Description is required.";
    // Add more conditions if needed
    return "";
  };

  const hasValidationErrors = () => {
    if (
      emailError == "" &&
      subjectError == "" &&
      nameError == "" &&
      descriptionError == ""
    ) {
      return true;
    }
    return false;
  };
  const onSubmit = async (event) => {
    event.preventDefault();

    setEmailError(validateEmail(email));
    setSubjectError(validateSubject(subject));
    setNameError(validateName(nameVal));
    setDescriptionError(validateDescription(description));

    try {
      if (hasValidationErrors()) {
        const result = await isnofferwallapi.post("/api/about/contact_us", {
          subject,
          email,
          name: nameVal,
          description,
        });

        if (result.data.code == "201") {
          showSuccessAlert("Your query has been submitted successfully.");
          resetForm();
        } else {
          showErrorAlert("Your query has not been submitted");
        }
      }
    } catch (error) {
      console.error("Error  : ", error);
      showErrorAlert("Your query has not been submitted. Please try again.");
    }
  };

  return (
    <>
      <div>
        <PageHeader title={"Contact us"} breadcrumbs={breadcrumbs} />
      </div>
      <div className="container">
        <div className="card mx-auto rounded-3 shadow">
          <div className="card-body">
            <h2 className="card-title text-left mb-4 isn-fs-24 px-3">
              Contact us
            </h2>
            <p className="text-left text-muted isn-fs-14 px-3">
              {/* Enter your email below to receive your password reset instructions */}
            </p>
            <form onSubmit={onSubmit}>
              <div className="row mb-3 ps-3">
                <div className="col-md-3">
                  <input
                    type={"text"}
                    className={`form-control isn-fs-13 ${
                      subjectError ? "is-invalid" : ""
                    }`}
                    id="txtSubject"
                    value={subject}
                    placeholder="Subject"
                    onChange={(e) => setSubject(e.target.value)}
                    onBlur={() => setSubjectError(validateSubject(subject))}
                    required
                  />
                  <div className="invalid-feedback isn-fs-11">
                    {subjectError}
                  </div>
                </div>
              </div>
              <div className="row mb-3 p-3">
                <div className="col-md-3">
                  <input
                    type="email"
                    className={`form-control isn-fs-13 text-reset ${
                      emailError ? "is-invalid" : ""
                    }`}
                    id="txtEmail"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={() => setEmailError(validateEmail(email))}
                    required
                  />
                  <div className="invalid-feedback isn-fs-11">{emailError}</div>
                </div>
              </div>
              <div className="row mb-3 ps-3">
                <div className="col-md-3">
                  <input
                    type={"text"}
                    className={`form-control isn-fs-13 text-reset ${
                      nameError ? "is-invalid" : ""
                    }`}
                    id="txtName"
                    value={nameVal}
                    placeholder="Name"
                    onChange={(e) => setName(e.target.value)}
                    onBlur={() => setNameError(validateName(nameVal))}
                    required
                  />
                  <div className="invalid-feedback isn-fs-11">{nameError}</div>
                </div>
              </div>
              <div className="row mb-3 ps-3">
                <div className="col-md-3 form-floating">
                  <textarea
                    type={"text"}
                    className={`form-control isn-fs-13 text-reset ${
                      descriptionError ? "is-invalid" : ""
                    }`}
                    id="txtDescription"
                    value={description}
                    placeholder="Discription"
                    onChange={(e) => setDescription(e.target.value)}
                    onBlur={() =>
                      setDescriptionError(validateDescription(description))
                    }
                    required
                  />
                  <div className="invalid-feedback isn-fs-11">
                    {descriptionError}
                  </div>
                  {/* <label for="floatingTextarea" className="isn-fs-13 ms-2">Description</label> */}
                </div>
              </div>

              <div className="px-3">
                <button
                  type="submit"
                  className="btn btn-primary btn-fp w-10 px-4 border-0 rounded-pill"
                >
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
          <Alert
            show={alert.show}
            type={alert.type}
            message={alert.message}
            onClose={clearAlert}
          />
        </div>
      </div>
    </>
  );
};

export default ContactUs;
