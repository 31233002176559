import { Link } from "react-router-dom"
import "./banner.css"
import { BannerOffer, BannerWithTitle, BannerWithTitleAndButton } from "./BannerContent"

export default function Banner(obj) {

    const clickUpgrade = () => {
        document.getElementById("btnTopUpgradeNow").click();
    }
    return <div>


        <div id={obj.bannerid} className="carousel slide isn-bradius isn-curve-bottom-right" data-bs-ride="carousel">
            <div className="carousel-indicators" style={{margin:"-5px"}}>
                {
                    obj.items.map((item, index) => {
                        return <button key={index} type="button" data-bs-target={`#${obj.bannerid}`} data-bs-slide-to={index} className={`${index === 0 ? 'active' : ''}`}></button>
                    })
                }
                {/* <button type="button" data-bs-target="#divBanner" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#divBanner" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#divBanner" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
            </div>
            <div className="carousel-inner hp-carousel-inner">
                {
                    obj.items.map((item, index) => {
                        return <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                            {
                                (obj.type === "LEFT") ?
                                    <div>
                                        <div className="row overflow-hidden m-0 d-md-block d-md-flex d-none" style={{ height: "380px" }}>
                                            <div className="col-md-8"
                                                style={{
                                                    backgroundImage: `url('${item.dImg}')`,
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center"
                                                }}>

                                            </div>
                                            <div className="col-md-4 position-relative text-white banner-top-image"
                                                style={{ borderRadius: "0 0 90px 0", backgroundColor: item.bgColor }}>
                                                {
                                                    (obj.contentType === "OFFER") ?
                                                        <BannerOffer offer={item} />
                                                        :
                                                        (obj.contentType === "TITLE") ?
                                                            <BannerWithTitle content={item} />
                                                            :
                                                            <BannerWithTitleAndButton content={item} />
                                                }
                                            </div>


                                        </div>
                                        <div className="row rounded-3 overflow-hidden m-0 d-block d-md-none">

                                            <div className="col-md-8 ratio ratio-1x1"
                                                style={{
                                                    backgroundImage: `url('${item.mImg}')`,
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center"
                                                }}>

                                            </div>
                                            <div className="col-md-4 position-relative text-white  banner-top-image ratio ratio-4x3"
                                                style={{ borderRadius: "0 0 90px 0" }}>
                                                {
                                                    (obj.contentType === "OFFER") ?
                                                        <BannerOffer offer={item} />
                                                        :
                                                        (obj.contentType === "TITLE") ?
                                                            <BannerWithTitle content={item} />
                                                            :
                                                            <BannerWithTitleAndButton content={item} />
                                                }
                                            </div>

                                        </div>
                                    </div>
                                    : (obj.type === "RIGHT") ?
                                        <div>
                                            <div className="row overflow-hidden m-0 d-md-block d-md-flex  d-none" style={{ height: "380px" }}>
                                                <div className="col-md-4 position-relative text-white  banner-top-image"
                                                    style={{ borderRadius: "0 0 0 90px" }}>
                                                    {
                                                        (obj.contentType === "OFFER") ?
                                                            <BannerOffer offer={item} />
                                                            :
                                                            (obj.contentType === "TITLE") ?
                                                                <BannerWithTitle content={item} />
                                                                :
                                                                <BannerWithTitleAndButton content={item} />
                                                    }
                                                </div>
                                                <div className="col-md-8"
                                                    style={{
                                                        backgroundImage: `url('${item.dImg}')`,
                                                        backgroundSize: "cover",
                                                        backgroundPosition: "center"
                                                    }}>

                                                </div>

                                            </div>
                                            <div className="row rounded-3 overflow-hidden m-0 d-block d-md-none">

                                                <div className="col-md-8 ratio ratio-1x1"
                                                    style={{
                                                        backgroundImage: `url('${item.mImg}')`,
                                                        backgroundSize: "cover",
                                                        backgroundPosition: "center"
                                                    }}>

                                                </div>
                                                <div className="col-md-4 position-relative text-white  banner-top-image ratio ratio-4x3"
                                                    style={{ borderRadius: "0 0 0 90px" }}>
                                                    {
                                                        (obj.contentType === "OFFER") ?
                                                            <BannerOffer offer={item} />
                                                            :
                                                            (obj.contentType === "TITLE") ?
                                                                <BannerWithTitle content={item} />
                                                                :
                                                                <BannerWithTitleAndButton content={item} />
                                                    }
                                                </div>

                                            </div>
                                        </div>

                                        :
                                        <div>
                                            {
                                                (item.isFunction) ?
                                                    <div>
                                                        <Link onClick={clickUpgrade} className="row  overflow-hidden m-0 d-md-block d-md-flex  d-none"
                                                            style={{ height: "380px", borderRadius: "0px 0px 90px 0px" }}>
                                                            <div className="col"
                                                                style={{
                                                                    backgroundImage: `url('${item.dImg}')`,
                                                                    backgroundSize: "cover",
                                                                    backgroundPosition: "center"
                                                                }}>
                                                            </div>
                                                        </Link>
                                                        <Link onClick={clickUpgrade} className="row  overflow-hidden m-0 d-block d-md-none"
                                                            style={{ height: "500px", borderRadius: "0px 0px 90px 0px" }}>
                                                            <div className="col h-100"
                                                                style={{
                                                                    backgroundImage: `url('${item.mImg}')`,
                                                                    backgroundSize: "cover",
                                                                    backgroundPosition: "center"
                                                                }}>
                                                            </div>
                                                        </Link>

                                                    </div>
                                                    :
                                                    <div>
                                                        <Link to={item.link} target={item.isNewTab ? "_blank" : ""} className="row  overflow-hidden m-0 d-md-block d-md-flex  d-none"
                                                            style={{ height: "380px", borderRadius: "0px 0px 90px 0px" }}>
                                                            <div className="col"
                                                                style={{
                                                                    backgroundImage: `url('${item.dImg}')`,
                                                                    backgroundSize: "cover",
                                                                    backgroundPosition: "center"
                                                                }}>
                                                            </div>
                                                        </Link>
                                                        <Link to={item.link} target={item.isNewTab ? "_blank" : ""} className="row  overflow-hidden m-0 d-block d-md-none"
                                                            style={{ height: "500px", borderRadius: "0px 0px 90px 0px" }}>
                                                            <div className="col h-100"
                                                                style={{
                                                                    backgroundImage: `url('${item.mImg}')`,
                                                                    backgroundSize: "cover",
                                                                    backgroundPosition: "center"
                                                                }}>
                                                            </div>
                                                        </Link>

                                                    </div>

                                            }

                                        </div>


                            }
                        </div>
                    })


                }

            </div>
            {
                (obj.items.length > 1) ?
                    <button className="carousel-control-prev isn-carousel-control-prev" type="button" data-bs-target={`#${obj.bannerid}`} data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button> : ""
            }
            {
                (obj.items.length > 1) ?
                    <button className="carousel-control-next isn-carousel-control-next" type="button" data-bs-target={`#${obj.bannerid}`} data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                    : ""
            }
        </div>







    </div>
}