import { useEffect, useState, useMemo, useContext } from "react";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import { Link } from "react-router-dom";
import "./profile.css";
import { useNavigate } from "react-router-dom";
import { ClientContext } from "../../context/client/ClientContext";

import './common.css';

export default function CommonProfile({ module }) {
  const navigate = useNavigate();
  const { client } = useContext(ClientContext);

  const [RewardsValue, setRewardsValue] = useState();
  const [firstName, setFirstName] = useState(client?.firstname || "");
  const [lastName, setLastName] = useState(client?.lastname || "");
  //const [email, setEmail] = useState(client?.email || "");
  const [euid, setEUID] = useState(client?.euid || "");
  const [accountColor, setAccountColor] = useState();
  const [cashbackColor, setCashbackColor] = useState();
  const [withdrawColor, setWithdrawColor] = useState();
  const [lastUpdate, setLastUpdate] = useState();

  if (!client) {
    navigate("/login");
  }

  useEffect(() => {
    if (client) {
      setFirstName(client.firstname);
      setLastName(client.lastname);
      //    setEmail(client.email);
      setEUID(client.euid);
      getRewardValues();
    }
  }, [client]);

  const getRewardValues = async () => {
    try {
      const res = await isnofferwallapi.get("/api/rewards/rewardvalues", {
        params: {
          customerId: euid,
        },
      });
      setLastUpdate(res.data.lastUpdate);
      setRewardsValue(res.data);
    } catch (error) {
      console.error("get reward :", error);
      setRewardsValue({
        lifeTimeEarnings:0.00,
        pendingEarnings: 0.00,
        currencyCode: "AUD",
        minimumWithdraw: error.response.data.minimumWithdraw,
      });
      
    }
  };

  const earnings = useMemo(
    () =>
      RewardsValue
        ? RewardsValue
        : [
          {
            lifeTimeEarnings: "N/A",
            pendingEarnings: "N/A",
            currencyCode: "N/A",
            minimumWithdraw: 0,
          },
        ],
    [RewardsValue]
  );

  const color = useMemo(() => {
    if (module==="cashback") {
      setAccountColor("");
      setCashbackColor("isn-prof-selected-tab");
      setWithdrawColor("");
    } else if(module==="profile") {
      setAccountColor("isn-prof-selected-tab");
      setCashbackColor("");
      setWithdrawColor("");
    }else{
      setAccountColor("");
      setCashbackColor("");
      setWithdrawColor("isn-prof-selected-tab");
    }
  }, [module]);


  return (
    <div className="col">
      <div className="">
        <div className="isn-bradius bg-white p-4 mt-4">
          <div className="card mb-3 border-0 mt-4">
            <div className="row g-0">
              <div style={{ textAlign: "center" }} className="mb-2">
                <img
                  src="/user.png"
                  className="img-fluid rounded-circle"
                  style={{ width: "66px", height: "66px" }}
                  alt="..."
                />
              </div>
              <div className="">
                <div style={{ textAlign: "center" }} className="card-body p-0">
                  <h5 className="card-title">
                    {firstName} {lastName}
                  </h5>
                  {client?.subscription_status === "active" ? (
                    <div
                      className="badge rounded-pill me-2"
                      style={{ background: "#D0E518", color: "#043963" }}
                    >
                      Premium User
                    </div>
                  ) : (
                    <div className="badge rounded-pill bg-primary me-2">
                      Standard User
                    </div>
                  )}
                  <p className="card-text">
                    <small
                      style={{ fontSize: "0.6875em" }}
                      className="text-body-secondary"
                    >
                      Lifetime earnings
                    </small>
                    <p>
                      {earnings.currencyCode ? earnings.currencyCode : "AUD "} $
                      {earnings.lifeTimeEarnings
                        ? earnings.lifeTimeEarnings
                        : 0}

                      {earnings.lifeTimeEarnings && earnings.lifeTimeEarnings >= earnings.minimumWithdraw ?  (
                        <div className="text-center mt-1">
                          <Link id="common-profile" to="/reward/redeem" state={{ minVal: earnings.minimumWithdraw, maxVal: earnings.lifeTimeEarnings }} className="btn btn-primary bg-primary btn-withdraw border-0 rounded-pill me-2 badge">
                            Withdraw
                          </Link>{" "}
                        </div>
                      ) : 
                      <div className="text-center mt-1">
                          <Link id="common-profile" to="#" className="btn btn-primary bg-primary btn-withdraw border-0 rounded-pill me-2 badge disabled">
                            Withdraw
                          </Link>{" "}
                        </div>
                      }
                      <div
                        className="col-12 text-body-secondary  mt-2"
                        style={{ fontSize: "0.6875em", textAlign: "center" }}
                      ><i class="bi bi-info-circle p-1"></i> 
                        You must have earned a minimum of AUD$&nbsp;{earnings.minimumWithdraw} to withdraw.
                      </div>
                    </p>


                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="mt-4 mb-4">
            <div className="row">
              <div
                className="col-7 text-body-secondary"
                style={{ fontSize: "0.875rem", textAlign: "left" }}
              >
                Pending earnings
              </div>
              <div
                className="col-5 text-body-secondary"
                style={{ fontSize: "0.875rem", textAlign: "right" }}
              >
                {earnings.currencyCode ? earnings.currencyCode : "AUD"} $
                {earnings.pendingEarnings ? earnings.pendingEarnings : 0}
              </div>
              <div
                className="col-12 text-body-secondary mt-4"
                style={{ fontSize: "0.6875em", textAlign: "left" }}
              >
                Last update: {lastUpdate}
              </div>
            </div>
          </div>
          <hr />
          <div className="mt-4">
            <div
              className={`border border-white isn-prof-tab ${accountColor} isn-fs-13`}>
              <i style={{ fontSize: "16px", marginRight: "10px" }}
                className="bi bi-person"></i>{" "}
              <Link id="common-profile" to="/user/account">
                Account Information
              </Link>{" "}
            </div>
            <div className={`border border-white isn-prof-tab ${cashbackColor} isn-fs-13`}>
              <i style={{ fontSize: "16px", marginRight: "10px" }} className="bi bi-card-list"></i>{" "}
              <Link id="common-profile" to="/user/cashback">
                Cashback Overview
              </Link>{" "}
            </div>
            <div className={`border border-white isn-prof-tab ${withdrawColor} isn-fs-13`}>
              <i style={{ fontSize: "16px", marginRight: "10px" }} className="bi bi-cash-coin"></i>{" "}
              <Link id="common-profile" to="/user/withdraw">
              Withdrawal History
              </Link>{" "}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
