import React, { useState, useEffect } from "react";
import "./login.css";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import { useNavigate } from "react-router-dom";
import Alert from "../../components/alert/Alert";
import useAlert from "../../components/alert/useAlert";
import Loader from "../../components/skeleton/Loader";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loader, setLoader] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  console.log(searchParams.get("redirectto"))
  const {
    alert,
    showSuccessAlert,
    showErrorAlert,
    showWarningAlert,
    clearAlert,
  } = useAlert();

  const location = useLocation();
  const isFirstLogin = location.state?.isFirstLogin || false;

  useEffect(() => {
    // When the component mounts
    document.body.classList.add("login-background");

    // When the component unmounts
    return () => {
      document.body.classList.remove("login-background");
    };
  }, []); // Empty array ensures this effect runs only once after initial render

  useEffect(() => {
    // const token = getCookie('loginToken');
    const tokenCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("loginToken"))
      ?.split("=")[1];

    const token = tokenCookie
      ? JSON.parse(decodeURIComponent(tokenCookie))
      : null;

    if (token) {
      // Validate token if necessary
      navigate('/'); // Redirect to dashboard or appropriate page
    }
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoader(true);
      const result = await isnofferwallapi.post("/api/login", {
        username: email,
        password: password,
      });

      if (result.data.code === "200") {
        const res = {
          idToken: result.data.payload.idToken,
          address: result.data.payload.address,
          email: result.data.payload.email,
          firstname: result.data.payload.firstname,
          lastname: result.data.payload.lastname,
          phone_number: result.data.payload.phone_number,
          euid: result.data.payload.euid,
          updated_at: result.data.payload.updated_at,
          gender: result.data.payload.gender,
          exp: result.data.payload.exp,
          refreshToken: result.data.payload.refreshToken,
          tier_name: result.data.payload.tier_name,
          fuel_card_number: result.data.payload.fuel_card_number,
          subscription_first_start_date:
            result.data.payload.subscription_first_start_date,
          subscription_current_start_date:
            result.data.payload.subscription_current_start_date,
          subscription_expiry_date:
            result.data.payload.subscription_expiry_date,
          subscription_status: result.data.payload.subscription_status,
          subscription_id: result.data.payload.subscription_id,
          rewardAmount: result.data.payload.rewardAmount,
          rewardCurrencyCode: result.data.payload.rewardCurrencyCode,
          isFirstLogin,
          dob: result.data.payload.dob,
          phone_number: result.data.payload.phone_number,
          subscription_type: result.data.payload.subscription_type
        };

        document.cookie = `userSession=${encodeURIComponent(
          JSON.stringify(res)
        )}; Max-Age=10800; path=/; Secure`;

        if (rememberMe) {

          document.cookie = `loginToken=${result.data.payload.idToken}; Secure; HttpOnly;`;
        }
        navigate((searchParams.get("redirectto")) ? searchParams.get("redirectto") : "/");
        // window.location.href = "/";
      } else {
        setLoader(false);
        showSuccessAlert(result.data.message.message);
      }
    } catch (error) {
      setLoader(false);
      if (error.response.data.payload.code === "UserNotConfirmedException") {
        console.error("UserNotConfirmedException: ", error.response.data);
        showErrorAlert(error.response.data.message);
        const encodedEmail = encodeURIComponent(email);
        navigate(`/otp?email=${encodedEmail}`);
      } else {
        console.error("onFailure: ", error.response.data);
        showErrorAlert(error.response.data.message);
      }
    }
  };

  return (
    <div>
      {/* Logo Image */}
      <div className="row">
        <div className="col-md-4 offset-md-4">
          <div className="col-md-7 offset-md-2 pt-md-4 isn-log-heading">
            <a href="/" className="ps-2">
              <img
                src="/login/log.png"
                alt="iSaveNow Logo"
                className="img-fluid"
              />
            </a>
          </div>
        </div>
      </div>

      {/* Form Container */}
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-4">
            <div className="card mx-auto rounded-3 shadow">
              <div className="card-body">
                {/* Form content */}

                <h2 className="card-title text-left mb-4 isn-fs-26 px-3 pb-3 pt-3">
                  Login
                </h2>
                <p className="isn-fs-13 ps-3 ">
                Take advantage of exclusive offers and start saving!
                </p>
                <form onSubmit={onSubmit}>
                  <div class="input-group mb-3 px-3">
                    <input
                      type="email"
                      className="form-control isn-fs-13"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email address"
                      required
                    />
                  </div>
                  <div class="input-group mb-3 px-3 pt-2">
                    <input
                      type={"password"}
                      className="form-control pwd-input isn-fs-13"
                      id="password"
                      value={password}
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div className="row ms-2 pt-2">
                    <div className="col-md-5">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="cbRememberMe"
                          checked={rememberMe}
                          onChange={(e) => setRememberMe(e.target.checked)}
                        />
                        <label
                          class="form-check-label isn-fs-13 ms-0"
                          for="flexCheckDefault"
                        >
                          Remember me
                        </label>
                      </div>
                    </div>
                    <div className="col-md-5 offset-md-2 lp-for">
                      <Link
                        to="/user/forgot_pasword"
                        className="isn-fs-13 text-decoration-none pgDirectLink"
                      >
                        Forgot password?
                      </Link>
                    </div>
                  </div>

                  <div className="row px-4 pt-5">
                    <button
                      type="submit"
                      className="btn btn-primary border-0 rounded-pill btnSignUp btn-login-pg"
                    >
                      LOGIN
                    </button>
                  </div>
                  <p className="mt-5 isn-fs-13 text-center">
                    Not registered yet?{" "}
                    <Link to="/signup" className="pgDirectLink">
                      Create an account
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Alert
        show={alert.show}
        type={alert.type}
        message={alert.message}
        onClose={clearAlert}
      />
      <Loader loader={loader} />
    </div>
  );
};

export default Login;
