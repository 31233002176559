import { useState, useContext, useEffect } from "react";
import { Link, json, useLocation, useNavigate } from "react-router-dom";
import { DropdownDivider, Button } from "react-bootstrap";

import NavBar from "./navigationBar/NavigationBar";
import ISaveMoreModal from "./ISaveMoreModal";
import LoginModal from "../../pages/auth/LoginModal";
import "./header.css";

import UserProfile from "./UserProfile";

import { ClientContext } from "../../context/client/ClientContext";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import { TenantContext } from "../../context/tenant/TenantContext";

const Header = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoginOpen, setLoginIsOpen] = useState(false);
  const [topText, setTopText] = useState();
  const { pathname } = useLocation();

  let userDisplayName = "";
  let cashbackAmount = 0.0;
  let cashbackCurrencyCode = "";
  let isLoggedIn = false;
  let isSubscribed = false;

  const { client, clientdispatch } = useContext(ClientContext);

  if (client) {
    userDisplayName = client.firstname + " " + client.lastname;
    cashbackAmount = client.rewardAmount;
    cashbackCurrencyCode = client.rewardCurrencyCode;
    isLoggedIn = true;
    if (client?.subscription_status === "active") {
      isSubscribed = true;
    }
  }

  const handleClose = () => setIsOpen(false);
  const handleShow = () => setIsOpen(true);
  const handleLoginClose = () => setLoginIsOpen(false);
  const handleLoginShow = () => setLoginIsOpen(true);

  useEffect(() => {
    if (client) {
      if (client.isFirstLogin && !isSubscribed) setIsOpen(true);

      const clientObj = client;
      clientObj.isFirstLogin = false;
      clientdispatch({ type: "CLIENT_SUCCESS", payload: clientObj });

      const userSessionCookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("userSession"))
        ?.split("=")[1];

      if (userSessionCookie) {
        const userSessionData = JSON.parse(
          decodeURIComponent(userSessionCookie)
        );
        userSessionData.isFirstLogin = false;

        document.cookie = `userSession=${encodeURIComponent(
          JSON.stringify(userSessionData)
        )}; path=/; Max-Age=10800; Secure`; // Add other cookie attributes as needed
      }
    }
  }, [client]);

  useEffect(() => {
    // const bsOffcanvas = new bootstrap.Offcanvas('#divMobMenu');
    // bsOffcanvas.hide();

    const get = document.getElementById("btnCloseMobMenu");
    get.click();
  }, [pathname]);

  const handleLoginSignUpClick = () => {
    navigate("/login"); // This will navigate to the login route when clicked
  };

  const handleLogoutClick = async () => {
    try {
      const params = { accesstokem: client.idToken };

      const result = await isnofferwallapi.post("/api/logout", params);

      document.cookie = "userSession=; Max-Age=-1; path=/; Secure";
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Error in logout - ", error.response);
      document.cookie = "userSession=; Max-Age=-1; path=/; Secure";
      navigate("/");
      window.location.reload();
    }
  };
  const resizeWindow = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setTopText("Updgrade to iSaveNow Premium");
    } else {
      setTopText("Unlock iSaveNow Premium today");
    }
  };

  useEffect(() => {
    getTenant(489);
    window.addEventListener("resize", resizeWindow);
    resizeWindow();
  }, []);

  const { tenant, tenantdispatch } = useContext(TenantContext);
  const getTenant = async (lanid) => {
    // alert("A")
    tenantdispatch({ type: "TENANT_START" });
    try {
      const res = await isnofferwallapi.get("/api/tenants/tenantgetbyurlname", {
        params: {
          tenanturlname: "isn",
          languageid: lanid,
        },
      });
      // if (res.data.length === 0) navigate("/");

      const tenantobj = res.data;

      // alert("A")

      await localStorage.setItem(
        "l",
        JSON.stringify({
          languageid: tenantobj.languageid,
          languagecode: tenantobj.languagecode,
        })
      );

      // alert("B")
      // const a = res.data.tenantrewards
      // console.log("AAAAA", JSON.parse(a))
      res.data.tenantrewards = JSON.parse(tenantobj.tenantrewards);
      // console.log("AAAAAAAAAAAAAAAAAAAA", res.data.tenantrewards)

      res.data.tenanttier = JSON.parse(tenantobj.tenanttier);

      // console.log("BBBBBBBBBBBBBBBBBBBB", res.data.tenanttier)

      // alert("A")

      tenantdispatch({ type: "TENANT_SUCCESS", payload: res.data });
      return {
        languageid: tenantobj.languageid,
        languagecode: tenantobj.languagecode,
      };
    } catch (err) {
      tenantdispatch({ type: "TENANT_FAIL", payload: err });
    }
  };

  return (
    <div className="fixed-top">
      {isLoggedIn && !isSubscribed ? (
        <div
          className="text-center p-2 upgrade-bar"
          style={{ backgroundColor: "#D0E518" }}
        >
          <p className="d-inline-block me-4 m-0 fw-bold">{topText}</p>
          <Link
            id="btnTopUpgradeNow"
            to="/giveaways"
            className="btn bg-secondary rounded-pill text-white upgrade-bar"
            onClick={handleShow}
          >
            Upgrade
          </Link>
          {/* <ISaveMoreModal
            show={isOpen}
            handleClose={handleClose}
            handleShow={handleShow}
          /> */}
        </div>
      ) : (
        ""
      )}
      <nav className="navbar navbar-expand-lg  bg-secondary py-0">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src="/login/log.png"
              width={"140px"}
              alt="Logo"
              className="d-inline-block align-text-top header-img"
            />
          </Link>
          {isLoggedIn ? (
            <div
              className="nav-link2 isn-header-nav-link mt-1 d-md-none"
              aria-current="page"
            >
              <Link to="/user/cashback">
                <button
                  type="button"
                  style={{ width: "149px" }}
                  className="btn btn-outline-light rounded-pill isn-fs-13 header-btn-nav-color"
                >
                  <div
                    className=" isn-fs-13 d-inline-block text-start align-middle pe-2"
                    style={{ width: "20%" }}
                  >
                    <i className="bi bi-wallet me-1"></i>
                  </div>
                  <div
                    className=" isn-fs-14 d-inline-block"
                    style={{ width: "80%" }}
                  >
                    {cashbackCurrencyCode === "" ? "AUD" : cashbackCurrencyCode}{" "}
                    $ {cashbackAmount}
                  </div>
                </button>
              </Link>
            </div>
          ) : (
            <div
              className="nav-link2 isn-header-nav-link mt-1 d-md-none"
              aria-current="page"
            >
              <button
                type="button"
                style={{ width: "125px" }}
                className="btn btn-outline-light rounded-pill isn-fs-13 header-btn-nav-color"
                onClick={handleLoginShow}
              >
                Login / Sign Up
              </button>
              <LoginModal
                show={isLoginOpen}
                handleClose={handleLoginClose}
                handleShow={handleLoginShow}
              />
            </div>
          )}
          <button
            class="border-0 btn btn-primary navbar-toggler text-white"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#divMobMenu"
            aria-controls="divMobMenu"
          >
            <i class="bi bi-text-right"></i>
          </button>

          {/* <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon text-white"></span>
          </button> */}
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {/* Conditional rendering based on userSession */}
              {isLoggedIn ? (
                <>
                  {console.log("this user is isSubscribed", isSubscribed)}
                  {isSubscribed ? (
                    <li className="nav-item p-0">
                      <div
                        className="nav-link2 isn-header-nav-link mt-1"
                        aria-current="page"
                      >
                        <Link to="/user/refer_a_friend">
                          <button
                            type="button"
                            className="btn btn-outline-light rounded-pill isn-fs-13"
                          >
                            <i className="bi bi-people me-2"></i>Refer a friend
                          </button>
                        </Link>
                      </div>
                    </li>
                  ) : null}
                  <li className="nav-item p-0">
                    <Link
                      className="d-block nav-link2 isn-header-nav-link mt-1 text-white"
                      to="/myfavourites"
                    >
                      <i className="bi bi-heart isn-fs-24"></i>
                    </Link>
                  </li>
  
                  <li className="nav-item p-0">
                    <div
                      className="nav-link2 isn-header-nav-link mt-1 dropdown"
                      aria-current="page"
                    >
                      <button
                        type="button"
                        className="btn btn-outline-light rounded-pill isn-fs-13 header-btn-nav-color dropdown-toggle"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bi bi-person me-2"></i> {userDisplayName}
                        {/* <i className="bi bi-chevron-down ms-2"></i> */}
                      </button>
                      <div
                        className="dropdown-menu profile-dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {/* <li className="d-flex justify-content-center"> */}
                        <UserProfile name={userDisplayName} />
                        {/* </li> */}
                      </div>
                    </div>
                  </li>
                  <li className="nav-item p-0">
                    <div
                      className="nav-link2 isn-header-nav-link mt-1"
                      aria-current="page"
                    >
                      <Link to="/user/cashback">
                        <button
                          type="button"
                          style={{ width: "190px" }}
                          className="btn btn-outline-light rounded-pill isn-fs-13 header-btn-nav-color"
                        >
                          <div
                            className=" isn-fs-13 d-inline-block text-start align-middle pe-2"
                            style={{ width: "50%" }}
                          >
                            <i className="bi bi-wallet me-1"></i> Wallet
                          </div>
                          <div
                            className=" isn-fs-14 d-inline-block"
                            style={{ width: "50%" }}
                          >
                            {cashbackCurrencyCode === ""
                              ? "AUD"
                              : cashbackCurrencyCode}{" "}
                            ${cashbackAmount}
                          </div>
                        </button>
                      </Link>
                    </div>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item p-0">
                    <div
                      className="nav-link2 isn-header-nav-link mt-1"
                      aria-current="page"
                    >
                      <button
                        type="button"
                        className="btn btn-outline-light rounded-pill isn-fs-13 header-btn-nav-color"
                        onClick={handleLoginShow}
                      >
                        <i className="bi bi-person me-2"></i> Login / Sign up
                      </button>
                      <LoginModal
                        show={isLoginOpen}
                        handleClose={handleLoginClose}
                        handleShow={handleLoginShow}
                      />
                    </div>
                  </li>
                  {/* <li className="nav-item p-0">
                    <div
                      className="nav-link2 isn-header-nav-link mt-1"
                      aria-current="page"
                    >
                      <button
                        type="button"
                        style={{ width: "170px" }}
                        className="btn btn-outline-light rounded-pill isn-fs-13 header-btn-nav-color"
                      >
                        <i className="me-2"></i> Guest User
                      </button>
                    </div>
                  </li> */}
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>

      <div
        class="offcanvas offcanvas-end w-75 bg-secondary"
        tabindex="-1"
        id="divMobMenu"
        aria-labelledby="divMobMenuLabel"
      >
        <div class="offcanvas-header text-white">
          <img
            src="/login/log.png"
            alt="Logo"
            width={"140px"}
            className="d-inline-block align-text-top"
          />
          <i
            id="btnCloseMobMenu"
            class="bi bi-x-lg"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></i>
        </div>
        <div class="offcanvas-body text-white ps-0 pe-0">
          {isLoggedIn ? (
            <>
              <div className="border-bottom p-3 pb-4">
                <Link
                  to={"/user/account"}
                  className="isn-text-decoration-none text-white"
                >
                  <div
                    className="bg-primary justify-content-center m-auto pt-2 rounded-circle text-white"
                    style={{ width: "70px", height: "70px" }}
                  >
                    <i className="bi bi-person d-block isn-fs-30 m-auto text-center"></i>
                  </div>
                  <div className="mb-2 mt-3 text-center">{userDisplayName}</div>
                </Link>
                <div className="text-center mb-3">
                  <div
                    className="rounded-pill d-inline-block p-2 pe-4 ps-4"
                    onClick={handleLogoutClick}
                    style={{ backgroundColor: "#164487" }}
                  >
                    Logout
                  </div>
                </div>
                <div className="text-center">
                  {isSubscribed ? (
                    <div
                      className={`badge rounded-pill m-auto bg-info text-dark`}
                      style={{ background: "#D0E518", color: "#043963" }}
                    >
                      Premium User
                    </div>
                  ) : (
                    <div
                      className={`badge rounded-pill m-auto bg-primary text-dark`}
                    >
                      Standard User
                    </div>
                  )}
                </div>
              </div>
              <div className="border-bottom p-3 pt-4 pb-4">
                <div className="text-center">
                  <h3>
                    {cashbackCurrencyCode === "" ? "AUD" : cashbackCurrencyCode}{" "}
                    ${cashbackAmount}
                  </h3>
                  Wallet
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div className="p-3 pt-4">
            <Link
              to="/giveaways"
              className="p-2 d-block isn-text-decoration-none text-white"
            >
              <i className="bi bi-gift me-3"></i>iSaveNow Premium
            </Link>
            {isLoggedIn ? (
              <>
                <Link
                  to="/myfavourites"
                  className="p-2 d-block isn-text-decoration-none text-white"
                >
                  <i className="bi bi-heart me-3"></i>My Favourites
                </Link>
                {isSubscribed ? (
                  <Link
                    to="/user/refer_a_friend"
                    className="p-2 d-block isn-text-decoration-none text-white"
                  >
                    <i className="bi bi-people me-3"></i>Refer a friend
                  </Link>
                ) : null}
              </>
            ) : (
              ""
            )}

            <Link
              to="/about/aboutus"
              className="p-2 d-block isn-text-decoration-none text-white"
            >
              <i className="bi bi-info-circle me-3"></i>About Us
            </Link>

            <Link
              to="/about/contact_us"
              className="p-2 d-block isn-text-decoration-none text-white"
            >
              <i className="bi bi-telephone me-3"></i>Contact Us
            </Link>

            <Link
              to="/about/how-it-works"
              className="p-2 d-block isn-text-decoration-none text-white"
            >
              <i className="bi bi-lightbulb-fill me-3"></i>How It Works
            </Link>
          </div>
          <div className="p-3">
            <a
              href="https://apps.apple.com/au/app/isavenow/id6473877514"
              target="_blank"
            >
              <img
                src="/App_Store_Badge.svg"
                height="35px"
                alt=""
                className="me-2"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.pulseid.isavenow"
              target="_blank"
            >
              <img src="/Google_Play_Store_badge_EN.svg" height="35px" alt="" />
            </a>
          </div>
        </div>
      </div>

      <NavBar />
    </div>
  );
};

export default Header;
