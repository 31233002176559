import { Link } from "react-router-dom";


export default function GiftCardThumb(obj) {
    return <div className="col-md-3 mb-4">
        <Link className="card border-0 nav-link" to={`/giftcards/giftcard/${obj.giftCard.code}`}>
            <div className="position-relative">
                <img src={obj.giftCard.image}
                    className="card-img-top" alt="..." />

                <div className="position-absolute bottom-0 start-0 translate-middle-y ms-2">
                    <img src={obj.giftCard.merchant_logo} className="bg-white d-inline-block rounded-circle object-fit-cover"
                        style={{ width: "35px", height: "35px" }} />

                    {/* <div className="position-absolute bottom-0 start-0 translate-middle-y">
                    <img src={obj.giftCard.merchant_logo} className="d-inline-block rounded-circle object-fit-cover"
                        style={{ width: "35px", height: "35px" }} /> */}

                </div>
            </div>
            <div className="card-body p-0 pt-3">
                <h5 className="card-title text-truncate isn-fs-16 fw-bold">{obj.giftCard.title}</h5>
                <small className="text-body-secondary me-2 isn-fs-13">
                    AUD ${obj.giftCard?.minimum_value} - AUD ${obj.giftCard?.maximum_value}
                </small>
                <div>
                    <div className="badge rounded-pill bg-primary isn-thumb-pill me-2">
                        {
                            (obj.giftCard.discount_type === "PERCENTAGE") ?
                                `${obj.giftCard.customer_discount.split(".")[0]}% Discount`
                                :
                                `${obj.giftCard.currency} ${obj.giftCard.customer_discount} Discount`
                        }
                    </div>
                </div>
                {/* <span className="badge rounded-pill text-bg-primary">6% CASHBACK</span> */}
                {/* <span className="badge rounded-pill text-bg-primary">Exclusive</span> */}
            </div>
        </Link>
    </div>
}