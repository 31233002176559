import React from 'react'
import { Modal, Button } from "react-bootstrap";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import "./ISaveMoreModal.css";
const ISaveMoreModal = (props) => {
  const { show, handleClose, handleShow } = props

  const userSessionCookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith("userSession"))
    ?.split("=")[1];

  const userSession = (userSessionCookie
    ? JSON.parse(decodeURIComponent(userSessionCookie))
    : null);

  const GetStripeURL = async () => {

    try {
      const res = await isnofferwallapi.post("/api/subscription/stripeurl", {
        "firstName": userSession.firstname,
        "lastName": userSession.lastname,
        "customerEmail": userSession.email,
        "externalUserId": userSession.euid
      });
      window.location.replace(res.data.redirectURL);

    } catch (error) {
      console.log("STRIPE URL ERROR", error)
    }
  }

  return (
    <div className='isave-more-content-main'>
      <Modal show={show} onHide={handleClose} className='mb-4' centered="true">
        <div className='isave-more-headr-button'> <Modal.Header className='isave-more-modal-header' closeButton>
        </Modal.Header></div>
        <div className="text-center">
          <img src="/isavemoreModal.png" alt="iSaveNow Premium" className="img-fluid" />
          <p className='isave-more-title' >Upgrade your account!</p>
          <p className='isave-more-content'>
          Become an iSaveNow Premium member and automatically go in the draw to WIN:<br></br>
                       
            1. $1,000 Cash EVERYDAY! (That's 30 x chances every month!)<br></br>
            2. $10,000 Cash EVERY FRIDAY!<br></br>
            3. And MORE
          </p>
          <p className='isave-more-content'>
          Looking for an elevated shopping experience? iSaveNow Premium members receive exclusive first access to new brands and 
          deals with hundreds of brands being added every week! <br></br><br></br>

        Upgrade now to experience MORE - cancel anytime!
          </p>
          {/* <p className='isave-more-content isave-more-content-ul'>
            <ul>
              <li>Automatic entry into all giveaways ($1,000 cash per day, huge weekly cash draws, luxury escapes, and MORE!)</li>
              <li>DOUBLE CASHBACK Opportunities - iSaveNow Premium members receive double cashback promotions.</li>
              <li>Exclusive access to new brands and deals</li>
              <li>Increased discounts on all Gift Cards</li>
              <li>A guilt-free shopping experience knowing your subscription allows us to give back, through donations to the Starlight Children's Foundation and other charitable organisations.</li>
            </ul>
          </p> */}
        </div>

        <Modal.Footer>
          <p className='isave-more-footer-price'>AUD $19.99 p/m</p>
          <div className='text-right ms-auto'>
            <button className="btn isave-more-upgrade gtm-upgrade-isaveMore-home" onClick={GetStripeURL}>Upgrade</button>

          </div>
        </Modal.Footer>



      </Modal>
    </div>
  )
};

export default ISaveMoreModal;
