import { Link } from "react-router-dom";

export default function FooterSection() {
    return <div className="bg-secondary mt-5 pt-5 pb-5">
        <div className="container">
            <div className="row">
                <div className="col-sm-6 mb-5">
                    <img src="/login/log.png" alt="Logo" width={"140px"} className="d-inline-block align-text-top" />
                    <div className="row mt-4 isn-fs-12">
                        <div className="col">
                            <Link className="d-block isn-text-normal mt-2 mb-2" to="/about/aboutus">About Us</Link>
                            <Link className="d-block isn-text-normal mt-2 mb-2" to="/about/faqs">FAQs</Link>
                            <Link className="d-block isn-text-normal mt-2 mb-2" to="/about/how-it-works">How it works</Link>
                            <Link className="d-block isn-text-normal mt-2 mb-2">Hobby Clubs (Coming Soon)</Link>
                        </div>
                        <div className="col">

                            <Link className="d-block isn-text-normal mt-2 mb-2" to="/about/privacy-policy">Privacy Policy</Link>
                            <Link className="d-block isn-text-normal mt-2 mb-2" to="/about/terms-and-conditions">Terms &amp; Conditions</Link>
                            <Link className="d-block isn-text-normal mt-2 mb-2" to="/about/contact_us">Contact us</Link>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 text-right" style={{ textAlign: "right" }}>
                    <div>
                        <a href="https://apps.apple.com/au/app/isavenow/id6473877514" target="_blank"><img src="/App_Store_Badge.svg" height="35px" alt="" className="me-2" /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.pulseid.isavenow" target="_blank"><img src="/Google_Play_Store_badge_EN.svg" height="35px" alt="" /></a>
                    </div>
                    <div className=" isn-text-normal mt-3">
                        <a href="https://www.facebook.com/isavenow/" target="_blank" className="isn-text-normal"><i className="bi bi-facebook m-2"></i></a>
                        <a href="https://www.instagram.com/isavenow/" target="_blank" className="isn-text-normal"><i className="bi bi-instagram m-2"></i></a>
                        <a href="https://www.linkedin.com/company/isavenow/" target="_blank" className="isn-text-normal"><i className="bi bi-linkedin m-2"></i></a>
                        <a href="https://www.tiktok.com/@isavenow" target="_blank" className="isn-text-normal"><i className="bi bi-tiktok m-2"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
}