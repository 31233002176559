const ClientReducer = (state, action) => {
    switch (action.type) {
        case "CLIENT_START":
            return {
                client: null,
                clientIsFetching: true,
                clientError: false
            };
        case "CLIENT_SUCCESS":
            return {
                client: action.payload,
                clientIsFetching: false,
                clientError: false
            };
        case "CLIENT_FAIL":
            return {
                client: { client: null },
                clientIsFetching: false,
                clientError: action.payload
            };
        case "CLIENT_CLEAR":
            return {
                client: { client: null },
                clientIsFetching: false,
                clientError: false
            };

        default: return state;

    }
}
export default ClientReducer;