import { useEffect, useRef, useState, useContext } from "react";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import {  useLocation } from "react-router-dom";
import PageHeader from "../../components/masterlayout/PageHeader";
import "./rewardcard.css";
import { ClientContext } from "../../context/client/ClientContext"
import { useNavigate } from "react-router-dom";
import Loader from "../../components/skeleton/Loader";
import Alert from "../../components/alert/Alert";
import useAlert from "../../components/alert/useAlert";
import PasswordConfirmModal from "./passwordConfirmModal";
import DOMPurify from "dompurify";

export default function RewardCard() {
    const location = useLocation()
    const { minVal } = location.state
    const { maxVal } = location.state
    const navigate = useNavigate();
    const { client } = useContext(ClientContext);
    const [loader, setLoader] = useState(false);
    const {
        alert,
        showSuccessAlert,
        showErrorAlert,
        clearAlert,
    } = useAlert();

    if (!client) {
        navigate("/login");
    }

    if (!minVal || !maxVal) {
        navigate("/user/account");
    }

    const breadcrumbs = [
        { label: "Home", path: "/" },
        { label: "Cashback Overview", path: "/user/cashback" }
    ];

    const [giftCard, setGiftCard] = useState();
    const amount = useRef(0.00);
    const amountShow = useRef("0.00");
    const total = useRef(0);

    const userSessionCookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("userSession"))
        ?.split("=")[1];

    const userSession = (userSessionCookie
        ? JSON.parse(decodeURIComponent(userSessionCookie))
        : null);

    const [isOpen, setIsOpen] = useState(false);
    

    const showLoader = () => {
        setLoader(true)
    }

    const hideLoader = () => {
        setLoader(false)
    }

    const [isOpenConfirm, setIsOpenConfirm] = useState(false);
    const handleConfirmClose = () => {
        setIsOpenConfirm(false);
    }
    const handleConfirmShow = () => {
        setIsOpenConfirm(true);
    }

    const showSuccess = (message) => {
        showSuccessAlert(message)
    }
    const showError = (message) => {
        showErrorAlert(message)
    }

    const addAmount = () => {
        let increment = 1;

        amount.current.value = (parseFloat(amount.current.value) + parseFloat(increment)).toFixed(2);
        onAmountChange();
        setAmountShow();
    }
    const minusAmount = () => {
        let decrement = 1;

        amount.current.value = (parseFloat(amount.current.value) - parseFloat(decrement)).toFixed(2);
        onAmountChange();
        setAmountShow();
    }
    const onAmountChange = () => {
        let camount = amount.current.value;

        camount = parseFloat((camount === "") ? giftCard.minimum_value : camount);
        if (camount < giftCard.minimum_value) {
            amount.current.value = giftCard.minimum_value;
        } else if (camount > giftCard.maximum_value) {
            amount.current.value = giftCard.maximum_value;
        } else {
            amount.current.value = camount.toFixed(2);
        }

        setPrices();
    }

    const setAmountShow = () => {
        amountShow.current.value = giftCard?.currency + " $" + amount.current.value;
    }

    const setAmountValue = (evt) => {
        if (evt.target.value.split('$').length > 1) {
            amount.current.value = evt.target.value.split('$')[1]
        } else {
            amount.current.value = evt.target.value.split('$')[0]
        }
        onAmountChange();
    }

    const setPrices = () => {

        total.current.innerText = parseFloat(amount.current.value).toFixed(2);

        // alert("A")

    }

    const allowNumaric = (evt) => {
        var theEvent = evt || window.event;

        // Handle paste
        if (theEvent.type === 'paste') {
            key = evt.clipboardData.getData('text/plain');
        } else {
            // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            if (key === 37 || key === 38 || key === 39 || key === 40 || key === 8 || key === 46) { // Left / Up / Right / Down Arrow, Backspace, Delete keys
                return;
            }
            key = String.fromCharCode(key);
        }
        var regex = /[0-9|\b|/]/;///[0-9]|[\b]|[\.]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    useEffect(() => {
        setPrices();


    }, [giftCard])


    const GetRewardCards = async () => {
        setLoader(true);

        try {

            const res = await isnofferwallapi.post("/api/giftcards/redeemRewardDetails", {
                minRedeemValue: minVal,
                maxRedeemValue: maxVal
            });

            if (res.data.code) {
                const element = res.data;
                let minValue = element.maximum_value;
                if (element.maximum_value > 100 && element.minimum_value > 100) {
                    minValue = element.minimum_value
                } else if (element.maximum_value > 100) {
                    minValue = 100
                }

                amount.current.value = minValue;
                amountShow.current.value = element.currency + " $" + amount.current.value;
                setGiftCard(element);
            } else {
                setLoader(false);
                showErrorAlert("Please try again in a couple of minutes and if the issue persists, please contact support@isavenow.com.au");
            }

        } catch (error) {
            setLoader(false);
            showErrorAlert("Please try again in a couple of minutes and if the issue persists, please contact support@isavenow.com.au");
        }
        setLoader(false);
    }
    useEffect(() => {
        GetRewardCards();
    }, [])

    const WithdrawCashback = async (token) => {

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        try {
            const res = await isnofferwallapi.post("/api/cashback/withdraw", {
                "firstName": userSession.firstname,
                "lastName": userSession.firstname,
                "email": userSession.email,
                "currency": giftCard.currency,
                "customerId": userSession.euid,
                "vendor": "BlackHawkNetwork",
                "amount": parseFloat(total.current.innerText),
                "giftCardCode": giftCard.code,
            },
            config);

            navigate("/reward/redeem/status?status=success",
                {
                    state: { orderNumber: res.data.orderNumber, transactionId: res.data.transactionId },
                    replace: false,
                }
            );
        } catch (error) {
            setLoader(false);
            if (error.response?.status === 400 && error.response.data) {
                showErrorAlert(error.response.data.message);
            }if (error.response?.status === 403) {
                showErrorAlert("Invalide user token");
            }else{
                navigate("/reward/redeem/status?status=failed",
                {
                    state: { orderNumber: "", transactionId: "" },
                    replace: false,
                }
            );
            }
            
            console.log(error);
            
           

        }

    }


    return <div className="isn-page-bg pb-5">
        <div>
            <PageHeader title="Withdraw Cashback" breadcrumbs={breadcrumbs} />
        </div>
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="">


                        <img src={giftCard?.image} alt="" className="w-100 isn-bradius isn-curve-bottom-right " />

                        <div className="isn-bradius bg-white p-4 mt-4">
                            <div className="card mb-3 border-0 mt-4">
                                <div className="row g-0">
                                    <div className="col-md-2">
                                        <img src={giftCard?.merchant_logo} className="img-fluid rounded-circle"
                                            style={{ width: "50px", height: "50px" }} alt="..." />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body p-0">
                                            <h5 className="card-title">{giftCard?.merchant_name}</h5>
                                            <p className="card-text">
                                                <small className="text-body-secondary">
                                                    Range {giftCard?.currency} ${giftCard?.minimum_value} - {giftCard?.currency} ${giftCard?.maximum_value}
                                                </small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 isn-fs-12" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(giftCard?.description) }}></div>
                            <div className="mt-4">
                                <div className="accordion" id="divGiftCardAccordian">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button isn-fs-13 fw-bold bg-white text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#gftcrdRedemptionInstructions" aria-expanded="true" aria-controls="gftcrdRedemptionInstructions">
                                                Redemption Instructions
                                            </button>
                                        </h2>
                                        <div id="gftcrdRedemptionInstructions" className="accordion-collapse collapse show">
                                            <div className="accordion-body isn-fs-12" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(giftCard?.redemption_info) }}></div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed isn-fs-13 fw-bold bg-white text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#gftcrdTermsandConditions" aria-expanded="false" aria-controls="gftcrdTermsandConditions">
                                                Terms and Conditions
                                            </button>
                                        </h2>
                                        <div id="gftcrdTermsandConditions" className="accordion-collapse collapse">
                                            <div className="accordion-body isn-fs-12" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(giftCard?.terms_and_conditions) }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 bg-white isn-bradius isn-curve-bottom-left mt-4 mt-md-0"
                    style={{ padding: "20px 10% 20px 10%" }}>
                    <div>
                        <h3 className="isn-fs-16 mb-4 fw-bold">Withdrawer details</h3>
                        <div className="mb-3">
                            <input type="text" value={userSession.firstname + " " + userSession.lastname} className="form-control" disabled
                                id="txtGftCrdName" placeholder="Name" />
                        </div>
                        <div className="mb-3">
                            <input type="email" value={userSession.email} className="form-control" disabled
                                id="txtGftCrdEmail" placeholder="Email Address" />
                        </div>
                    </div>
                    <div className="mt-5">
                        <h3 className="isn-fs-16 mb-4 fw-bold">Withdrawal Amount</h3>

                        <div className="input-group mb-3">
                            <button className="btn btn-primary border-0 btn-add-minus rounded-circle me-3 mt-3 mb-3 bg-primary fw-bold"
                                type="button" id="button-addon2" onClick={minusAmount}>-</button>
                            <input id="txtAmountShow"
                                onBlur={setAmountShow} ref={amountShow} type="text" onKeyDownCapture={allowNumaric} onKeyUpCapture={setAmountValue}
                                className="form-control text-center p-3" placeholder="" aria-describedby="button-addon1" />

                            <button className="btn btn-primary border-0 btn-add-minus rounded-circle ms-3 mt-3 mb-3 bg-primary fw-bold"
                                type="button" id="button-addon1" onClick={addAmount}>+</button>
                            <input id="txtAmount"
                                onBlur={onAmountChange} ref={amount} type="hidden"
                                className="form-control text-center p-3" placeholder="" aria-describedby="button-addon1" />
                        </div>
                    </div>

                    <div className="mt-5">
                        <div className="d-flex mb-3  isn-fs-13">
                            <div className="me-auto ">Total Amount</div>
                            <div className="">{giftCard?.currency} $ </div><div className="text-end" ref={total}>0.00</div>
                        </div>

                        <div className="text-center mt-4">
                            <button type="button" className="btn btn-primary bg-primary border-0 rounded-pill p-2 ps-4 pe-4" onClick={handleConfirmShow}>Withdraw</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Loader loader={loader} />
        <Alert
            show={alert.show}
            type={alert.type}
            message={alert.message}
            onClose={clearAlert}
            showSuccessAlert={showSuccessAlert}
            showErrorAlert={showErrorAlert}
        />

        <PasswordConfirmModal
            show={isOpenConfirm}
            handleClose={handleConfirmClose}
            handleShow={handleConfirmShow}
            showLoader={showLoader}
            hideLoader={hideLoader}
            showSuccess={showSuccess}
            showError={showError}
            callback={WithdrawCashback}

        />

    </div>
}