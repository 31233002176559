import { useEffect, useState, useContext } from "react";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import "./giftcardstat.css";
import PageHeader from "../../components/masterlayout/PageHeader";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { ClientContext } from "../../context/client/ClientContext"
import { useNavigate } from "react-router-dom";
import Loader from "../../components/skeleton/Loader";

export default function GiftCardStat() {
    const navigate = useNavigate();
    const { client, clientdispatch } = useContext(ClientContext);
    const [loader, setLoader] = useState(false);

    const [orderNumber, setOrderNumber] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [expDate, setExpDate] = useState("");
    const [message, setMessage] = useState("Please wait...");
    const [giftFail, setGiftFail] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();



    const mode = searchParams.get("mode")

    const ValidateGiftCardTransaction = async () => {
        setLoader(true);
        if (searchParams.get("status") === "canceled") {
            setLoader(false);
        }
        try {
            const res = await isnofferwallapi.post("/api/giftcards/stripevalidate", {

                referenceId: searchParams.get("ref_id"),
                status: searchParams.get("status") === "success"

            });
            setOrderNumber(`Order Number: ${res.data.orderNumber}`);
            setTransactionId(`Transaction ID: ${res.data.transactionId}`);
            setLoader(false);

            // window.location.replace(res.data.redirectUrl);
        } catch (error) {
            setGiftFail(true);
            console.log("STRIPE URL ERROR", error);
            setLoader(false);

        }
    };

    const ValidateSubscriptions = async () => {
        setLoader(true);


        try {

            if (searchParams.get("status") === "canceled") {
                setLoader(false);
            }
            const res = await isnofferwallapi.get("/api/subscription/status", {
                params: {
                    referenceId: searchParams.get("ref_id"),
                    status: searchParams.get("status") === "success",
                     euid: searchParams.get("euid"),
                }

            });
            if (res.data.subscriptionStatus === 'active') {
                setMessage("You have successfully upgraded to iSaveNow Premium.")
                setExpDate("Susbscription Expires On : " + res.data.subscriptionExpireDate);

                if (client) {
                    const userSessionCookie = document.cookie
                        .split("; ")
                        .find((row) => row.startsWith("userSession"))
                        ?.split("=")[1];

                    const userSession = (userSessionCookie
                        ? JSON.parse(decodeURIComponent(userSessionCookie))
                        : null);

                    const clientobj = client
                    clientobj.subscription_status = 'active';
                    clientobj.subscription_expiry_date = res.data.subscriptionExpireDate;
                    clientobj.subscription_id = res.data.subscriptionReference;
                    clientdispatch({ type: "CLIENT_SUCCESS", payload: clientobj });

                    userSession.subscription_status = 'active';
                    userSession.subscription_id = res.data.subscriptionReference;
                    userSession.subscription_expiry_date = res.data.subscriptionExpireDate;

                    document.cookie = `userSession=${encodeURIComponent(
                        JSON.stringify(userSession)
                    )}; path=/; Max-Age=10800; Secure`;

                    const reqPath = document.cookie
                        .split("; ")
                        .find((row) => row.startsWith("request-path"))
                        ?.split("=")[1];
                    if (reqPath) {
                        document.cookie = `request-path=; path=/; Max-Age=-1; Secure`;
                        setTimeout(function () {
                            navigate(reqPath);
                        }, 3000);
                    }
                } else {

                }

            } else {
                document.cookie = `request-path=; path=/; Max-Age=-1; Secure`;
                setMessage("Error Occurred While subscribe for iSaveNow Premium. Please contact support.")
            }

            setLoader(false);

        } catch (error) {
            console.log("ERROR on validate subscribe");
            document.cookie = `request-path=; path=/; Max-Age=-1; Secure`;
            setLoader(false);

        }
    };

    useEffect(() => {
        //if (client) {
            if (mode === 'subscription') {
                ValidateSubscriptions();
            } else if (!mode) {
                ValidateGiftCardTransaction();
            }
       // }
    }, []);

    const breadcrumbs = [
        { label: "Home", path: "/" },
        { label: "GiftCard", path: "/giftcards" },
        // Do not include the current page here
    ];
    const breadcrumbsSubs = [
        { label: "Home", path: "/" },
        // Do not include the current page here
    ];

    return (
        <div>
            {
                (searchParams.get("status") === "success" && !giftFail) ?

                    mode !== 'subscription' ? <div>
                        <PageHeader title={"Order Status"} breadcrumbs={breadcrumbs} />
                        <div className="container my-5">
                            <div className="row justify-content-center">
                                <div className="card-style-box card shadow col-md-6">
                                    <div className="row g-0">
                                        <div className="text-center">
                                            <img src="/success.png" className="rounded" alt="..." />
                                        </div>
                                        <div style={{ marginTop: "2%" }} className="text-center">
                                            <h2 style={{ color: "#002848" }}>Thank you for your purchase!</h2>
                                            <p style={{ color: "#556068" }}>
                                                Your order will arrive shortly.
                                            </p>
                                        </div>
                                        <div style={{ marginTop: "3%" }} className="text-center">
                                            <p style={{ color: "#002848" }}>{orderNumber}</p>
                                            <p style={{ color: "#002848" }}>
                                                {transactionId}
                                            </p>
                                        </div>
                                        <div
                                            style={{ marginTop: "15%", marginBottom: "5%" }}
                                            className="text-center"
                                        >

                                            <Link className="col" to="/">
                                                <button style={{ backgroundColor: "#FB6E2C", width: "166px", height: "46px", border: "0px solid", }}
                                                    type="button" className="btn btn-outline-dark rounded-pill m2">
                                                    HOME
                                                </button>
                                            </Link>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : <div>
                        <PageHeader title={"Subscription Status"} breadcrumbs={breadcrumbsSubs} />
                        <div className="container my-5">
                            <div className="row justify-content-center">
                                <div className="card-style-box card shadow col-md-6">
                                    <div className="row g-0">
                                        <div className="text-center">
                                            <img src="/success.png" className="rounded" alt="..." />
                                        </div>
                                        <div style={{ marginTop: "2%" }} className="text-center">
                                            <h2 style={{ color: "#002848" }}>Congratulations!</h2>
                                            <p style={{ color: "#556068" }}>
                                                {message}
                                            </p>
                                        </div>
                                        {/* <div style={{ marginTop: "3%" }} className="text-center">
                                    <p style={{ color: "#002848" }}>{expDate}</p>

                                </div> */}
                                        <div
                                            style={{ marginTop: "15%", marginBottom: "5%" }}
                                            className="text-center"
                                        >
                                            {
                                                (client) ?
                                                    <Link className="col" to="/">
                                                        <button style={{ backgroundColor: "#FB6E2C", width: "166px", height: "46px", border: "0px solid", }}
                                                            type="button" className="btn btn-outline-dark rounded-pill m2">
                                                            HOME
                                                        </button>
                                                    </Link>
                                                    :
                                                    <div>
                                                        <div className="text-center mb-5">
                                                            Please log in again to access your iSaveNow Premium profile! This will ensure that your account settings are updated!
                                                        </div>

                                                        <Link className="col" to="/login">
                                                            <button style={{ backgroundColor: "#FB6E2C", width: "166px", height: "46px", border: "0px solid", }}
                                                                type="button" className="btn btn-outline-dark rounded-pill m2">
                                                                LOGIN
                                                            </button>
                                                        </Link>
                                                    </div>

                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    :
                    <div>
                        <PageHeader title={(mode === "subscription")?"Subscription Status":"Order Status"} breadcrumbs={breadcrumbsSubs} />
                        <div className="container my-5">
                            <div className="row justify-content-center">
                                <div className="card-style-box card shadow col-md-6">
                                    <div className="row g-0">
                                        <div className="text-center">
                                            <img src="/fail.png" className="rounded" alt="..." />
                                        </div>
                                        <div style={{ marginTop: "2%" }} className="text-center">
                                            <h2 style={{ color: "#002848" }}>Transaction Failed</h2>
                                            <p style={{ color: "#556068" }}>
                                                {
                                                    (giftFail) ? <div>
                                                        Unable to purchase the gift card! Please contact <a href='mailto:support@isavenow.com.au'>support@isavenow.com.au</a> for inquiries.
                                                    </div>
                                                        :
                                                        "Your transaction has been declined. Please try again later."
                                                }

                                            </p>
                                        </div>
                                        {/* <div style={{ marginTop: "3%" }} className="text-center">
                                    <p style={{ color: "#002848" }}>{expDate}</p>

                                </div> */}
                                        <div
                                            style={{ marginTop: "15%", marginBottom: "5%" }}
                                            className="text-center"
                                        >

                                            <Link className="col" to="/">
                                                <button
                                                    style={{
                                                        backgroundColor: "#FB6E2C",
                                                        width: "166px",
                                                        height: "46px",
                                                        border: "0px solid",
                                                    }}
                                                    type="button"
                                                    className="btn btn-outline-dark rounded-pill m2"
                                                >
                                                    HOME
                                                </button>
                                            </Link>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            <Loader loader={loader} />
        </div>
    );
}
