import { React, useState, useRef } from 'react'
import { Modal } from "react-bootstrap";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import "./passwordConfirmModal.css";

const PasswordConfirmModal = (props) => {
  const { show, handleClose, handleShow, showLoader, hideLoader, showSuccess, showError, callback } = props
  const [loader, setLoader] = useState(false);
  const passwordRef = useRef(0);
  const [passwordError, setPasswordError] = useState("");
  const userSessionCookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith("userSession"))
    ?.split("=")[1];

  const userSession = (userSessionCookie
    ? JSON.parse(decodeURIComponent(userSessionCookie))
    : null);

  const validatePassword = (password) => {
    if (!password) return "Please enter the password.";
    return "";
  };

  const hasValidationErrors = () => {
    return (
      passwordError
    );
  };

  const AuthUser = async () => {

    try {

      setPasswordError(validatePassword(passwordRef.current.value));
      if (!hasValidationErrors()) {
        showLoader()
        const res = await isnofferwallapi.post("/api/login/verify_password", {
          username: userSession.email,
          password: passwordRef.current.value
        });
        handleClose();
        
        showSuccess("Please wait while completing whithdrawal.");
        callback(res.data.payload?.AuthenticationResult.AccessToken);
       
      }


    } catch (error) {
      hideLoader(false);
      showError(error.response.data.payload ? error.response.data.payload.message : "Invalid username or password");
    }
  }

  return (
    <div className='password-confirm-content-main'>
      <Modal show={show} onHide={handleClose} className='mb-4' centered="true">
        <div class="modal-header password-confirm-modal-header">
          <p className='password-confirm-title modal-title' >Confirm Password</p>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
        </div>
        <div class="modal-body">
          <div className="text-center">
            <p className='password-confirm-content'>Please confirm your password to Withdraw Cashback.</p>
          </div>

          <div class="form-group ps-3">
            <div className="d-flex account-email">
              <label readonly class='d-flex password-confirm-form-text overflow-hidden p-2 form-control'>{userSession.email}

              </label>

            </div>
          </div>

          <div class="form-group p-3">
            <input type="password" id="currentPassword" className={`form-control password-confirm-form-text p-2 ${passwordError ? "is-invalid" : ""}`}
              placeholder="Confirm Password"
              ref={passwordRef}
              onBlur={(e) => setPasswordError(validatePassword(e.target.value))} />
            <div className="invalid-feedback isn-fs-10">
              {passwordError}
            </div>
          </div>
        </div>
        <Modal.Footer>

          <div className='text-right ms-auto'>
            <button className="btn password-confirm-bt" onClick={AuthUser}>CONFIRM</button>
            <button className="btn password-confirm-skip" onClick={handleClose}>SKIP</button>

          </div>
        </Modal.Footer>



      </Modal>
    </div>
  )
};

export default PasswordConfirmModal;
