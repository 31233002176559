import React, { useState, useEffect } from "react";
import "./otp.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import Alert from "../../components/alert/Alert";
import useAlert from "../../components/alert/useAlert";
import Loader from "../../components/skeleton/Loader";

const OTP = () => {
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmationCodeError, setConfirmationCodeError] = useState("");
  const {
    alert,
    showSuccessAlert,
    showErrorAlert,
    showWarningAlert,
    clearAlert,
  } = useAlert();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const email = decodeURIComponent(queryParams.get("email"));
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    // When the component mounts
    document.body.classList.add("login-background");

    // When the component unmounts
    return () => {
      document.body.classList.remove("login-background");
    };
  }, []);

  const resetForm = () => {
    setVerificationCode("");
  };

  const validateConfirmationCode = () => {
    const regex = /^\d{6}$/;
    if (!regex.test(verificationCode)) {
      setConfirmationCodeError("Confirmation code must be 6 digit");
      return false;
    }
    setConfirmationCodeError("");
    return true;
  };

  const handleVerificationSubmit = async (event) => {
    event.preventDefault();
    const isCodeValid = validateConfirmationCode();
    if (isCodeValid) {
      const params = { verificationcode: verificationCode, username: email };

      try {
        setLoader(true);
        const result = await isnofferwallapi.post(
          "/api/login/confirm_signup",
          params
        );

        if (result.data.code === "200") {
          setLoader(false);
          showSuccessAlert("Your email address has been verified");
          navigate("/login", { state: { isFirstLogin: true } });
        } else {
          setLoader(false);
          showErrorAlert("Your email address has not been verified");
        }

        resetForm();
      } catch (error) {
        setLoader(false);
        console.error("Email verifing error", error.response);
        showErrorAlert(error.response.data.message);
      }
    }
  };

  const resendConfirmationCode = async (event) => {
    event.preventDefault();
    try {
      const result = await isnofferwallapi.post(
        "/api/signup/resend_confirmation_code",
        { username: email }
      );
      if (result.data.code === "200") {
        // Handle success, maybe show a message to the user
        showSuccessAlert("Confirmation code resent successfully");
      } else {
        // Handle any errors, such as showing an error message to the user
        showErrorAlert("Error resending confirmation code");
      }
    } catch (error) {
      // Handle the error scenario, such as a network error or other unexpected issues
      console.error(
        "An error occurred while resending the confirmation code:",
        error
      );
      showErrorAlert(error.response.data.message);
    }
  };

  return (
    <div>
      {/* Logo Image */}
      <div className="row">
        <div className="col-md-4 offset-md-4">
          <div className="col-md-7 offset-md-2 pt-md-4 isn-log-heading">
            <a href="/" className="ps-2">
              <img
                src="/login/log.png"
                alt="iSaveNow Logo"
                className="img-fluid"
              />
            </a>
          </div>
        </div>
      </div>

      {/* Form Container */}
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-4">
            <div className="card mx-auto rounded-3 shadow">
              <div className="card-body">
                <h2 className="card-title text-left isn-fs-26 px-3 pt-3">
                  User Authentication
                </h2>
                <p className="isn-fs-13 ps-3 pe-2">
                  Please check your inbox and enter the verification code below
                  to verify your email address. If you don’t see a code in your
                  inbox, please check your spam/junk folder
                </p>
                <form>
                  <div class="input-group mb-3 px-3">
                    <input
                      type="text"
                      className={`form-control isn-fs-13 ${
                        confirmationCodeError ? "is-invalid" : ""
                      }`}
                      id="verificationCode"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                      placeholder="Enter 6 digit authentication code"
                      onBlur={validateConfirmationCode}
                      required
                    />
                    {confirmationCodeError && (
                      <div className="invalid-feedback isn-fs-10">
                        {confirmationCodeError}
                      </div>
                    )}
                  </div>
                  <div class="row pt-4">
                    <div className="col-md-11 text-center">
                      <button
                        type="submit"
                        className="btn btn-primary btnLogin border-0 rounded-pill btnVerify "
                        onClick={handleVerificationSubmit}
                      >
                        VERIFIY
                      </button>
                    </div>
                  </div>
                  <div class="row pt-4 text-center">
                    <div className="col-md-11 button-container button-container-otp">
                      <button
                        onClick={resendConfirmationCode}
                        className="otp-btn-resend-code ps-3"
                      >
                        Resend Code
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Alert
        show={alert.show}
        type={alert.type}
        message={alert.message}
        onClose={clearAlert}
      />
      <Loader loader={loader} />
    </div>
  );
};

export default OTP;
