import { CONTENT } from "./content";

export default function TermsAndConditionsFuel() {
	return (
		<div className="container">
			<p style={{paddingBottom: "2%"}} className="font-bold">General</p>
			<div className="flex flex-col gap-2">
				{CONTENT.general.map((item, index) => (
					<div key={item.id} className="row">
						<div className="sub-bullets col-1">
							<p className="font-bold">{index + 1}.</p>
						</div>
						<div className="col-11">
							<p>{item.content}</p>
						</div>
					</div>
				))}
			</div>
			<p style={{paddingBottom: "2%", paddingTop: "2%"}} className="font-bold">Who can participate</p>
			<div className="flex flex-col gap-2">
				{CONTENT.participate.map((item, index) => (
					<div key={item.id} className="row">
						<div className="sub-bullets col-1">
							<p className="font-bold">{index + 1}.</p>
						</div>
						<div className="col-11">
							<p>{item.content}</p>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
