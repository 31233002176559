import React, { useState, useEffect } from "react";
import "./login.css";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import { useNavigate } from "react-router-dom";
import Alert from "../../components/alert/Alert";
import useAlert from "../../components/alert/useAlert";
import Loader from "../../components/skeleton/Loader";
import { Modal, Button } from "react-bootstrap";

const LoginModal = (props) => {
  const { show, handleClose, handleShow } = props;
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loader, setLoader] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  // console.log(searchParams.get("redirectto"));
  const {
    alert,
    showSuccessAlert,
    showErrorAlert,
    showWarningAlert,
    clearAlert,
  } = useAlert();

  const location = useLocation();
  const isFirstLogin = location.state?.isFirstLogin || false;


  useEffect(() => {
    // const token = getCookie('loginToken');
    const tokenCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("loginToken"))
      ?.split("=")[1];

    const token = tokenCookie
      ? JSON.parse(decodeURIComponent(tokenCookie))
      : null;

    if (token) {
      // Validate token if necessary
      navigate("/giveaways"); // Redirect to dashboard or appropriate page
    }
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoader(true);
      const result = await isnofferwallapi.post("/api/login", {
        username: email,
        password: password,
      });

      if (result.data.code === "200") {
        const res = {
          idToken: result.data.payload.idToken,
          address: result.data.payload.address,
          email: result.data.payload.email,
          firstname: result.data.payload.firstname,
          lastname: result.data.payload.lastname,
          phone_number: result.data.payload.phone_number,
          euid: result.data.payload.euid,
          updated_at: result.data.payload.updated_at,
          gender: result.data.payload.gender,
          exp: result.data.payload.exp,
          refreshToken: result.data.payload.refreshToken,
          tier_name: result.data.payload.tier_name,
          fuel_card_number: result.data.payload.fuel_card_number,
          subscription_first_start_date:
            result.data.payload.subscription_first_start_date,
          subscription_current_start_date:
            result.data.payload.subscription_current_start_date,
          subscription_expiry_date:
            result.data.payload.subscription_expiry_date,
          subscription_status: result.data.payload.subscription_status,
          subscription_id: result.data.payload.subscription_id,
          rewardAmount: result.data.payload.rewardAmount,
          rewardCurrencyCode: result.data.payload.rewardCurrencyCode,
          isFirstLogin,
          dob: result.data.payload.dob,
          phone_number: result.data.payload.phone_number,
          subscription_type: result.data.payload.subscription_type
        };

        document.cookie = `userSession=${encodeURIComponent(
          JSON.stringify(res)
        )}; Max-Age=10800; path=/; Secure`;

        if (rememberMe) {
          document.cookie = `loginToken=${result.data.payload.idToken}; Secure; HttpOnly;`;
        }
        // navigate(
        //   searchParams.get("redirectto") ? searchParams.get("redirectto") : "/giveaways"
        // );
        setLoader(false);

        // navigate("/giveaways")
        window.location.href = "/giveaways";
      } else {
        setLoader(false);
        showSuccessAlert(result.data.message.message);
      }
    } catch (error) {
      setLoader(false);
      if (error.response.data.payload.code === "UserNotConfirmedException") {
        console.error("UserNotConfirmedException: ", error.response.data);
        showErrorAlert(error.response.data.message);
        const encodedEmail = encodeURIComponent(email);
        navigate(`/otp?email=${encodedEmail}`);
      } else {
        console.error("onFailure: ", error.response.data);
        showErrorAlert(error.response.data.message);
      }
    }
  };

  const handleForgotPassword = () => {
    handleClose(); // Close the modal first
    // navigate("/user/forgot_password"); // Then navigate to forgot password page
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} className="mb-4" centered="true">
        <Modal.Header className="isave-login-modal-header giveaway-bg-blue-wt" closeButton>
          {/* <img
            src="/login/log.png"
            alt="iSaveNow Logo"
            // className="m-auto"
            style={{ width: "40%", marginLeft: "132px" }}
          /> */}
        </Modal.Header>

        <Modal.Body className="giveaway-bg-blue">
          <div className={`me-3 ms-3 mt-3`}>
            <h2 className="card-title text-left mb-4 isn-fs-26 px-3 pb-3 pt-3 giveaway-text-green">
              Login
            </h2>
            <p className="isn-fs-13 ps-3 login-text">
              Take advantage of exclusive offers and start saving!
            </p>
            <form onSubmit={onSubmit}>
              <div class="input-group mb-3 px-3">
                <input
                  type="email"
                  className="rounded-5 form-control isn-fs-13 signup-text-field"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email address"
                  required
                />
              </div>
              <div class="input-group mb-3 px-3 pt-2">
                <input
                  type={"password"}
                  className="rounded-5 form-control signup-text-field pwd-input isn-fs-13"
                  id="password"
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="row ms-2 pt-2">
                <div className="col-md-5">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="cbRememberMe"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    <label
                      class="form-check-label isn-fs-13 ms-0 giveaway-text-green"
                      for="flexCheckDefault"
                    >
                      Remember me
                    </label>
                  </div>
                </div>
                <div className="col-md-5 offset-md-2 lp-for">
                  <Link
                    to="/user/forgot_pasword"
                    onClick={handleForgotPassword}
                    className="giveaway-text-green isn-fs-13 text-decoration-none pgDirectLink"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>

              <div className="row px-4 pt-5">
                <button
                  type="submit"
                  className="btn btn-primary border-0 rounded-pill giveaway-btn-login btn-login-pg"
                >
                  LOGIN
                </button>
              </div>
              <p className="mt-5 isn-fs-13 text-center">
                Not registered yet?{" "}
                <Link to="/signup" className="giveaway-text-green">
                  Create an account
                </Link>
              </p>
            </form>
          </div>
        </Modal.Body>

        <Modal.Footer className="giveaway-bg-blue-wt"></Modal.Footer>
      </Modal>
      <Alert
        show={alert.show}
        type={alert.type}
        message={alert.message}
        onClose={clearAlert}
      />
      <Loader loader={loader} />
    </div>
  );
};

export default LoginModal;
