import { Link, useParams } from "react-router-dom";
import { addFavourites, deleteFavourites, getFavourites } from "../../utils/favourites";
import { useContext, useEffect, useState } from "react";
import { FavouritesContext } from "../../context/favourites/FavouritesContext";
import { ClientContext } from "../../context/client/ClientContext";
import { setFavourites } from "../../utils/offers";
import { OffersContext } from "../../context/offers/OffersContext";
import "./fiveofferthumb.css";
import { TenantContext } from "../../context/tenant/TenantContext";

export default function FiveOfferThumb(obj) {

    const { favourites, favouritesdispatch } = useContext(FavouritesContext);
    const { offers, offersdispatch } = useContext(OffersContext);
    const { tenant } = useContext(TenantContext);

    const { client } = useContext(ClientContext);

    const [offerLink, setOfferLink] = useState();
    const [fivewidth, setFiveWidth] = useState("20%");

    const params = useParams();

    useEffect(() => {
        (obj.sectiontype === "My Favourites") ?
            setOfferLink(`/myfavourites/${obj?.offer.id}`)
            :
            (params.category) ?
                setOfferLink(`/categories/${obj?.sectiontype}/detail/${obj?.offer.id}`)
                :
                setOfferLink(`/offers/${obj?.sectiontype}/detail/${obj?.offer.id}`)

    }, [obj]);
    const updateFavourites = async (e, offerid) => {
        e.preventDefault();
        try {
            const favs = [...favourites];
            favouritesdispatch({ type: "FAVOURITES_START" });

            if (favs.indexOf(offerid) === -1) {
                const res = await addFavourites(client.euid, offerid);
                favs.push(offerid);
            } else {
                const res = await deleteFavourites(client.euid, offerid);
                favs.splice(favs.indexOf(offerid), 1);
            }
            favouritesdispatch({ type: "FAVOURITES_SUCCESS", payload: favs });
            const offersOrganized = setFavourites(offers.all, favs);
            offersdispatch({ type: "OFFERS_SUCCESS", payload: offersOrganized });

        } catch (error) {
            console.log("FAV UPDATE ERROR", error)
            favouritesdispatch({ type: "FAVOURITES_FAIL", payload: error });
        }


    }

    const resizeWindow = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            //alert(obj.parenttype === "page")
            const width = (obj.parenttype === "page") ? "100%" : "20%"
            setFiveWidth(width);
        } else {
            setFiveWidth("20%");
        }
    }
    useEffect(() => {
        // if (obj.offers) window.addEventListener('resize', resizeWindow)
        resizeWindow();
    }, []);

    return <div className="mb-4" style={{ width: fivewidth }}>
        <Link className="card border-0 nav-link" to={offerLink}>
            <div className="position-relative" style={{ borderRadius: "12px" }}>
                <div className="ratio ratio-1x1">
                    <img src={obj.offer.img[0]}
                        className="card-img-top rounded-3" alt="..." />
                    {/* <div className="position-absolute start-0"
                        style={{ background: "linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(0,212,255,0) 100%)", borderRadius: "12px" }}></div> */}

                </div>
                <div className="position-absolute top-0 end-0 text-white mt-2 me-2" onClick={(e) => updateFavourites(e, obj?.offer.id)}>
                    {
                        (obj.offer.fav) ?
                            <i className="bi bi-heart-fill text-primary"></i>
                            : <i className="bi bi-heart "></i>
                    }
                </div>
                <div className="position-absolute bottom-0 start-0 translate-middle-y ms-2">
                    <img src={obj.offer.mImg} className="bg-white d-inline-block rounded-circle object-fit-cover"
                        style={{ width: "35px", height: "35px" }} />
                    <span className="text-white isn-thumb-merchant ms-2">{obj.offer.mName}</span>
                </div>
            </div>
            <div className="card-body ps-0 pe-0">
                <h5 className="card-title text-truncate isn-thumb-title">{obj.offer.title}</h5>

                {/* <span className="badge rounded-pill isn-thumb-pill-offer isn-thumb-pill-green-offer me-2">
                    Premium - {
                        (obj.offer.rwrdT === "PERCENTAGE") ?
                            `${(((obj.offer.rwrdV*100)/80)*90/100).toFixed(1)}% Cashback`
                            :
                            `${obj.offer.cur} ${(((obj.offer.rwrdV*100)/80)*90/100).toFixed(1)} Cashback`
                    }
                </span> */}
                
                <span className="badge isn-fs-10 fw-normal me-2 ps-2 mb-2 rounded-pill text-dark">
                    Standard - {
                        (obj.offer.rwrdT === "PERCENTAGE") ?
                            `${(obj.offer.rwrdV * tenant?.tenantrewards.tier1 / 100).toFixed(1)}% Cashback`
                            :
                            `${obj.offer.cur} ${(obj.offer.rwrdV * tenant?.tenantrewards.tier1 / 100).toFixed(1)} Cashback`
                    }
                </span>
                <span className="badge rounded-pill isn-thumb-pill isn-thumb-pill-green me-2 p-2 fw-bold">
                    Premium - {
                        (obj.offer.rwrdT === "PERCENTAGE") ?
                            `${(obj.offer.rwrdV * tenant?.tenantrewards.tier2 / 100).toFixed(1)}% Cashback`
                            :
                            `${obj.offer.cur} ${(obj.offer.rwrdV * tenant?.tenantrewards.tier2 / 100).toFixed(1)} Cashback`
                    }
                </span>
                {/* {
                    (obj.offer.tgs[1]) ?
                        <span className="badge rounded-pill text-bg-success isn-thumb-pill isn-exclusive-pill">Exclusive</span>
                        : ""
                } */}
            </div>
        </Link>
    </div>
}