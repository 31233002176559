import { useContext, useEffect, useState } from "react";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import { useParams } from "react-router-dom";
import { OffersContext } from "../../context/offers/OffersContext";
import FiveOfferThumb from "../../components/thumbnails/FiveOfferThumb";
import PageHeader from "../../components/masterlayout/PageHeader";
import BrandThumb from "../../components/thumbnails/BrandThumb";
import ThreeOfferThumb from "../../components/thumbnails/ThreeOfferThumb";

export default function Offers() {
  const { offers } = useContext(OffersContext);
  const params = useParams();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [type, setType] = useState();
  const [isSections, setIsSections] = useState(false);
  const [isBrand, setIsBrand] = useState(false);
  const [isCategory, setIsCategory] = useState(false);
  const [isPrevWinners, setIsPrevWinners] = useState(false);

  const [existingBrands, setExistingBrands] = useState([]);

  const [section, setSection] = useState([]);
  const [catOffersList, setCatOffersList] = useState([]);

  const catMapping = [
    { category: "Beauty", isnCategory: "Beauty, Personal Care & Cosmetics" },
    { category: "Cafe", isnCategory: "Books, Flowers & Gifts" },
    { category: "Department Stores", isnCategory: "Department Stores" },
    { category: "Electronics", isnCategory: "Electronics & Accessories" },
    { category: "Entertainment", isnCategory: "Entertainment" },
    { category: "Fashion & Retail", isnCategory: "Clothing & Accessories" },
    { category: "Finance and Banking", isnCategory: "Shoes" },
    { category: "Health and Wellness", isnCategory: "Health and Wellness" },
    { category: "Hobbies", isnCategory: "Hobbies & Games" },
    { category: "Home Improvement/Furniture", isnCategory: "Home, Living & Garden" },
    { category: "Jewelry & Timepieces", isnCategory: "Jewellery & Watches" },
    { category: "Marketplace", isnCategory: "Marketplace" },
    { category: "Miscellaneous", isnCategory: "Others" },
    { category: "Real Estate", isnCategory: "Kids, Babies & Toys" },
    { category: "Restaurants", isnCategory: "Food & Drinks" },
    { category: "Retailers", isnCategory: "Adult" },
    { category: "Sports and Fitness", isnCategory: "Sports and Fitness" },
    { category: "Supermarkets", isnCategory: "Groceries & Pets" },
    { category: "Travel", isnCategory: "Travel" }
  ]

  useEffect(() => {
    const type = params.type
    setBreadcrumbs([
      { label: "Home", path: "/" }
      // Do not include the current page here
    ])
    setType(type);
    // alert()
    if (type) {
      if (type === "Featured Brands") {
        setIsSections(true);
        setSection("Featured");
      } else if (type === "Hot Deals") {
        setIsSections(true);
        setSection("Hot");
      } else if (type === "Popular") {
        setIsBrand(true);
        setSection("Hot");
      } else if (type === "For You") {
        setIsSections(true);
        setSection("Personalised");
      } else if (type === "International") {
        setIsSections(true);
        setSection("International");
      }
    } else if (params.brandname) {
      setType(params.brandname);
      setIsBrand(true)
      setIsSections(false)
      setBreadcrumbs([
        { label: "Home", path: "/" },
        { label: "Brands", path: "/brands" }
      ]);
      setSection("Hot");



    } else if (params.category) {
      console.log(params.category)
      setType(params.category);
      setIsCategory(true);
      setIsSections(false)
      
      const catoffers = [];
      let catalystcat = "";
      for (const cat of catMapping) {
        
        if (cat.isnCategory === params.category) {
          // console.log(cat)


          catalystcat = cat.category;
          break;
        }
      }

      if (offers) {
        for (const offer of offers?.all) {

          
          if (offer.cat === catalystcat && offer?.tgs[0] !== "Giveaway" && offer?.tgs[0] !== "How") {
            console.log(offer.cat, catalystcat)
            catoffers.push(offer);
          }
        }
      }

      setCatOffersList(catoffers)
    } else if (params.previouswinners) {
      setType("Previous Winners");
      setIsPrevWinners(true)
      // setIsSections(true);
      // setSection("Giveaway");
      setBreadcrumbs([
        { label: "Home", path: "/" },
        { label: "About Us", path: "/about/aboutus" }
      ]);
    }
  }, [params]);


  return (
    <>
      <div>
        <PageHeader title={type} breadcrumbs={breadcrumbs} />
      </div>
      <div className="container">
        <div className="row">
          {
            (offers) ?
              (isSections) ?
                offers[section]?.map((offer, index) => {
                  return <FiveOfferThumb sectiontype={type} offer={offer} key={index} parenttype="page" />;
                })
                : (isBrand) ?
                  offers?.Hot?.map((offer, index) => {
                    if (offer.mName === type) {
                      return <FiveOfferThumb sectiontype={type} offer={offer} key={index} parenttype="page" />;

                    } else {
                      return ""
                    }

                  })
                  :
                  (isCategory) ?
                    catOffersList?.map((offer, index) => {
                      return <FiveOfferThumb sectiontype={type} offer={offer} key={index} parenttype="page" />;
                    })
                    :
                    (isPrevWinners) ?
                    offers["ISN_Winners"]?.map((offer, index) => {
                      return <ThreeOfferThumb sectiontype={type} offer={offer} key={index} parenttype="page" />;
                    })
                    :
                    ""
              : ""
          }
        </div>
      </div>
    </>
  );
}



// if (existingBrands.indexOf(offer.mName) !== -1) {
//   setExistingBrands([offer.mName, ...existingBrands]);
//   return <BrandThumb sectiontype={type} offer={offer} key={index} />;

// } else {
//   return ""
// }