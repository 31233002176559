import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const PageHeader = ({ title, breadcrumbs }) => {
  const allBreadcrumbs = [...breadcrumbs, { label: title, path: null }];
  return (
    <div className="pt-3 pb-5">
      <div className="container">
        <h2 className="isn-title isn-fs-23">{title}</h2>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb isn-fs-11">
            {allBreadcrumbs.map((breadcrumb, index) => (
              <li
                key={index}
                className={`breadcrumb-item ${index === allBreadcrumbs.length - 1 ? "active" : ""
                  }`}
                aria-current={index === allBreadcrumbs.length - 1 ? "page" : null}
              >
                {breadcrumb.path ? (
                  <Link to={breadcrumb.path}  className="text-decoration-none text-reset">{breadcrumb.label}</Link>
                ) : (
                  <span className="ph-breadCrumb">{breadcrumb.label}</span>
                )}
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string
    })
  ).isRequired
};


export default PageHeader;
