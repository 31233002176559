export const CONTENT = {
  personal: [
    {
      id: 'c9710fa3-f0b4-5575-aa33-66baa287b932', content: 'Your name;'
    },
    {
      id: 'b141fcf4-96a8-5b36-bf75-7d4fb91cb67e', content: 'Email address;'
    },
    {
      id: '0f35ef48-4cea-52e7-9a71-10bcfe668e94', content: 'Mailing address;'
    },
    {
      id: '3fd9de6e-669a-55a1-b1c0-ba7e87458d06', content: 'Phone number;'
    },
    {
      id: '09b81769-878a-5307-9e7c-95fd1469f28d', content: 'Date of Birth.'
    }
  ],
  nonPersonal: [
    {
      id: '577fc1eb-a2c7-5482-a342-5c37b1c45479', content: 'Log data (e.g., IP address, browser type, access times);'
    },
    {
      id: '64edeb3b-ac7f-5849-b674-ba843f20d016', content: 'Device information (e.g., device type, operating system);'
    },
    {
      id: '709e981a-7e53-54b7-b15c-64c55d7152d6', content: 'Usage data (e.g., pages visited, actions taken).'
    },
  ],
  yourInfo: [
    {
      id: '137f37e7-d224-5584-8332-b6258d8a4753', content: 'To provide and maintain our services;'
    },
    {
      id: '25a349ea-9591-588a-b8d2-32a1fb3464ff', content: 'To personalise and improve your experience on our website;'
    },
    {
      id: '419e0ca6-9ca4-56fd-a9f8-dc9032df2af3', content: 'To send you updates, newsletters, and promotional materials by way of direct marketing, information about the products or services of our related and allied entities;'
    },
    {
      id: 'eb8388ad-0b26-58af-9b25-909bae25077b', content: 'To share your personal with our related or allied entities, which may then communicate with you for marketing purposes;'
    },
    {
      id: 'd99b7d8b-0f43-54ac-a155-1c1c76393895', content: 'To respond to your requests, comments, or questions;'
    },
    {
      id: '5c22e420-b075-5618-b3e2-737071f402ec', content: 'To monitor and analyse website usage patterns;'
    },
    {
      id: '47cc7af1-254f-5e9f-b0b9-a89c94c9a02e', content: 'To detect and prevent fraud or misuse of our services;'
    },
    {
      id: 'a9729ccf-e07d-5699-882f-2bd64d419e05', content: 'To comply with legal obligations; or'
    },
    {
      id: '33303951-1f17-5238-be62-4a5207be055d', content: 'As otherwise permitted or required by Australian Privacy Law;'
    },
  ],
  disclosure: [
    {
      id: '86a83d69-6af9-5e61-8ec8-c604b5259124', content: 'With your consent;'
    },
    {
      id: 'd038030c-0ab1-5de9-a1bd-fb2d3537de22', content: 'With service providers who assist us in operating our website and services;'
    },
    {
      id: '61a4dd6c-79c9-5930-8ab3-25beb534489e', content: 'To comply with legal obligations, such as responding to legal requests or government authorities;'
    },
    {
      id: 'e98bd2fe-591a-5994-95ef-521ba5169094', content: 'In connection with the sale, merger, or acquisition of all or part of our business.'
    },
    {
      id: '4590c673-d5fb-5650-adf0-12b8b5f413a2', content: 'With our related or allied entities.'
    },
  ],
  retention: [
    {
      id: '86a83d69', content: 'we are required to do so by law; and'
    },
    {
      id: 'd038030c', content: 'is necessary for the purposes set out above;'
    },
  ],
  choices: [
    {
      id: 'de70884d-c540-5dd1-afcc-00a100840be6', content: 'Access, correct, or delete your personal information;'
    },
    {
      id: 'b1cbbaa5-0829-57a6-803f-3e17b2a46dec', content: 'Opt out of receiving promotional communications;'
    },
    {
      id: '98a933ec-fd8d-50c1-acd0-4a0486681b8a', content: 'Disable cookies through your browser settings;'
    },
    {
      id: 'bbfb2cb6-741c-588f-b8b9-eaad0a39fe3b', content: 'Request a copy of your data.'
    },
  ],
  europe: [
    {
      id: 'de70884d', content: 'We shall obtain your express consent to send you any direct marketing material;'
    },
    {
      id: 'b1cbbaa5', content: 'You may contact our Privacy Complaints Officer at support@isavenow.com.au;'
    },
    {
      id: '98a933ec', content: 'We shall only retain your personal information or personal data according to clause 7 of this Policy.'
    },
    {
      id: 'bbfb2cb6', content: 'The legal basis on which we deal with your personal data and information is that you have expressly consented to us so doing and that we shall collect, store, use, share and retain your personal data only to the extent for those purposes disclosed in this Policy and necessary for the provision of the services we provide to you.'
    },
  ],
}