export default function MemberAndSubscriber() {
	return (
		<div className="container">
			<p className="mb-5">
				These terms and conditions (hereinafter referred to as
				&quot;Terms&quot;) govern the use of services provided by iSaveNow,
				registered in Australia, with its principal place of business located at
				50 Cavill Avenue, Surfers Paradise, Queensland, Australia (hereinafter
				referred to as the &quot;Company&quot;). By accessing or using our
				services, you agree to abide by these Terms. If you do not agree with
				these Terms, please refrain from using our services.
			</p>
			<h4 className="font-bold text-base mb-4">Background</h4>
			<div className="flex flex-col gap-3 mb-4">
				<div className="row">
					<div className="sub-bullets col-1">
						<p className="font-bold">A.</p>
					</div>
					<div className="col-11">
						<p>
							iSaveNow operates a website platform providing access to multiple
							offerings of products and services from multiple merchants who are
							our Affiliates including unique and exclusive discounts, pricing
							and promotional prizes.
						</p>
					</div>
				</div>
				<div className="row">
					<div className="sub-bullets col-1">
						<p className="font-bold">B.</p>
					</div>
					<div className="col-11">
						<p>
							You wish to use such access and nominated to do so by visiting our
							website and perhaps completing our registration form.
						</p>
					</div>
				</div>
			</div>
			<h4 className="font-bold text-base mb-3">Operative provisions</h4>
			<p className="text-[15px] mb-4">It is now agreed:</p>
			<div className="flex flex-col gap-3 mb-4">
				<div className="row">
					<div className="sub-bullets col-1">
						<p className="font-bold">1.</p>
					</div>
					<div className="col-11">
						<p className="font-bold mb-2">Status</p>
						<div className="flex flex-col gap-3">
							<p>
								<span className="mr-2">a.</span> If you have nominated to be a{" "}
								<b>Guest</b>, by simply visiting our website, you may access
								those offerings of products generally available to guests.
							</p>
							<p>
								<span className="mr-2">b.</span> If you have nominated to be a{" "}
								<b>Standard Member</b> of iSaveNow by completion of our registration
								form, you may by use of your user name and password not only
								access the offerings of products and services generally
								available to guests but also those from our affiliate merchants
								on our website platform which are only available to members.”
							</p>
							<p>
								<span className="mr-2">c.</span> If you have nominated to be an{" "}
								<b>iSaveNow Premium Subscriber Member</b> by completion of our
								registration form and have agreed to pay the required
								subscription fee, you may have all the access of a Standard Member,
								described at 1b above and, in addition, certain other discounts,
								offerings and promotional competition prizes which are described
								as being for “iSaveNow Premium Subscribers Only.””
							</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="sub-bullets col-1">
						<p className="font-bold">2.</p>
					</div>
					<div className="col-11">
						<p className="font-bold mb-2">Continuing Membership and Payment</p>
						<div className="flex flex-col gap-3">
							<p>
								<span className="mr-2">a.</span> You are required to ensure that
								sufficient funds are available in the financial institution
								account whether by way of debit or credit card nominated in your
								Registration Form to pay the Subscription Fee of $19.99 per
								month in advance in order to maintain your subscription as an
								iSaveNow Subscriber;
							</p>
							<p>
								<span className="mr-2">b.</span> iSaveNow reserves the right to
								cancel or suspend your continuing membership on notice in
								writing of two (2) business days;
							</p>
							<p>
								<span className="mr-2">c.</span>You may suspend or cancel your
								membership either as a Subscriber or iSaveNow Standard Member by providing written
								notice to support@iSaveNow.com.au by no less than two (2)
								business days prior to your anniversary of becoming a Subscriber
								or iSaveNow Standard Member.
							</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="sub-bullets col-1">
						<p className="font-bold">3.</p>
					</div>
					<div className="col-11">
						<p className="font-bold mb-2">
							Your Acknowledgement, Agreement and Acceptance
						</p>
						<p className="mb-2">You acknowledge, agree and accept that:</p>
						<div className="flex flex-col gap-3">
							<p>
								<span className="mr-2">a.</span>you are aged 18 years or older;
							</p>
							<p>
								<span className="mr-2">b.</span> you are an Australian resident;
							</p>
							<p>
								<span className="mr-2">c.</span>iSaveNow Pty Ltd is not the
								agent of any of the Affiliate merchants whose products or
								services are accessible through its website platform;
							</p>
							<p>
								<span className="mr-2">d.</span>iSaveNow is not your agent;
							</p>
							<p>
								<span className="mr-2">e.</span>iSaveNow is not responsible or
								liable in any way for any actions, suits, claims, demands,
								losses, damages, liabilities, cost and expenses of any nature
								(including without limitation civil and criminal penalties to
								the extent permitted by law) suffered or incurred by you or
								which you may have at any time actually or contingently arising
								directly or indirectly from:
							</p>
							<div className="pl-4 flex flex-col gap-3">
								<div className="flex gap-2">
									<div className="flex-none">(A)</div>
									<div className="flex-1">
										<p className="mb-2">
											any failure by any Affiliate merchant or any person acting
											on its behalf (including as a contractor or employee)
											(Agent) to comply with any provision of:
										</p>
										<div className="flex flex-col gap-2">
											<p>
												(i) any contract or agreement between you and any
												Affiliate merchant; and
											</p>
											<p>
												(ii) any statute or regulation applying to your dealings
												with any Affiliate merchant;
											</p>
										</div>
									</div>
								</div>
								<div className="flex gap-2">
									<div className="flex-none">(B)</div>
									<div className="flex-1">
										<p>
											any dishonest, fraudulent or negligent act or omission by
											the Affiliate, or any of its Agents whether in breach of
											any relevant contract or agreement or statute or
											regulation or not;
										</p>
									</div>
								</div>
								<div className="flex gap-2">
									<div className="flex-none">(C)</div>
									<div className="flex-1">
										<p>
											any misrepresentations or unconscionable conduct by the
											Affiliate merchant or its Agents in relation to its
											products or services advertised through our website
											platform or otherwise;
										</p>
									</div>
								</div>
								<div className="flex gap-2">
									<div className="flex-none">(D)</div>
									<div className="flex-1">
										<p>
											any liability to a third party arising because of, or
											contributed to by, any act or omission by the Affiliate
											merchant or its Agents;
										</p>
									</div>
								</div>
								<div className="flex gap-2">
									<div className="flex-none">(E)</div>
									<div className="flex-1">
										<p>
											Any conduct of by the Affiliate merchant or its Agents;
										</p>
									</div>
								</div>
								<div className="flex gap-2">
									<div className="flex-none">(F)</div>
									<div className="flex-1">
										<p>
											Any defect, flaw or failing in any product or service
											obtained by you from any of our Affiliate merchants
											whether through our website platform or otherwise; or
										</p>
									</div>
								</div>
								<div className="flex gap-2">
									<div className="flex-none">(G)</div>
									<div className="flex-1">
										<p>
											any conduct by any of its Guests, Standard Members or iSaveNow Premium
											Subscriber Members.
										</p>
									</div>
								</div>
							</div>
							<p>
								<span className="mr-2">f.</span>you shall not when dealing with
								us or with our affiliate merchants or suppliers engage in any
								conduct which is:
							</p>
							<div className="pl-4 flex flex-col gap-3">
								<div className="flex gap-2">
									<div className="flex-none">(A)</div>
									<div className="flex-1">
										<p className="mb-2">dishonest, fraudulent or misleading;</p>
									</div>
								</div>
								<div className="flex gap-2">
									<div className="flex-none">(B)</div>
									<div className="flex-1">
										<p>abusive or insulting;</p>
									</div>
								</div>
								<div className="flex gap-2">
									<div className="flex-none">(C)</div>
									<div className="flex-1">
										<p>discriminatory, illegal or otherwise unlawful;</p>
									</div>
								</div>
							</div>
							<p>
								<span className="mr-2">g.</span>you must only access our website or complete our registration form:
							</p>
							<div className="pl-4 flex flex-col gap-3">
								<div className="flex gap-2">
									<div className="flex-none">(A)</div>
									<div className="flex-1">
										<p className="mb-2">once;</p>
									</div>
								</div>
								<div className="flex gap-2">
									<div className="flex-none">(B)</div>
									<div className="flex-1">
										<p>only on your own behalf.</p>
									</div>
								</div>
							</div>
							<p>
								<span className="mr-2">h.</span>you shall not when accessing our website or when dealing with us or
								with our affiliate merchants or suppliers:
							</p>
							<div className="pl-4 flex flex-col gap-3">
								<div className="flex gap-2">
									<div className="flex-none">(A)</div>
									<div className="flex-1">
										<p className="mb-2">engage in any kind of hacking ,tampering or abuse of our
											website and its attended computer system; or</p>
									</div>
								</div>
								<div className="flex gap-2">
									<div className="flex-none">(B)</div>
									<div className="flex-1">
										<p>insert into our website any script, code, robot or other software
											program which may have the effect of disrupting or disturbing the
											operation of the website or interfering with the access of other
											persons to the website.</p>
									</div>
								</div>
							</div>
							<p>
								<span className="mr-2">i.</span>you shall comply with any and all terms and rules of any Cashback and Competition offers made by us.
							</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="sub-bullets col-1">
						<p className="font-bold">4.</p>
					</div>
					<div className="col-11">
						<p className="font-bold mb-2">Cashbacks</p>
						<div className="flex flex-col gap-3">
							<p>
								<span className="mr-2">a.</span>
								We may at our absolute and sole discretion offer and provide Standard Members
								and iSaveNow Premium Subscription Members cash back incentives by way of
								deposits to your iSaveNow Wallet;
							</p>
							<p>
								<span className="mr-2">b.</span>Withdrawals from your iSaveNow Wallet shall:
							</p>
							<div className="flex flex-col gap-2">
								<p>
									(i) not be by way of cash; but
								</p>
								<p>
									(ii) only be by way of digital Gift Card subject to the provisions below.
								</p>
							</div>
							<p>
								<span className="mr-2">c.</span>Payment of any amount by way of cash back is not a representation
								that we have any obligation to you to pay such amount on that occasion
								or on any other occasion.
							</p>
							<p>
								<span className="mr-2">d.</span>Such payment is by way of a gratuity only and is not consideration for
								any agreement or variation to these terms.
							</p>
							<p>
								<span className="mr-2">e.</span>Calculation of the cashback amount is minus any taxes and costs
								associated with the good(s) or service.
							</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="sub-bullets col-1">
						<p className="font-bold">5.</p>
					</div>
					<div className="col-11">
						<p className="font-bold mb-2">Gift Cards</p>
						<div className="flex flex-col gap-3">
							<p>
								<span className="mr-2">a.</span>
								Each Gift Card are subject to and may only be used in accordance the terms of use of the Gift Card Issuer which will be one of our
								affiliated merchants.
							</p>
							<p>
								<span className="mr-2">b.</span>It is your responsibility to identify the expiry date and any other
								limitations on the use of a Gift Card from the terms of its use as issued by its Issuer.
							</p>
							<p>
								<span className="mr-2">c.</span>Gift Cards:
							</p>
							<div className="flex flex-col gap-2">
								<p>
									(i) cannot be exchanged for money;
								</p>
								<p>
									(ii) will not be exchanged, refunded or replaced if lost, stolen, misdirected, deleted, damaged or destroyed; and
								</p>
								<p>
									(iii) cannot be cancelled, exchanged or refunded after the Gift Card has been purchased.
								</p>
								<p>
									(iv) are sold for the personal use of the recipient of the Gift Card only and are not to be re-sold or re-distributed.
								</p>
								<p>
									<span className="mr-2">d.</span>The range of Gift Cards available on our website may change from time to time, without notice.
								</p>
								<p>
									<span className="mr-2">e.</span>The number and value of Gift Cards able to be purchased by any iSaveNow Standard Member or iSaveNow Premium Subscription Member may be limited by us or by our affiliated merchants from time to time.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="sub-bullets col-1">
						<p className="font-bold">6.</p>
					</div>
					<div className="col-11">
						<p className="font-bold mb-2">Gift Card Acknowledgements</p>
						<div className="flex flex-col gap-3">
							<p>
								You acknowledge and agree that:
							</p>
							<p>
								<span className="mr-2">a.</span>
								Gift Cards are offered subject to availability.
							</p>
							<p>
								<span className="mr-2">b.</span>Some Gift Cards are subject to capacity controls or limited availability, and this may result in them becoming unavailable before an order can be fulfilled.
							</p>
							<p>
								<span className="mr-2">c.</span>Gift Card issuers may limit the amount of Gift Cards which may be used for any single purchase, please check the Special Offer Terms of the individual Gift Card issuer.
							</p>
							<p>
								<span className="mr-2">d.</span>Even though Gift Cards will be dispatched electronically, orders may not be processed immediately.
							</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="sub-bullets col-1">
						<p className="font-bold">7.</p>
					</div>
					<div className="col-11">
						<p className="font-bold mb-2">iSaveNow:</p>
						<div className="flex flex-col gap-3">
							<p>
								<span className="mr-2">a.</span>
								is not liable for the loss, theft or destruction of a Gift Card;
							</p>
							<p>
								<span className="mr-2">b.</span>is not responsible for the provision of any products or services supplied to a holder of a Gift Card upon redemption of the Gift Card or for any misunderstanding or dispute between you and a Gift Card Issuer.
							</p>
							<p>
								<span className="mr-2">c.</span>is not liable for any value on a Gift Card where the relevant Gift Card Issuer ceases to carry on business except where that Gift Card is issued by iSaveNow.
							</p>
							<p>
								<span className="mr-2">d.</span>to the extent permitted by law, iSaveNow does not make any representations or warranties and does not accept any liability for any error or omission or the lack of completeness or correctness of any information about Gift Cards provided by their issuers, except where the Gift Card is issued by iSaveNow. 
							</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="sub-bullets col-1">
						<p className="font-bold">8.</p>
					</div>
					<div className="col-11">
						<p className="font-bold mb-2">Bar to Proceedings and Costs</p>
						<div className="flex flex-col gap-3">
							<p>
								<span className="mr-2">a.</span>
								This Agreement shall stand as a bar against any proceedings you
								bring for any cause of action for any loss described or relevant
								to the matters described in Clause 3 above and that it can
								pleaded as a complete defence to any such action;
							</p>
							<p>
								<span className="mr-2">b.</span>You shall pay all our costs of
								investigating, responding and dealing with any such action as
								described at 4a including legal costs on an indemnity basis
								whether they are incurred in our capacity as a Defendant or
								Respondent to such action or as a witness or respondent to any
								non-party discovery notice or statutory notice delivered by any
								relevant authority.
							</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="sub-bullets col-1">
						<p className="font-bold">9.</p>
					</div>
					<div className="col-11">
						<p className="font-bold mb-2">Assignment</p>
						<p>
							A party must not transfer any right or liability under this
							document without the prior consent of each other party, except
							where this document provides otherwise.
						</p>
					</div>
				</div>
				<div className="row">
					<div className="sub-bullets col-1">
						<p className="font-bold">10.</p>
					</div>
					<div className="col-11">
						<p className="font-bold mb-2">Governing law and jurisdiction</p>
						<p>
							This document is governed by and construed under the law of
							Queensland and the parties will submit any dispute arising out of
							it to the courts or tribunals of that state.
						</p>
					</div>
				</div>
				<div className="row">
					<div className="sub-bullets col-1">
						<p className="font-bold">11.</p>
					</div>
					<div className="col-11">
						<p className="font-bold mb-2">Waivers</p>
						<p>
							Any failure by a party to exercise any right under this document
							does not operate as a waiver. The single or partial exercise of
							any right by that party does not preclude any other or further
							exercise of that or any other right by that party.
						</p>
					</div>
				</div>
				<div className="row">
					<div className="sub-bullets col-1">
						<p className="font-bold">12.</p>
					</div>
					<div className="col-11">
						<p className="font-bold mb-2">Severability</p>
						<p>
							Any clause of this document which is invalid in any jurisdiction
							is invalid in that jurisdiction to that extent, without
							invalidating or affecting the remaining clauses of this document
							or the validity of that clause in any other jurisdiction.
						</p>
					</div>
				</div>
				<div className="row">
					<div className="sub-bullets col-1">
						<p className="font-bold">13.</p>
					</div>
					<div className="col-11">
						<p className="font-bold mb-2">Execution</p>
						<p>
							Execution of this agreement by you shall be by the electronic
							means notified to you on our website.
						</p>
					</div>
				</div>
				<div className="row">
					<div className="sub-bullets col-1">
						<p className="font-bold">14.</p>
					</div>
					<div className="col-11">
						<p className="font-bold mb-2">Termination</p>
						<div className="flex flex-col gap-3">
							<p>
								<span className="mr-2">a.</span>
								We may terminate without notice your status as a Guest, iSaveNow Standard Member or
								iSaveNow Premium Member at any time if we reasonably believe
								you have breached any of these Terms.
							</p>
							<p>
								<span className="mr-2">b.</span>We may terminate without cause on notice of 30 days your status as
								Guest, iSaveNow Standard Member or iSaveNow Premium Subscription Member.
							</p>
							<p>
								<span className="mr-2">c.</span>You may terminate your status with us at any time by the means made
								available within your profile.
							</p>
							<p>
								<span className="mr-2">d.</span>After the notice period (if any) for termination provided for above, all
								uncollected Cashbacks, Refer a Friend incentives and Competition
								prizes shall be cancelled and will no longer be payable by us.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
