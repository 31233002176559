import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./threeoffersthumb.css";

export default function ThreeOfferThumb(obj) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const [giveawaywidth, setGiveawayWidth] = useState("12.5%");

    const resizeWindow = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            //alert(obj.parenttype === "page")
            const width = (obj.parenttype === "page") ? "100%" : "33.33%"
            setGiveawayWidth(width);
        } else {
            setGiveawayWidth("33.33%");
        }
    }
    useEffect(() => {
        window.addEventListener('resize', resizeWindow)
        resizeWindow();
    }, []);

    return <div className="mb-4" style={{ width: giveawaywidth }}>
        <Link className={`card border-0 nav-link aboutus-title-text ${(obj?.isroundedcorner ? 'bottom-right-rounded' : '')}`}
            to={`/about/aboutus/${obj?.sectiontype}/${obj?.offer.id}`}>
            {
                (obj?.layout && obj.layout === "TITLE_MIDDLE") ?
                    <div className="position-relative">
                        <div className="ratio ratio-1x1">
                            <img
                                src={obj?.offer.img[0]}
                                // style={{ borderRadius: "15px 15px 50px 15px" }}
                                alt="..."
                            />
                            <div className="aboutus-overlay"></div>
                        </div>
                        <div className="position-absolute top-50 start-50 translate-middle">
                            <h3 style={{ color: "#ffffff", textAlign: "center" }}>
                                {obj?.offer.title}
                            </h3>
                        </div>
                    </div>
                    :
                    <div className="position-relative" style={{ borderRadius: "12px" }}>
                        <div className="ratio ratio-1x1">
                            <img src={obj?.offer.img[0]}
                                className="card-img-top rounded-3" alt="..." />
                        </div>
                    </div>
            }


        </Link>
    </div>
}