import React from "react";
import ReactDOM from 'react-dom';

const AlertModal = ({ show, type, message, onClose }) => {
  if (!show || !message) return null;

  const iconClass = `bi ${
    type === "success"
      ? "bi-check-circle-fill"
      : type === "danger"
      ? "bi-exclamation-triangle-fill"
      : type === "warning"
      ? "bi-exclamation-triangle-fill"
      : "bi-info-fill"
  }`;

  const modalContent = (
    <div
      className={`modal fade ${show ? "show" : ""}`}
      style={{ display: show ? "block" : "none", zIndex: 1500 }}
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-top">
        <div className={`modal-content border-0`}>
          <div
            className={`modal-body alert alert-${type} d-flex align-items-center m-0`}
            role="alert"
          >
            <i
              className={`${iconClass} flex-shrink-0 me-3`}
              style={{ fontSize: "1.25rem" }}
            ></i>
            <div>{message}</div>

            <button
              type="button"
              className="btn-close ps-2 ms-auto"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>
        </div>
      </div>
    </div>
  );

  // Render the modal content using a portal
  return ReactDOM.createPortal(
    modalContent,
    document.getElementById('portal-root')
  );
};

export default AlertModal;
