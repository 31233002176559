import {React,useState} from 'react'
import { Modal } from "react-bootstrap";
import { isnofferwallapi } from "../../config/axios/axiosconfig";
import Loader from "../../components/skeleton/Loader";
import "./subscriptionCancleModal.css";

const ISaveMoreModal =  (props) => {  
  const {show, handleClose,handleShow,showLoader,hideLoader,showSuccess,showError} = props
  const [loader, setLoader] = useState(false);
  const userSessionCookie = document.cookie
  .split("; ")
  .find((row) => row.startsWith("userSession"))
  ?.split("=")[1];

  const userSession = (userSessionCookie
    ? JSON.parse(decodeURIComponent(userSessionCookie))
    : null);
    
  const ProceedCancle = async () => {

  try {
      handleClose()
      showLoader()
      const res = await isnofferwallapi.post("/api/profile/subscription/cancle", {
        subscriptionId:userSession.subscription_id,
        euid:userSession.euid
      });
      hideLoader(false)
      if (res.data.cancleAtPeriodEnd){
        showSuccess("You cancelation request is success and will be cancel at end of the period.");
      }else{
        showError("Error occurred while processing cancel request. Please contact support.");
      }
    
    } catch (error) {
      hideLoader(false)
      showError("Error occurred while processing cancel request. Please contact support.");
    }
  }

  return (
    <div className='sub-cancle-content-main'>
      <Modal show={show} onHide={handleClose} className='mb-4' centered="true">
        <div class="modal-header sub-cancle-modal-header">
        <p className='sub-cancle-title modal-title' >Cancel Subscription</p>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
        </div>
        <div class="modal-body">
        <div className="text-center">
            
            <p className='sub-cancle-content'>Your subscription will be cancelled at end of the subscription period. Please confirm to cancel.</p>
          </div>
          </div>
        <Modal.Footer>
         
          <div className='text-right ms-auto'>
          <button className="btn sub-cancle-upgrade" onClick={ProceedCancle}>CONFIRM</button>
          <button className="btn sub-cancle-skip" onClick={handleClose}>SKIP</button>

          </div>
        </Modal.Footer>

        

      </Modal>
    </div>
  )
};

export default ISaveMoreModal;
